import { useState } from 'react'
import useRest from 'hooks/useRest'
import { storageKey } from 'services/axios'
import { addConsumerUnitParam } from 'utils/helpers'

export type TColumns = {
  columnName: string,
  sectionData: number[]
}

export type TInvoiceComparisonChart = {
  chartData: {
    referenceMonth: string,
    columnNames:string[],
    columns: TColumns[]
  }
}

const useInvoiceComparisonChart = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getInvoiceComparisonChart = async (triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/dashboard/invoiceComparisonChart'

    const params = addConsumerUnitParam({}, storageKey)

    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    return response.content as TInvoiceComparisonChart
  }

  return {
    isLoading,
    getInvoiceComparisonChart,
  }
}

export default useInvoiceComparisonChart