import React from 'react'
import { Routes as ReactRouterRoutes, Route } from 'react-router-dom'

import RouteWrapper from './Route'

import Home from 'pages/Home'
import Help from 'pages/Help'
import ReferNWin from 'pages/ReferNWin'
import Consumption from 'pages/Consumption'
import Invoices from 'pages/Invoices'
import MyProfile from 'pages/MyProfile'
import Login from 'pages/Login'
import Terms from 'pages/Terms'
import ForgotPassword from 'pages/ForgotPassword'
import ReferNWinList from 'pages/ReferNWinList'

const Routes = () => {
  return (
    <ReactRouterRoutes>

      <Route
        path='/'
        element={(
          <RouteWrapper
            isPrivate
          >
            <Home />
          </RouteWrapper>
        )}
      />

      <Route
        path='/help'
        element={(
          <RouteWrapper
            isPrivate
          >
            <Help />
          </RouteWrapper>
        )}
      />

      <Route
        path='/my-profile'
        element={(
          <RouteWrapper
            isPrivate
          >
            <MyProfile />
          </RouteWrapper>
        )}
      />

      <Route
        path='/invoices'
        element={(
          <RouteWrapper
            isPrivate
          >
            <Invoices />
          </RouteWrapper>
        )}
      />

      <Route
        path='/refer-and-win'
        element={(
          <RouteWrapper
            isPrivate
          >
            <ReferNWin />
          </RouteWrapper>
        )}
      />

      <Route
        path='/refer-and-win/list'
        element={(
          <RouteWrapper
            isPrivate
          >
            <ReferNWinList />
          </RouteWrapper>
        )}
      />

      <Route
        path='/consumption'
        element={(
          <RouteWrapper
            isPrivate
          >
            <Consumption />
          </RouteWrapper>
        )}
      />

      <Route
        path='/login'
        element={(
          <RouteWrapper>
            <Login />
          </RouteWrapper>
        )}
      />

      <Route
        path='/forgot-password'
        element={(
          <RouteWrapper>
            <ForgotPassword />
          </RouteWrapper>
        )}
      />

      <Route
        path='/forgot-password/:token'
        element={(
          <RouteWrapper>
            <ForgotPassword />
          </RouteWrapper>
        )}
      />

      <Route
        path='/terms'
        element={(
          <RouteWrapper>
            <Terms />
          </RouteWrapper>
        )}
      />

    </ReactRouterRoutes>
  )
}

export default Routes
