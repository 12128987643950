import styled from 'styled-components'
import { Collapse } from 'antd'

export const Container = styled.div`
  max-width: 100%;
`
export const CollapseCustom = styled(Collapse)`

 border: ${({ theme }) => `1px solid ${theme.colors.LIGHT_GRAY}`};
 background-color: ${({ theme }) => theme.colors.LIGHT_GRAY};

 p {
  font: normal normal 300 17px/23px 'Exo 2';
  color: ${({ theme }) => theme.colors.GRAY};
 }
 
 .ant-collapse-header{
  padding-inline-start: 44px !important;
  padding: 30px 15px 30px 50px !important;
  align-items: center !important;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    padding: 17px !important;
  }
 }

 .ant-collapse-header-text{
  text-align: left;
  font: normal normal 300 22px/26px 'Exo 2' !important;
  letter-spacing: 0px !important;
  color: #758592 !important;
  opacity: 1 !important;
 }

 .ant-collapse>.ant-collapse-item >.ant-collapse-header{
  height: 60px !important;
 }

 :where(.css-dev-only-do-not-override-1m2t760).ant-collapse.ant-collapse-icon-position-end>.ant-collapse-item >.ant-collapse-header .ant-collapse-expand-icon {
    padding-inline-start:0 !important;
 }

 .ant-collapse-content {
  border-radius: 0 0 10px 10px !important;
 }

 .anticon {
  font-size: 25px !important;
  color: ${({ theme }) => theme.colors.GRAY} !important;
 }

`

export const Link = styled.button`
  color: ${({ theme }) => theme.colors.BLUE};
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: none;
  cursor: pointer;
`