import { useState } from 'react'
import useRest from 'hooks/useRest'

export type TClient = {
  customerName: string
  customerDocument: string
  consumerUnitCode: string
  profilePictureUrl?: string
  email: string
  address?: TAddress
  city: {
    name: string
  }
  state: {
    name: string
    uf: string
  }
}

export type TGetClientsParams = {
  searchTerm?: string,
  page?: number,
  pageSize?: number
}

export type TAddress = {
  zipCode: string
  street: string
  number: string
  complement: string
  neighborhood: string
  addressType: string
}

const useClients = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getClients = async (params:TGetClientsParams, triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)

    const url = '/clients'

    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    return response as any
  }


  return {
    isLoading,
    getClients,
  }
}

export default useClients