import { useState } from 'react'
import useRest from 'hooks/useRest'
import { storageKey } from 'services/axios'
import { addConsumerUnitParam } from 'utils/helpers'

export type TEconomyDataParams = {
  yearFilter: number
  uc?: string
} | undefined

export type TData = {
  label:string,
  data:number[]
}

export type TSpreadsheet = {
  spreadSheetToBase64:string
}

export type TEconomyData = {
  chartData:{
    labels:string[],
    data: TData[]
  }
}

const useEconomy = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getEconomyData = async (data:TEconomyDataParams, triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/consumptionAndEconomy/economyChart'
    const params = addConsumerUnitParam(data, storageKey)

    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    return response.content as TEconomyData
  }

  const getSpreadsheetData = async (data:TEconomyDataParams, triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/consumptionAndEconomy/economySpreadSheet'
    const params = addConsumerUnitParam(data, storageKey)

    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    return response.content as TSpreadsheet
  }

  return {
    isLoading,
    getEconomyData,
    getSpreadsheetData
  }
}

export default useEconomy