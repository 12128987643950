import styled from 'styled-components'
import { Button, Form as AntForm } from 'antd'

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`

export const ContainerCAPTCHA = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
`


export const Canvas = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 12.5px 18.5%;
  background: 
    transparent 
    radial-gradient(closest-side at 50% 50%, ${({ theme }): any => theme.colors.RED} 20%, ${({ theme }): any => theme.colors.ORANGE} 100%)
    0% 0%
    no-repeat
    padding-box;
  mix-blend-mode: multiply;
  min-height: 800px;
  height: auto;
  width: 100%;
  overflow: hidden;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    background: 
    transparent 
    linear-gradient(to right, ${({ theme }): any => theme.colors.RED}, ${({ theme }): any => theme.colors.ORANGE})
    0% 0%
    no-repeat
    padding-box;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    padding: 50px 7.5% 0 7.5%;
  }
`
export const TitleContainer = styled.div`
  z-index: 100;
`

export const Title = styled.h1`
  text-align: center;
  font: normal normal 300 50px/60px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.WHITE};

  b {
    font: normal normal 900 50px/60px 'Exo 2';
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
   font-size: 35px;
   line-height: 40px;
   padding: 0 12px;

   b {
    font-size: 35px;
    line-height: 40px;
   }
  }
`

export const SubTitle = styled.h2`
  text-align: center;
  font: normal normal normal 20px/24px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.WHITE};
  opacity: 1;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
   font-size: 20px;
  }
`

export const LoadingContainer = styled.div<{isLoading?: boolean}>`
  display: ${({ isLoading }) => isLoading ? 'none' : 'block'};
`

export const LoginContainer = styled.div<{isLoading?:boolean}>`
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: ${({ isLoading }) => isLoading ? 'center' : 'space-around'};;
  align-items: center;
  margin-top: 25px;
  height: 582.6px;
  width: 565px;
  background: ${({ theme }): any => theme.colors.WHITE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 30px;

  .ant-btn, .ant-btn-lg {
    height: 15%;
    min-height: 65px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }): any => theme.colors.GRAY};
    border-color: ${({ theme }): any => theme.colors.GRAY};
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    width: 100vw;
    border-bottom-style: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 20px;
    padding-bottom: 100px;

    .ant-form {
      width: 100%;
    }

  }
`

export const FormItem = styled(AntForm.Item)`
   margin-bottom: 10px;

  .ant-input-group-addon{
    background-color: ${({ theme }): any => theme.colors.GRAY};
    width: 56px;
  }

  .ant-input-suffix{
    width: 30px;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    margin-bottom: 0;
    .ant-input-lg{
      line-height: 3;
    }
  }
`

export const LogoImg = styled.img<{isLoading?:boolean}>`
  width: 125px;
  height: 62.5px;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    display: ${({ isLoading }) => isLoading ? 'block' : 'none'};;
  }
`

export const LogoImgMobile = styled.img`
  display: none;
  width: 130px;
  height: 65px;
  margin-bottom: 20px;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
   display: block;
  }
`

export const LoginSubTitle = styled.h2`
  text-align: center;
  font: normal normal normal 19px/50px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.GRAY};
`

export const Label = styled.h3`
  font: normal normal normal 19px/50px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.GRAY};
  margin: 0;
`

export const SmallLabel = styled.h4`
  font: normal normal normal 15px/50px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.GRAY};
  margin: 0;
`

export const LoginFooter = styled.h5`
  text-align: center;
  font: normal normal normal 12px/15px 'Exo 2';
  letter-spacing: 0px;
  opacity: 1;
  padding: 0 20px;
  margin: 15px 0;

  a, a:visited, a:hover, a:active {
    color:${({ theme }): any => theme.colors.YELLOW};
    text-decoration: none
  }

  a:hover{
    text-decoration: underline;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    display: none;
  }
`

export const SolBackgroundImg = styled.div`
  position: absolute;
  top: -27.5%;
  left: 65%;
  width: 35%;
  height: auto;
  z-index: 1;
  opacity: 1;
  overflow: hidden;
  img{
    width: 145%;
    height: 145%;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    display: none;
  }
`

export const FamilyBackgroundImg = styled.div`
  position: absolute;
  top: 4%;
  overflow: hidden;
  height: 817px;
  width: 100%;
  z-index: 0;
  opacity: 1;

  img {
    position: relative;
    width: 140%;
    height: 140%;
    top: -5%;
    left: -25%;
    z-index: 0;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    display: none;
  }
`

export const LoginButton = styled(Button)`
  background: ${({ theme }): any => theme.colors.GRAY} 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 493px;
  height: 64px;
  margin-top: 10px;

  :hover{
    background: ${({ theme }): any => theme.colors.YELLOW} 0% 0% no-repeat padding-box;
  }
  
  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    width: 100%;
  }
`

export const LabelButton = styled.h3`
  font: normal normal normal 19px/50px 'Exo 2';
  letter-spacing: 0px;
  color: #fff;
  margin: 0;
`

export const LinkArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 5px 0;

  a, a:visited, a:hover, a:active {
    color: ${({ theme }): any => theme.colors.GRAY};
    text-decoration: none;
  }

  a:hover{
    text-decoration: underline;
  }
`