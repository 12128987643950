import axios from 'axios'


const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
})

export const storageKey = 'PORTALDOCLIENTE:userData'

const storageUserData = localStorage.getItem(storageKey)

if (storageUserData) {
  const { jwt } = JSON.parse(storageUserData)
  api.defaults.headers.Authorization = `Bearer ${jwt}`
}

export default api