import React, {
  useState,
  useEffect,
  useRef
} from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { storageKey } from 'services/axios'
import { useAuth } from 'hooks/useAuth'
import ReCAPTCHA from 'react-google-recaptcha'
import InputMask, { InputState } from 'react-input-mask'
import {
  Input,
  Form,
  InputProps,
  InputRef,
  Spin
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import useChangePassword from 'repositories/useChangePassword'
import { initialValues, validation } from './validations'
import { useToasts, Options } from 'react-toast-notifications'
import Header from 'components/Header'
import Footer from 'components/Footer'
import { User as UserIcon } from 'components/Icons'
import SolImg from 'assets/sol_element.png'
import SolCopGrayLogo from 'assets/grayLogoSolCop.svg'
import SolCopWhiteLogo from 'assets/whiteLogoSolCop.svg'
import FamilyImg from 'assets/family_background.png'
import {
  Container,
  Canvas,
  SolBackgroundImg,
  FamilyBackgroundImg,
  Title,
  TitleContainer,
  SubTitle,
  LoadingContainer,
  LoginContainer,
  LogoImg,
  LoginSubTitle,
  LoginFooter,
  Label,
  SmallLabel,
  LoginButton,
  FormItem,
  LabelButton,
  LinkArea,
  LogoImgMobile,
  ContainerCAPTCHA
} from './styles'
import ChangePasswordModal from 'components/ChangePasswordModal'

export type TForgotPassword = {
  cpfCnpj: string
}

const toastSuccessConfig = {
  appearance: 'success',
  autoDismiss: true,
} as Options

const ForgotPassword = () => {
  const auth = useAuth()
  const [form] = Form.useForm<TForgotPassword>()
  const navigate = useNavigate()
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const [mask, setMask] = useState<string>('999.999.999-99')
  const [isLoading, setIsLoading] = useState(false)
  const [isBlocked, setIsBlocked] = useState(true)
  const [CAPTCHA, setCAPTCHA] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)


  const changePassword = useChangePassword()
  const { addToast } = useToasts()

  const { token } = useParams()

  useEffect(() => {
    if (!token) return
    setIsModalOpen(true)
  }, [token])

  const onChangeCAPTCHA = (value: string | null) => {
    if (!value) {
      setIsBlocked(true)
      return
    }
    setIsBlocked(false)
    setCAPTCHA(value)
  }

  const userLogin = async (values: TForgotPassword): Promise<void> => {
    if (CAPTCHA === '') return
    setIsLoading(true)
    if (!recaptchaRef.current) {
      setIsLoading(false)
      return
    }

    const formattedCpfCnpj = values.cpfCnpj.replace(/[^0-9]/g, '')

    const data = {
      cpfCnpj: formattedCpfCnpj,
      captchaResponse: CAPTCHA
    }

    const response = await changePassword.forgotPassword(data)
    recaptchaRef.current.reset()
    setIsBlocked(true)
    if (!response) {
      setIsLoading(false)
      return
    }

    addToast('Se seu CPF/CNPJ for válido, será enviada uma mensagem para o email cadastrado!', toastSuccessConfig)
    setIsLoading(false)
  }

  useEffect(() => {
    if (!auth || !auth.userData) return
    const requestExistentToken = Boolean(localStorage.getItem(storageKey))
    const isFirsAccess = Boolean(auth.userData.user.isFirstAccess)
    if (requestExistentToken && !isFirsAccess) navigate('/')
  }, [])

  const beforeMaskedValueChange = (newState: InputState, oldState: InputState, userInput: string): InputState => {
    const oldValue = (oldState.value || '').replace(/\D/g, '')
    const newValue = (newState.value || '').replace(/\D/g, '')
    const isErasure = oldValue.length > newValue.length

    if ((!userInput && !isErasure)) return newState

    const input = (userInput || '').replace(/\D/g, '')

    let updatedValue = ''
    if (input.length > 1 && newValue.length <= 11) {
      updatedValue = input
    } else if (oldValue.length === 11) {
      updatedValue = (newValue + input).substring(0, 14)
    } else {
      updatedValue = newValue
    }

    if ((updatedValue.length) > 11) {
      setMask('99.999.999/9999-99')
      const selectionValue = updatedValue.length + (updatedValue.length > 12 ? 4 : 3)
      return { ...newState, selection: { start: selectionValue, end: selectionValue }, value: updatedValue }
    }
    setMask('999.999.999-99')
    return newState
  }

  return (
    <Container>
      <Header fixed={false} />
      <Canvas>
        <SolBackgroundImg>
          <img src={SolImg} alt='Sol Laranja' />
        </SolBackgroundImg>
        <FamilyBackgroundImg>
          <img src={FamilyImg} alt='Familia' />
        </FamilyBackgroundImg>
        <LogoImgMobile
          src={SolCopWhiteLogo}
          alt='Logo Sol Cop Branco'
        />
        <TitleContainer>
          <Title>Bem vindo ao <b>Portal do Cliente</b></Title>
          <SubTitle>Tenha acesso aos seus dados e gastos com energia elétrica</SubTitle>
        </TitleContainer>
        <LoginContainer isLoading={isLoading}>
          <LogoImg
            src={SolCopGrayLogo}
            isLoading={isLoading}
            alt='Logo Sol Cop Cinza'
          />
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 70,
                  color: 'rgb(117, 133, 146)',
                  alignSelf: 'center',
                  marginTop: '20px',
                  display: isLoading ? 'inline-block' : 'none'
                }}
                width={'100%'}
                spin
              />
            }
          />
          <LoadingContainer isLoading={isLoading}>
            <LoginSubTitle>Entre para iniciar sua sessão</LoginSubTitle>
            <Form
              name='basic'
              initialValues={initialValues}
              layout='vertical'
              onFinish={userLogin}
              form={form}
              autoComplete='off'
              size='middle'
              requiredMark='optional'
            >
              <FormItem
                label={<Label>CPF/CNPJ</Label>}
                rules={validation('cpfCnpj')}
                name='cpfCnpj'
                id='input-cpfCnpj'
              >
                <InputMask
                  mask={mask}
                  maskChar='_'
                  alwaysShowMask={false}
                  beforeMaskedValueChange={beforeMaskedValueChange}
                >
                  {(inputProps: JSX.IntrinsicAttributes & InputProps & React.RefAttributes<InputRef>) =>
                    <Input
                      {...inputProps}
                      size='large'
                      addonBefore={<UserIcon size='22px' color='white' />}
                      placeholder='000.000.000-00'
                      maxLength={30}
                    />
                  }
                </InputMask>
              </FormItem>
              <LoginButton
                htmlType='submit'
                disabled={isBlocked}
              >
                <LabelButton>Confirmar</LabelButton>
              </LoginButton>
              <LinkArea>
                <a href='/login'><SmallLabel>Voltar</SmallLabel></a>
              </LinkArea>
              <ContainerCAPTCHA>
                <ReCAPTCHA
                  onChange={onChangeCAPTCHA}
                  onExpired={() => setIsBlocked(true)}
                  ref={recaptchaRef}
                  hl={'pt-BR'}
                  type={'image'}
                  sitekey='6LfFo00qAAAAACf6b4ZIvlt72TBdn35cL2Hsmn8S'
                />
              </ContainerCAPTCHA>
            </Form>
            <LoginFooter>
              Este aplicativo é protegido por reCAPTCHA e a <a href='/terms'>política de privacidade </a>
              e os <a href='/terms'>termos de serviço</a> do Google se aplicam.
            </LoginFooter>
          </LoadingContainer>
        </LoginContainer>
      </Canvas>
      <Footer />
      <ChangePasswordModal
        isOpen={isModalOpen}
        passwordType='forgotPassword'
        newPasswordToken={token}
        onCancel={() => setIsModalOpen(false)}
        onFinishOperation={() => {
          addToast('Senha alterada com sucesso!', toastSuccessConfig)
          navigate('/login')
        }
        }
      />
    </Container>
  )
}

export default ForgotPassword