import styled from 'styled-components'
import { Select as AntdSelect } from 'antd'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const SelectCustom = styled(AntdSelect)`
  .ant-select-selection-item, .ant-select-item-option-content, .ant-select-dropdown{
    text-align: center;
    font: normal normal normal 15px/19px 'Montserrat';
    letter-spacing: 0px;
    color: ${({ theme }): any => theme.colors.GRAY};
    opacity: 1;
    min-width: 145px;
  }

  :where(.css-dev-only-do-not-override-1m2t760).ant-select .ant-select-selection-placeholder{
    color: ${({ theme }): any => theme.colors.GRAY}!important;
    font: normal normal normal 15px/19px 'Montserrat';

    :hover{
      color: ${({ theme }): any => theme.colors.YELLOW}!important;
    }
  }

  
`