import { Rule } from 'antd/lib/form'
import { TReferNWinForm } from '.'

export const initialValues: TReferNWinForm = {
  isCompany: false,
  companyName: '',
  companyPhone: '',
  name: '',
  phone: '',
  responsibleName: ''
}

export const validation = (key: string) => {
  switch (key) {

    case 'responsibleName':
      return [{
        type: 'string',
        message: 'Não é um telefone válido!'
      },
      {
        required: true,
        message: 'Nome do responsável é obrigatório!'
      },
      {
        max: 50,
        message: 'Nome do responsável não pode possuir mais de 50 caracteres!'
      }] as Rule[]

    case 'companyName':
      return [{
        type: 'string',
        message: 'Não é um telefone válido!'
      },
      {
        required: true,
        message: 'Nome fantasia é obrigatório!'
      },
      {
        max: 50,
        message: 'Nome fantasia não pode possuir mais de 50 caracteres!'
      }] as Rule[]

    case 'companyPhone':
      return [{
        type: 'string',
        message: 'Não é um telefone válido!'
      },
      {
        required: true,
        message: 'Telefone é obrigatório!'
      },
      () => ({
        validator(_, value) {
          if (value.replace(/\D+/g, '').length < 10)
            return Promise.reject('Telefone precisa ter ao menos 10 dígitos.')
          return Promise.resolve()
        },
      })] as Rule[]

    case 'name':
      return [{
        type: 'string',
        message: 'Não é um telefone válido!'
      },
      {
        required: true,
        message: 'Nome do amigo(a) é obrigatório!'
      },
      {
        max: 50,
        message: 'Nome do amigo(a) não pode possuir mais de 50 caracteres!'
      }] as Rule[]

    case 'phone':
      return [{
        type: 'string',
        message: 'Não é um telefone válido!'
      },
      {
        required: true,
        message: 'Telefone é obrigatório!'
      },
      () => ({
        validator(_, value) {
          if (value.replace(/\D+/g, '').length < 10)
            return Promise.reject('Telefone precisa ter ao menos 10 dígitos.')
          return Promise.resolve()
        },
      })] as Rule[]


    default:
      [] as Rule[]
  }
}