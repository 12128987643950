import React from 'react'
import { Exclamation } from 'components/Icons'
import {
  StyledModal,
  Wrapper,
  ConfirmButton,
  LabelButton,
  ReturnButtonArea,
  SmallLabel,
  ConfirmButtonArea
} from './styles'

export type TConfirmationModalProps = {
  isOpen: boolean
  onFinishOperation: ()=> void
  onCancel: ()=> void
  okText: string
  confirmationText: string
}

const ConfirmationModal = (props: TConfirmationModalProps) => {
  const {
    isOpen = false,
    onFinishOperation,
    onCancel,
    confirmationText,
    okText
  } = props


  return (
    <StyledModal
      open={isOpen}
      onCancel={onCancel}
      width = '1180px'
      footer = {null}
      closeIcon = {null}
      centered
    >
      <Wrapper>
        <Exclamation size='112px' color='#758592' />
        <h2>{confirmationText}</h2>
        <ConfirmButtonArea>
          <ConfirmButton onClick={onFinishOperation}>
            <LabelButton>{okText}</LabelButton>
          </ConfirmButton>
        </ConfirmButtonArea>
        <ReturnButtonArea>
          <SmallLabel onClick={onCancel}>Voltar</SmallLabel>
        </ReturnButtonArea>
      </Wrapper>
    </StyledModal>
  )
}

export default ConfirmationModal