import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const ReferNWin = (props: TProps): JSX.Element => {
  const {
    size = '30',
    color = '#868686'
  } = props


  return (
    <svg id='ind_gan_icon' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width={size} height={size} viewBox='0 0 30 26'>
      <defs>
        <clipPath id='clip-path'>
          <rect id='Retângulo_132' data-name='Retângulo 132' width='30' height='26' fill={color} />
        </clipPath>
      </defs>
      <g id='Grupo_174' data-name='Grupo 174' clipPath='url(#clip-path)'>
        <path id='Caminho_1262' data-name='Caminho 1262' d='M13,22.18a5,5,0,0,1-5-5V15.36a1,1,0,0,1,.49-.86l3.63-2.18a1,1,0,0,1,1,1.72L10,15.93v1.25a3,3,0,0,0,3,3,1,1,0,0,1,0,2' fill={color} />
        <path id='Caminho_1263' data-name='Caminho 1263' d='M6,22.18H5a5,5,0,0,1-5-5V15.36a1,1,0,0,1,.49-.86l3.63-2.18a1,1,0,0,1,1,1.72L2,15.93v1.25a3,3,0,0,0,3,3H6a1,1,0,0,1,0,2' fill={color} />
        <path id='Caminho_1264' data-name='Caminho 1264' d='M9,12A6,6,0,0,1,9,0,1,1,0,0,1,9,2a4,4,0,0,0,0,8,1,1,0,0,1,0,2' fill={color} />
        <path id='Caminho_1265' data-name='Caminho 1265' d='M17,12a6,6,0,1,1,6-6,6,6,0,0,1-6,6M17,2a4,4,0,1,0,4,4,4,4,0,0,0-4-4' fill={color} />
        <path id='Caminho_1266' data-name='Caminho 1266' d='M23,26a7,7,0,1,1,7-7,7,7,0,0,1-7,7m0-12a5,5,0,1,0,5,5,5,5,0,0,0-5-5' fill={color} />
        <rect id='Retângulo_130' data-name='Retângulo 130' width='6' height='2' transform='translate(20 18)' fill={color} />
        <rect id='Retângulo_131' data-name='Retângulo 131' width='2' height='6' transform='translate(22 16)' fill={color} />
      </g>
    </svg>
  )
}

export default ReferNWin