import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const ConsumerUnit = (props: TProps): JSX.Element => {
  const {
    size = '14',
    color = '#868686'
  } = props

  return (
    <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width={size} height={size} viewBox='0 0 57 51.194'>
      <defs>
        <clipPath id='ConsumerUnit-clip-path'>
          <rect id='Retângulo_261' data-name='Retângulo 261' width={size} height={size} fill={color} />
        </clipPath>
        <clipPath id='ConsumerUnit-clip-path-2'>
          <rect id='Retângulo_262' data-name='Retângulo 262' width='5.654' height='11.106' fill='none' stroke={color} strokeWidth='1.3' />
        </clipPath>
      </defs>
      <g id='un_icon' transform='translate(-67 -25.806)'>
        <g id='Grupo_281' data-name='Grupo 281' transform='translate(67 25.806)'>
          <g id='Grupo_280' data-name='Grupo 280' clipPath='url(#ConsumerUnit-clip-path)'>
            <path id='Caminho_1286' data-name='Caminho 1286' d='M37.876,310.582c-.209-.086-.415-.181-.628-.255-3.7-1.291-7.4-2.591-11.118-3.852a2.552,2.552,0,0,0-1.343-.054c-3.341.8-6.672,1.638-10.006,2.464-.132.033-.264.066-.4.093a.992.992,0,0,1-1.257-.735.943.943,0,0,1,.807-.984c1.465-.379,2.936-.739,4.4-1.1l17.233-4.271c.169-.042.34-.081.512-.113a.9.9,0,0,1,1.131.544.882.882,0,0,1-.733,1.176c-1.634.423-3.275.82-4.913,1.229a18.311,18.311,0,0,0-2.593.792c.182.077.359.164.545.229,3.557,1.24,7.111,2.487,10.677,3.7a1.919,1.919,0,0,0,1.169.037c3.5-1.238,6.977-2.517,10.462-3.786.124-.045.242-.109.509-.23-.31-.1-.494-.166-.682-.213q-3.67-.913-7.341-1.821c-.207-.051-.484-.061-.6-.2a1.681,1.681,0,0,1-.492-.945.793.793,0,0,1,.917-.7,4.48,4.48,0,0,1,.8.16q5.082,1.265,10.164,2.535,5.926,1.469,11.856,2.923c.692.17,1.026.572.916,1.12s-.608.793-1.342.612c-3.387-.835-6.774-1.666-10.155-2.523a2.431,2.431,0,0,0-1.521.084c-3.459,1.276-6.928,2.527-10.393,3.787-.231.084-.456.182-.836.334.338.134.526.216.719.283L57.826,315.6c.165.058.33.116.493.177.717.267.994.709.787,1.256-.183.483-.741.655-1.385.43L48.1,314.1c-2.2-.766-4.4-1.521-6.594-2.3a1.681,1.681,0,0,0-1.229,0q-7.686,2.812-15.385,5.59c-.383.138-.809.444-1.188.055-.208-.213-.464-.588-.4-.805a1.371,1.371,0,0,1,.7-.8c1.4-.566,2.839-1.055,4.262-1.572q4.517-1.64,9.033-3.283c.2-.071.385-.162.577-.244l0-.156' transform='translate(-11.587 -266.442)' fill={color} />
            <path id='Caminho_1287' data-name='Caminho 1287' d='M165.932,11.17a12.89,12.89,0,0,1-2.227,7.156,68.9,68.9,0,0,0-6.947,13.025c-.563,1.446-1.044,2.923-1.562,4.387-.052.146-.094.3-.144.443a.89.89,0,0,1-.888.7.906.906,0,0,1-.88-.713c-.587-1.642-1.134-3.3-1.78-4.917a59.572,59.572,0,0,0-6.363-11.82,15.9,15.9,0,0,1-2.213-4.4A11.2,11.2,0,0,1,145.167,4.2,10.907,10.907,0,0,1,152.743.075a11.547,11.547,0,0,1,12.479,7.742,21.643,21.643,0,0,1,.71,3.353M154.147,33.011c.176-.4.3-.664.405-.932.413-1.025.8-2.06,1.234-3.075a72.925,72.925,0,0,1,6.365-11.568,10.111,10.111,0,0,0,1.782-7.6,10.017,10.017,0,0,0-7.739-7.844,9.636,9.636,0,0,0-9.15,2.8,9.485,9.485,0,0,0-2.351,9.884,15.2,15.2,0,0,0,2.132,4.05,59.081,59.081,0,0,1,6.5,12.335c.23.611.507,1.2.82,1.944' transform='translate(-125.792 0)' fill={color} />
            <path id='Caminho_1288' data-name='Caminho 1288' d='M285.75,275.294q-5.594,0-11.189,0c-.962,0-1.422-.3-1.415-.915.007-.575.479-.871,1.4-.871h22.377a5.03,5.03,0,0,1,.524.011.883.883,0,0,1,.871.908.845.845,0,0,1-.857.85c-.406.031-.815.02-1.223.02q-5.245,0-10.489,0' transform='translate(-241.319 -241.636)' fill={color} />
            <path id='Caminho_1289' data-name='Caminho 1289' d='M12.592,275.295q-5.506,0-11.011,0a6.274,6.274,0,0,1-.814-.029A.8.8,0,0,1,0,274.454a.833.833,0,0,1,.722-.914,2.838,2.838,0,0,1,.638-.033q11.186,0,22.372,0a3.726,3.726,0,0,1,.639.027c.536.094.717.442.7.967a.731.731,0,0,1-.713.766,4.628,4.628,0,0,1-.7.027l-11.069,0' transform='translate(0 -241.635)' fill={color} />
          </g>
        </g>
        <g id='Grupo_283' data-name='Grupo 283' transform='translate(92.673 34.192)'>
          <g id='Grupo_282' data-name='Grupo 282' clipPath='url(#ConsumerUnit-clip-path-2)'>
            <path id='Caminho_1290' data-name='Caminho 1290' d='M9.27,4.5H5.182L4.5,10.633H5.863l-.681,4.088L9.27,7.907H7.907Z' transform='translate(-4.058 -4.058)' fill='none' stroke={color} strokeLinejoin='round' strokeWidth='1.3' />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ConsumerUnit