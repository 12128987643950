import styled from 'styled-components'

export const StyledHeader = styled.div`
  background-image: linear-gradient(to right, ${({ theme }): any => theme.colors.RED}, ${({ theme }): any => theme.colors.ORANGE});
  width: 100%;
  height: 55px;
  padding: 8px 0 4px 18.5%;
  color: white;
  z-index: 999;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    display: none;
  }
`

export const LogoImg = styled.img`
  width: 80px;
  height: 40px;
  :hover{
    cursor: pointer;
  }
`