import { useState } from 'react'
import useRest from 'hooks/useRest'
import type { RcFile } from 'antd/es/upload/interface'
import { AxiosResponse } from 'axios'
import { storageKey } from 'services/axios'
import { addConsumerUnitParam } from 'utils/helpers'


export type TRequestTokenParams = {
  cpfCnpj: string
  password: string
  captchaResponse: string
}

export type TPatchTokenParams = {
  newTelephone?: string
  newEmail?: string
}

const useMyProfile = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getMyProfile = async (triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/myProfile'
    const params = addConsumerUnitParam({}, storageKey)

    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    return response as any
  }

  const patchMyProfile = async (data:TPatchTokenParams, triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/myProfile'

    const response = await rest.patch(url, data)
    if (triggerLoading) setIsLoading(false)
    return response as any
  }

  const newProfilePicture = async (data: RcFile, triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/myProfile/profilePicture'
    const header = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }

    const formData = new FormData()
    formData.append('newProfilePicture', data)

    const response = await rest.patchWithHeader(url, formData, header)
    if (triggerLoading) setIsLoading(false)
    return response as AxiosResponse
  }


  return {
    isLoading,
    getMyProfile,
    patchMyProfile,
    newProfilePicture
  }
}

export default useMyProfile