import styled from 'styled-components'
import { Button, Form as AntForm } from 'antd'
import BackgroundImg from 'assets/indique&ganhe_banner.png'

export const Canvas = styled.div`
  background-image: url(${BackgroundImg});
  background-repeat: no-repeat;
  background-position: left top;
  background-color: ${({ theme }): any => theme.colors.LIGHT_GRAY};
  background-size: cover;
  width: ${({ theme }): any => theme.breakPoints.CONTENT_MAX_WIDTH};
  height: 770px;
  border-radius: 15px;
  margin-top: -10px;
  margin-left: -10px;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    background-image: none;
    width: auto;
    margin-top: -5px;
    margin-left: 0;
  }
`

export const FormDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: end;
  width: 1005px;
  padding-top: 130px;
  padding-left: 60px;
  gap: 60px;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    position: relative;
    gap: 20px;
    top: -250px
  }
`

export const FormContainer = styled.div`
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  height: auto;
  width: 468px;
  min-height: 500px;
  background: ${({ theme }): any => theme.colors.WHITE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 50px;

  .ant-btn, .ant-btn-lg {
    height: 15%;
    min-height: 65px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }): any => theme.colors.GRAY};
    border-color: ${({ theme }): any => theme.colors.GRAY};
  }

  .ant-radio-group {
    span{
      text-align: left;
      font: normal normal 600 16px/19px 'Exo 2';
      letter-spacing: 0px;
      color: ${({ theme }): any => theme.colors.GRAY};
    }
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    width: 100%;
    border-radius: 15px;
    border-top-style: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 20px;
    min-height: 540px;
    margin-bottom: 0;

    .ant-form {
      width: 100%;
    }
  }
`

export const FormTitle = styled.h2`
  text-align: center;
  font: normal normal 600 30px/32px 'Exo 2';
  letter-spacing: 0px;
  color: #FAC541;
  opacity: 1;
`

export const SubmitButton = styled(Button)`
  background: ${({ theme }): any => theme.colors.GRAY} 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 398px;
  height: 64px;
  margin-top: 10px;

  :hover{
    background: ${({ theme }): any => theme.colors.YELLOW} 0% 0% no-repeat padding-box;
  }
  
  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    width: 100%;
  }
`

export const RedirectButton = styled(Button)`
  background: ${({ theme }): any => theme.colors.ORANGE} 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 398px;
  height: 64px;
  margin-top: 10px;

  :hover{
    background: ${({ theme }): any => theme.colors.YELLOW} 0% 0% no-repeat padding-box;
  }
  
  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    margin-top: 0;
    width: 90%;
  }
`


export const LabelButton = styled.h3`
  font: normal normal normal 25px/30px 'Exo 2';
  letter-spacing: 0px;
  color: #fff;
  margin: 0;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    font: normal normal normal 20px/25px 'Exo 2';
  }
`

export const FormItem = styled(AntForm.Item)`
   margin-bottom: 5px;

  .ant-input-group-addon{
    background-color: ${({ theme }): any => theme.colors.GRAY};
    width: 56px;
  }

  .ant-input-suffix{
    width: 30px;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    margin-bottom: 0;
    .ant-input-lg{
      line-height: 3;
    }
  }
`

export const Label = styled.h3`
  font: normal normal normal 19px/35px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.GRAY};
  margin: 0;
`

export const MobileBackgroundImg = styled.div`
  display: none;
 
  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    display: inline-block;
    height: 581px;
    width: 100%;
    background-color:  ${({ theme }): any => theme.colors.RED};
    border-radius: 10px;
    img{
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
`
