import React from 'react'

import {
  Container,
} from './styles'

import Menu from '../Menu'

const Sidebar = (): JSX.Element => {

  return (
    <Container>
      <Menu />
    </Container>
  )
}

export default Sidebar