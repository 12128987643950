import styled from 'styled-components'
import { Modal as AntdModal, Button } from 'antd'

export const StyledModal = styled(AntdModal)`
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;

  h2 {
    text-align: center;
    font: normal normal normal 27px/50px 'Exo 2';
    letter-spacing: 0px;
    color: ${({ theme }): any => theme.colors.GRAY};
    padding-top: 50px;

    @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
      font: normal normal normal 27px/34px 'Exo 2';
      padding: 100px 30px 0 30px;
    }
  }

  h3 {
    text-align: left;
    font: normal normal normal 20px/50px 'Exo 2';
    letter-spacing: 0px;
    color: ${({ theme }): any => theme.colors.GRAY};
    width: 496px;
    @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
      width: 282px;
    }
  }

  h4 { 
    text-align: left;
    font: normal normal normal 17px/25px 'Exo 2';
    letter-spacing: 0px;
    color: ${({ theme }): any => theme.colors.GRAY};
    width: 496px;
    padding: 10px 0 0 5px;
    @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
      font: normal normal normal 16px/22px 'Exo 2';
      width: 282px;
    }
  }

  h4:hover{
    text-decoration: underline;
    cursor:pointer;
  }

  h5 { 
    text-align: center;
    font: normal normal normal 16px/25px 'Exo 2';
    letter-spacing: 0px;
    color: ${({ theme }): any => theme.colors.GRAY};
    @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
      font: normal normal normal 16px/22px 'Exo 2';
      padding: 0 25px;
    }
  }

  .ant-input {
    width: 65px;
    height: 76px;
    text-align: center;
    font: normal normal normal 27px/50px 'Exo 2';

    @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
      width: 45px;
    }
  }

  .ant-form-item {
    @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
      margin-inline-end: 2px;
    }
  }
`
export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ConfirmButton = styled(Button)`
  background: ${({ theme }): any => theme.colors.GRAY} 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 496px;
  height: 64px;
  margin-top: 25px;
  margin-bottom: 50px;

  :hover{
    background: linear-gradient(to right, ${({ theme }): any => theme.colors.RED}, ${({ theme }): any => theme.colors.ORANGE});
  }
  
  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    width: 282px;
    margin-bottom: 125px;
  }
`

export const LabelButton = styled.h6`
  font: normal normal normal 19px/50px 'Exo 2';
  letter-spacing: 0px;
  color: #fff;
  margin: 0;
  text-align: center;
`