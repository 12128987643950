import React, { useState } from 'react'
import type { CollapseProps } from 'antd'
import {
  BulbFilled, UpOutlined
} from '@ant-design/icons'

import { theme } from 'styles'
import {
  Container,
  CollapseCustom,
  Link
} from './styles'

import Fatura from 'assets/fatura.png'
import EndiqueEGanhe from 'assets/indiqueganhe.png'

const Accordion = () => {

  const [defaultActiveKey, setDefaultActiveKey] = useState<string | string[]>([])

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: '#fff',
    borderRadius: 10,
    border: 'none',
  }

  const onRenderCollapseItem = (key:string) => {
    window.location.hash = `#${key}`
    setDefaultActiveKey([key])
  }

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Como funciona a assinatura de energia solar?',
      children:
      <div>
        <p>A assinatura de energia solar da SOL Copérnico funciona de forma simples: ao invés de receber energia de uma concessionária tradicional, a SOL Copérnico distribui a energia para sua casa ou negócio através da rede de distribuição, sem que seja necessário fazer instalações, obras ou se preocupar com gastos extras.</p>
        <p>Uma das principais vantagens da assinatura é a economia na conta de luz, já que a tarifa cobrada pela SOL Copérnico é mais barata que a da concessionária. Assim, é possível economizar e utilizar energia limpa sem investimentos em equipamentos solares.</p>
        <p>É importante lembrar que, mesmo recebendo a energia da SOL Copérnico, ainda será necessário pagar uma fatura à concessionária pelo uso da rede de distribuição, iluminação pública e outros encargos. Para mais informações consulte o artigo: <Link onClick={() => onRenderCollapseItem('15')}>Por que eu recebi uma cobrança da CEMIG?</Link></p>
        <p> Além disso, se o consumo da unidade consumidora exceder o volume de energia enviado pela SOL Copérnico, o restante será fornecido pela concessionária. Para mais informações consulte o artigo: <Link onClick={() => onRenderCollapseItem('8')}>Qual o volume de energia será enviada para minha residência pela Sol Copérnico?</Link></p>
      </div>,
      style: panelStyle,
    },
    {
      key: '2',
      label: 'Como contratar a assinatura de energia solar?',
      children:
      <div>
        <h3>Requisitos para assinatura</h3>
        <p>A SOL Copérnico oferece o serviço de assinatura de energia solar, também conhecido como Geração Compartilhada, que está disponível para qualquer pessoa física, empresa ou estabelecimento comercial devidamente inscritos na Receita Federal do Brasil e conectados na rede de distribuição da Distribuidora local. Para a adesão com a SOL Copérnico, é necessário apresentar um documento válido, CPF ou CNPJ e a unidade consumidora estar localizada em Minas Gerais.</p>
        <h3>Processo de Contratação</h3>
        <p>Para contratar o serviço de energia solar por assinatura, faremos uma análise rápida da sua solicitação e entraremos em contato para confirmar a contratação. Em seguida, enviaremos o contrato de &ldquo;Contrato de Adesão a Usina Solar Fotovoltaica e outras avenças&ldquo; para assinatura digital, oficializando a contratação do serviço.</p>
        <p>Após análise da documentação você começará a receber sua energia solar por assinatura dentro do período de conexão. Para mais informações, consulte o artigo <Link onClick={() => onRenderCollapseItem('3')}>Em que momento eu começo a receber energia da Sol Copérnico?</Link></p>
      </div>,
      style: panelStyle,
    },
    {
      key: '3',
      label: 'Quando começo a receber energia da Sol Copérnico?',
      children:
      <div>
        <p>A partir do momento em que você realiza a assinatura conosco, o prazo inicial para começar a receber energia é de 90 dias.</p>
        <p>No entanto, é importante ressaltar que esse prazo pode ser prorrogado devido ao processo de conexão da sua instalação em nossas usinas, que está sujeito às etapas junto à distribuidora local.</p>
      </div>,
      style: panelStyle,
    },
    {
      key: '4',
      label: 'Comparação entre a tarifa Sol Copérnico e CEMIG',
      children:
        <div>
          <p>A diferença consiste nos componentes presentes na composição da tarifa. No caso da CEMIG, a tarifa de energia elétrica inclui os tributos (PIS/COFINS, ICMS).</p>
          <p>Na tarifa SOL Copérnico energia solar, na modalidade de Geração Compartilhada, é isento o PIS/COFINS, aplicando o desconto contratado, resultando em uma tarifa de valor mais baixo e garantindo a economia. Importante ressaltar que o PIS/COFINS é isento pois o consumidor já paga este valor à Distribuidora.</p>
        </div>,
      style: panelStyle,
    },
    {
      key: '5',
      label: 'Como calculo minha economia?',
      children:
          <div>
            <p>Em sua fatura SOL Copérnico, você pode observar no meio do relatório um demonstrativo em gráfico de valores CEMIG e SOL Copérnico + CEMIG.</p>
            <p>O que isso significa:</p>
            <p>CEMIG: você pagaria todos os encargos somados ao seu consumo para a Distribuidora totalizando um valor cheio sem desconto.</p>
            <p>SOL Copérnico + CEMIG: você pagará o seu consumo de energia aplicando o desconto contratado sobre uma tarifa sem PIS/COFINS. E para a CEMIG a taxa mínima contando: custo de disponibilidade, iluminação pública e PIS/COFINS.</p>
            <p>O que quer dizer que se somar ambos os valores com a SOL Copérnico, é possível perceber que houve o desconto comparado ao que pagaria apenas para a CEMIG. O cálculo de quanto pagaria se estivesse apenas com a CEMIG pode ser observado na sua fatura SOL Copérnico. Para saber mais confira o artigo <Link onClick={() => onRenderCollapseItem('10')}>Como ler minha fatura?</Link>.</p>
          </div>,
      style: panelStyle,
    },
    {
      key: '6',
      label: 'Posso realizar a assinatura para minha empresa?',
      children:
            <div>
              <p>Sim, a assinatura de energia solar da SOL Copérnico está disponível para pequenos e médios negócios. Para saber mais sobre os requisitos de portabilidade e como contratar, confira o artigo <Link onClick={() => onRenderCollapseItem('2')}>Como contratar a assinatura de energia solar ?</Link>.</p>
            </div>,
      style: panelStyle,
    },
    {
      key: '7',
      label: 'Quais as vantagens da energia solar por assinatura?',
      children:
            <div>
              <p>A energia solar por assinatura é uma solução inovadora que traz diversos benefícios aos assinantes. Destacamos as principais vantagens econômicas, ambientais e práticas que essa modalidade oferece.</p>
              <h3>Sem investimentos</h3>
              <p>Nada muda estruturalmente na sua casa ou empresa. Ou seja, você não precisa fazer nenhum tipo de investimento ou obras no local.</p>
              <h3>Redução na conta de luz</h3>
              <p>Ao aderir à energia solar por assinatura, há uma redução nos gastos com energia elétrica, pois a tarifa de energia da SOL Copérnico é menor em comparação com as tarifas cobradas por concessionárias tradicionais.</p>
              <h3>Conveniência e facilidade</h3>
              <p>Além de não precisar realizar nenhum tipo de instalação na sua propriedade, o processo de assinatura é 100% digital, sem precisar sair de casa.</p>
              <h3>Flexibilidade e escalabilidade</h3>
              <p>A energia solar por assinatura oferece flexibilidade, pois a quantidade de energia enviada pela SOL Copérnico se ajusta de acordo com suas necessidades.</p>
              <p>Com isso, também temos escalabilidade, permitindo que o volume de energia fornecido, seja ajustado caso seu consumo aumente ao longo do tempo. Para mais informações consulte o artigo:  <Link onClick={() => onRenderCollapseItem('8')}>Qual o volume de energia será enviada para minha residência pela Sol Copérnico?</Link>.</p>
              <p>*Referente ao tempo de envio das cotas de energia, para mais informações consulte o artigo: <Link onClick={() => onRenderCollapseItem('3')}>Em que momento eu começo a receber energia da Sol Copérnico?</Link></p>
              <h3>Sustentabilidade ambiental</h3>
              <p>
A energia solar é uma fonte de energia limpa e renovável, o que significa que sua utilização reduz a dependência de combustíveis fósseis e contribui para a redução das emissões de gases de efeito estufa e outros impactos ambientais negativos.</p>
            </div>,
      style: panelStyle,
    },
    {
      key: '8',
      label: 'Qual o volume de energia será enviada para minha residência pela Sol Copérnico?',
      children:
            <div>
              <p>A energia fornecida pela SOL Copérnico é gerada em nossas usinas solares e distribuída aos nossos assinantes.</p>
              <p>Ao assinar nosso serviço, você recebe uma cota do total gerado pela usina, essa cota será baseada na sua média de consumo dos últimos meses.</p>
              <p>É importante ressaltar que, caso o seu consumo exceda o volume de energia fornecido pela SOL Copérnico, a energia adicional será suprida pela concessionária, que cobrará essa energia juntamente com os custos de iluminação pública, impostos e outros encargos, seguindo a tarifa da concessionária. Para mais informações, consulte o artigo: <Link onClick={() => onRenderCollapseItem('15')}>Por que eu recebi uma cobrança da CEMIG?.</Link></p>
            </div>,
      style: panelStyle,
    },
    {
      key: '9',
      label: 'Como é feita a cobrança de mensalidade da minha assinatura?',
      children:
            <div>
              <p>A mensalidade de sua assinatura será enviada através do boleto SOL Copérnico, com o desconto contratado já aplicado ao valor da tarifa de energia.</p>
              <p>Se preferir, você pode acessar o boleto no Portal do Cliente na aba &ldquo;Faturas&ldquo; ou entrar em contato pelo WhatsApp (11) 3136-0251.</p>
              <h3>Formas de pagamento</h3>
              <p>A fatura SOL pode ser paga via boleto (código de barras) ou PIX QR Code.</p>
              <h3>Período de cobrança</h3>
              <p>O período de cobrança de sua conta SOL Copérnico segue o mesmo padrão de leitura da Distribuidora. Ou seja, assim que recebemos as suas informações de consumo e compensação da Distribuidora, iremos gerar a sua fatura com base no seu consumo do mês.</p>
              <h3>Cobrança de impostos</h3>
              <p>Impostos, juros, multas, doações e outros encargos são cobrados em sua conta da Distribuidora. Para mais informações, consulte o artigo <Link onClick={() => onRenderCollapseItem('15')}>Por que eu recebi uma conta da CEMIG?</Link>.</p>
            </div>,
      style: panelStyle,
    },
    {
      key: '10',
      label: 'Como ler minha fatura?',
      children:
              <div>
                <h3>Como ler minha fatura?</h3>
                <p><BulbFilled style={{ color: theme.colors.YELLOW }} /> Importante: Para acessar as legendas explicativas da fatura de exemplo, realize o download do arquivo ou acesse o link: <a href='https://faturas.solcopernico.com.br/Fatura_Exemplo_Sol_Copernico.pdf'>Fatura de Exemplo SOL Copérnico</a></p>
                <img src={Fatura} height='100%' width='100%' />
                <p><b>Ou acesse</b> o Portal do Cliente na aba “Faturas” para ver maiores detalhes.</p>
              </div>,
      style: panelStyle,
    },
    {
      key: '11',
      label: 'Posso pagar minha assinatura através de débito automático?',
      children:
            <div>
              <p><b>Atualmente, essa modalidade de pagamento</b> não está disponível. Estamos trabalhando para oferecer mais opções de pagamento aos nossos clientes.</p>
              <p>Fique atento às nossas atualizações, em breve informaremos sobre quaisquer mudanças futuras relacionadas ao débito automático.</p>
            </div>,
      style: panelStyle,
    },
    {
      key: '12',
      label: 'Como solicitar a segunda via da fatura?',
      children:
              <div>
                <p>A segunda via da fatura SOL Copérnico pode ser solicitada através dos nossos canais de atendimento:</p>
                <ul>
                  <li><b>De forma automática, pelo WhatsApp da Central de Atendimento:</b> (11) 3136-0251</li>
                  <li style={{ color: 'blue' }}><b style={{ color: 'black' }}>E-MAIL:</b> sac@copernico.com.br</li>
                  <li>Portal do Cliente na aba “Faturas”</li>
                </ul>
              </div>,
      style: panelStyle,
    },
    {
      key: '13',
      label: 'Posso cancelar minha assinatura a qualquer momento?',
      children:
                <div>
                  <p>Sim, você pode cancelar sua assinatura a qualquer momento através dos nossos canais oficiais de atendimento.</p>
                  <p>Após solicitar o cancelamento, será necessário aguardar um período de 90 dias para que a desconexão seja concluída pela distribuidora. Durante esse prazo, a instalação poderá continuar recebendo injeção de energia.</p>
                  <p>Após os 90 dias, caso haja saldo remanescente com a SOL Copérnico, ele será cobrado até que seja todo efetivamente compensado.</p>
                </div>,
      style: panelStyle,
    },
    {
      key: '14',
      label: 'Não recebi minha fatura',
      children:
                <div>
                  <p>As faturas são enviadas mensalmente para o e-mail e WhatsApp cadastrados do titular da assinatura. <b>Se você não recebeu sua fatura, é possível emitir automaticamente uma segunda via pelo nosso WhatsApp</b>, Portal do Cliente na aba “Faturas” <b>ou através dos nossos canais oficiais de atendimento</b>.</p>
                  <p><b>TEL</b>: 0800 068 0800</p>
                  <p><b>WhatsApp da Central de Atendimento</b>: (11) 3136-0251</p>
                  <p>Lembre-se que além da fatura da SOL Copérnico, você deve receber uma cobrança de sua distribuidora de energia, para saber o porquê, consulte o artigo &ldquo;Por que eu recebi uma cobrança da CEMIG ?&ldquo;.</p>
                </div>,
      style: panelStyle,
    },
    {
      key: '15',
      label: 'Por que eu recebi uma conta da CEMIG?',
      id: '15',
      children:
                <div>
                  <p>Mensalmente, você receberá duas faturas, uma da SOL Copérnico e outra da sua concessionária de energia.</p>
                  <p>A fatura da SOL Copérnico é referente à energia compensada em sua instalação. O custo tarifário dela é menor do que o cobrado pela concessionária, o que resulta em economia para você.</p>
                  <p><b>A cobrança da distribuidora será referente ao uso da rede, iluminação pública, impostos e outros encargos da própria distribuição</b>. Além disso, se o consumo da unidade consumidora exceder o volume de energia enviado pela SOL Copérnico, o restante será fornecido pela concessionária.</p>
                </div>,
      style: panelStyle,
    },
    {
      key: '25',
      label: 'Diferença no valor da fatura da distribuidora em relação ao demonstrativo do boleto SOL Copérnico.',
      id: '25',
      children:
                <div>
                  <p>Se o valor da sua fatura da distribuidora estiver diferente do valor indicado em nosso relatório, verifique se não foram aplicados juros, multas ou doações adicionais à sua fatura mensal.</p>
                  <p>Caso não identifique nenhuma dessas ocorrências, entre em contato com nossa equipe de atendimento para que possamos analisar sua fatura e prestar assistência necessária.</p>
                </div>,
      style: panelStyle,
    },
    {
      key: '16',
      label: 'O que acontece se eu atrasar o pagamento da mensalidade da assinatura de energia solar?',
      children:
                <div>
                  <p>O não pagamento do boleto SOL Copérnico acarretará a suspensão de energia em sua instalação.</p>
                </div>,
      style: panelStyle,
    },
    {
      key: '17',
      label: 'Como funciona e como faço para indicar?',
      children:
                <div>
                  <p>A SOL Copérnico oferece um programa de indicações que lhe trará mais economia! Ao indicar amigos, familiares ou colegas a assinarem conosco, para cada indicação, você receberá R$ 200,00 em bônus para abater em sua próxima fatura SOL Copérnico.</p>
                  <img src={EndiqueEGanhe} height='85%' width={'85%'} />
                  <p><b>Importante</b>, é necessário que o indicado assine contrato conosco e se torne um cliente ativo para que seu crédito seja validado e liberado.</p>
                </div>,
      style: panelStyle,
    },
    {
      key: '18',
      label: 'Como indicar?',
      children:
                <div>
                  <p>As indicações podem ser feitas através do Portal do Cliente e canais oficiais de atendimento:</p>
                  <p><b>TEL</b>: 0800 068 0800</p>
                  <p><b>WhatsApp da Central de Atendimento</b>: (11) 3136-0251</p>
                  <p><b>Ou contatando diretamente nosso representante, via telefone ou WhatsApp, pelo número (31) 99606-9370.</b></p>
                </div>,
      style: panelStyle,
    },
    {
      key: '19',
      label: 'Crédito de Indicação Não Recebido',
      children:
                <div>
                  <p>Caso você tenha indicado alguém e não tenha recebido o crédito correspondente, pedimos que aguarde até o próximo mês para que a compensação seja realizada. O prazo para confirmação do seu bônus é de 30 dias.</p>
                  <p>Se a situação persistir, entre em contato com a nossa equipe de atendimento através do WhatsApp ou por telefone, para que possamos verificar o ocorrido.</p>
                  <p><b>TEL</b>: 0800 068 0800</p>
                  <p><b>WhatsApp da Central de Atendimento</b>: (11) 3136-0251</p>
                </div>,
      style: panelStyle,
    },
    {
      key: '20',
      label: 'Qual o limite de indicações que posso fazer?',
      children:
                <div>
                  <p><b>Não há limite no número de indicações que você pode realizar.</b></p>
                  <p>A cada cliente indicado que assinar conosco, os créditos serão somados ao seu saldo acumulado para abatimento em sua conta de energia SOL Copérnico.</p>
                  <p>Vale lembrar que as indicações são contabilizadas por unidade consumidora (instalação).</p>
                  <p>Regulamento do programa ‘Indique e ganhe’</p>
                  <p>Segue abaixo o link para o regulamento do programa:</p>
                  <p><a href='https://faturas.solcopernico.com.br/Regulamento+Programa+de+Indica%C3%A7%C3%A3o.pdf'>REGULAMENTO</a></p>
                </div>,
      style: panelStyle,
    },
    {
      key: '21',
      label: 'Posso fazer a assinatura morando em uma casa alugada?',
      children:
                <div>
                  <p>Sim, é possível realizar a assinatura de energia mesmo residindo em uma casa alugada. Para isso, basta garantir que a conta de luz esteja registrada em seu nome. É importante ressaltar que, caso ocorra uma mudança de endereço ou a necessidade de transferência de titularidade, é fundamental nos informar imediatamente para que possamos realizar as devidas atualizações.</p>
                </div>,
      style: panelStyle,
    },
    {
      key: '22',
      label: 'Mudei de endereço, o que devo fazer?',
      children:
                <div>
                  <p>Se você mudou de endereço após o cadastro para economia de energia, é importante nos informar imediatamente para que possamos encerrar o plano atual e transferir o desconto para o seu novo endereço.</p>
                  <p>Por favor, entre em contato conosco o mais rápido possível para que possamos atualizar as informações e garantir que você continue a receber os benefícios de economia de energia no seu novo local.</p>
                </div>,
      style: panelStyle,
    },
    {
      key: '23',
      label: 'Já possuo um sistema solar, mesmo assim posso fazer a assinatura?',
      children:
                <div>
                  <p>Sim, mesmo que você já possua um sistema próprio de energia solar, ainda é possível fazer a assinatura de energia. Essa opção é viável apenas se o seu sistema não suprir 100% do seu consumo mensal de energia. Ao fazer a assinatura, você pode complementar a energia gerada pelo seu sistema solar e garantir que todas as suas necessidades energéticas sejam atendidas.</p>
                </div>,
      style: panelStyle,
    },
    {
      key: '24',
      label: 'A Regulação de Energia Solar por Assinatura no Brasil',
      children:
                <div>
                  <p>Energia Solar por assinatura no Brasil é regulamentada e fiscalizada pela <b>ANEEL (Agência Nacional de Energia Elétrica)</b>. A ANEEL é a agência governamental responsável por regular o setor elétrico no país, incluindo a geração, transmissão, distribuição e comercialização de energia.</p>
                  <p>Através da RN 482 e posteriormente a Lei 14.300, foram estabelecidas as regras para a geração distribuída de energia no Brasil, incluindo a modalidade de Energia Solar por assinatura. Essa modalidade permite que consumidores compartilhem os benefícios da energia solar sem necessidade de instalação de painéis solares em suas próprias residências ou estabelecimentos comerciais.</p>
                  <p>Ao aderir a um sistema de Energia Solar por assinatura, os consumidores se tornam assinantes de uma usina solar compartilhada, recebendo créditos proporcionais à energia gerada pela usina. Esses créditos são utilizados para compensar o consumo de energia da unidade consumidora, resultando em economia na conta de luz e contribuindo para a sustentabilidade ambiental.</p>
                </div>,
      style: panelStyle,
    },
  ]

  return (
    <Container>
      <CollapseCustom
        accordion
        items={items}
        size='large'
        expandIconPosition='end'
        activeKey={defaultActiveKey}
        expandIcon={({ isActive }) => <UpOutlined style={{ fontSize: '16px' }} rotate={isActive ? 360 : 180} />}
        onChange={e => 
          setDefaultActiveKey(e)
        }
      />
    </Container>
  )
}


export default Accordion