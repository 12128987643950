import styled from 'styled-components'

export const Header = styled.h1`
  text-align: left;
  font: normal normal 300 30px/36px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.GRAY};

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    text-align: center;
    align-self: center;
    margin-bottom: 16.5px;
  }
`