import { Rule } from 'antd/lib/form'
import { TMyProfileForm } from '.'

export const initialValues: TMyProfileForm = {
  cpfCnpj: '',
  password: '**********',
  contractedPlan: 'placeholder',
  email: '',
  name: '',
  telephone: ''
}

export const validation = (key: string) => {
  switch (key) {

    case 'email':
      return [{
        type: 'email',
        message: 'Não é um email válido!'
      },
      {
        max: 50,
        message: 'Email não pode possuir mais de 50 caracteres!'
      }] as Rule[]

    case 'telephone':
      return [{
        type: 'string',
        message: 'Não é um telefone válido!'
      },
      () => ({
        validator(_, value) {
          if (value.replace(/\D+/g, '').length < 10)
            return Promise.reject('Telefone precisa ter ao menos 10 dígitos.')
          return Promise.resolve()
        },
      })] as Rule[]


    default:
      [] as Rule[]
  }
}