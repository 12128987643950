import styled from 'styled-components'

export const StyledHeader = styled.div`
  background-image: linear-gradient(to right, ${({ theme }): any => theme.colors.RED}, ${({ theme }): any => theme.colors.ORANGE});
  width: 100%;
  height: 55px;
  padding: 4px 0 4px 18.5%;
  color: white;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;


  > div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      margin-right: 40px;

      :hover{
        cursor: pointer;

        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) { 
    > div{
       div {
          margin-right: 20px;
       }
    }
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.BS_LG}) {
    padding-left: 10px;
    height: 67px;
  }

  @media screen and (min-width: calc(${({ theme }): any => theme.breakPoints.SCREEN_CAP} + 40px)) {
    > div {
      div {
        position: fixed;
        left: calc(${({ theme }): any => theme.breakPoints.SCREEN_CAP} - 25px);
      }
    }
  }
`

export const LogoImg = styled.img`
  width: 80px;
  height: 40px;
  :hover{
    cursor: pointer;
  }

    @media screen and (max-width: ${({ theme }): any => theme.breakPoints.BS_LG}) {
        width: 71px;
        height: 36px;
    }
`

export const MenuContainer = styled.aside`
    position: absolute;
    left: 0%;
    top: 55px;
    width: 100vw;
    height: calc(100vh - 55px);
    background-image: linear-gradient(to right, ${({ theme }): any => theme.colors.RED}, ${({ theme }): any => theme.colors.ORANGE});
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .ant-menu{
        border-inline-end: none;
    }

    .ant-menu-item {
        margin: 0;
        border-radius: 0;
        width: 100%;
        text-align: left;
        font: normal normal normal 20px/24px 'Exo 2';
        letter-spacing: 0px;
        color: ${({ theme }): any => theme.colors.WHITE};
        background-image: linear-gradient(to right, ${({ theme }): any => theme.colors.RED}, ${({ theme }): any => theme.colors.ORANGE});
        padding: 30px 0;
    }

    .ant-menu-item-selected, .ant-menu-item-active {
        background: black linear-gradient(to right, rgb(241, 39, 17, 0.9), rgb(245, 175, 25, 0.9));
        color: ${({ theme }): any => theme.colors.WHITE};
    }

    .ant-menu-item-selected {
        border-left: 7px solid ${({ theme }): any => theme.colors.ORANGE};
    }

    .ant-menu-title-content{
        padding-left: 15px;
    }

    .ant-menu-item-divider{
        margin: 0;
        background-image: linear-gradient(to right, ${({ theme }): any => theme.colors.RED}, ${({ theme }): any => theme.colors.ORANGE});
    }

    @media screen and (min-width: ${({ theme }): any => theme.breakPoints.BS_LG}) {
        display: none;
    }
`

export const MenuButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-right: calc(50% - 80px);

    @media screen and (min-width: ${({ theme }): any => theme.breakPoints.BS_LG}) {
        display: none;
    }
`