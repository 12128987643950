import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const Phone = (props: TProps): JSX.Element => {
  const {
    size = '14',
    color = '#868686'
  } = props

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 24.28 24.394'>
      <g id='phone_ic' transform='translate(0.536 0.651)'>
        <rect id='Retângulo_168' data-name='Retângulo 168' width='23' height='23' fill='none' />
        <path id='Caminho_1282' data-name='Caminho 1282' d='M14.561,16.9s1.879-1.076,2.371-1.334a1.41,1.41,0,0,1,1.308-.136c.467.286,4.392,2.9,4.723,3.128A1.119,1.119,0,0,1,23,20.1c-.453.648-2.54,3.21-3.425,3.183S15,23.169,8.056,16.22,1.028,5.589,1,4.7,3.534,1.729,4.181,1.276a1.133,1.133,0,0,1,1.541.036c.262.362,2.842,4.275,3.127,4.723a1.4,1.4,0,0,1-.136,1.308c-.258.492-1.334,2.371-1.334,2.371A23.047,23.047,0,0,0,10.7,13.573,23.156,23.156,0,0,0,14.561,16.9Z' transform='translate(-0.536 -0.536)' fill='none' stroke={color} strokeWidth='2' />
      </g>
    </svg>


  )
}

export default Phone