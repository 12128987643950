import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  type ChartData,
  type ChartArea,
  TooltipItem
} from 'chart.js/auto'
import { Chart } from 'react-chartjs-2'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { useNavigate } from 'react-router-dom'

import {
  Wrapper,
  ExportChart,
  SpinContainerTable,
  Button
} from './styles'
import { useStyleMediaQuery } from 'hooks/useStyleMediaQuery'

import ChartDataLabels from 'chartjs-plugin-datalabels'

import Select from 'components/Select'
import { downloadPdfChart, formatCurrency } from 'utils/helpers'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend
)

type TRenderLabel = {
  value?: string,
  display: boolean
}

export type TDatasets = {
  label: string,
  data: Array<number | null>
  color?: string
  isGradient: boolean
  gradientColors?: string[],
  stack: string,
  renderLabel: TRenderLabel
}

type TBarChartProps = {
  labels: string[],
  labelsMobile?: string[],
  dataSets: TDatasets[],
  symbol: 'currency' | 'quilowatt',
  title: string,
  disableLegend?: boolean,
  positionLegend?: 'top' | 'bottom',
  type: 'vertical' | 'horizontal',
  disableExportChart?: boolean,
  isLoading: boolean,
  idPosition?: number,
  handleDownloadSpreadsheetData?: (value: string) => void,
  maxValuePositionY: number,
  disableButton: boolean,
  showTooltip?: boolean
}

const BarChart = ({
  labels,
  labelsMobile = labels,
  dataSets,
  symbol,
  title,
  positionLegend = 'bottom',
  type = 'vertical',
  disableLegend = true,
  disableExportChart = false,
  isLoading,
  idPosition = 0,
  handleDownloadSpreadsheetData = () => { },
  maxValuePositionY,
  disableButton = true,
  showTooltip = true
}: TBarChartProps) => {

  const chartRef = useRef<ChartJS>(null)
  const [chartData, setChartData] = useState<ChartData<'bar'>>({
    datasets: [],
  })
  const [typeFile, setTypeFile] = useState<unknown>()
  const [columnHeight, setColumnHeight] = useState<number>(0)

  const { matches: isMobile } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: 990 })
  const navigate = useNavigate()

  const labelImage = useMemo(() => {
    const image = new Image()
    image.src = require('../../assets/logo_solcop_w_y@2x.png')
    image.sizes = '(max-width: 70px) 50px,80px'

    return image
  }, [])

  const imageCop = {
    id: 'img',
    afterDatasetDraw: (chart: { getDatasetMeta?: any; ctx?: any }) => {
      if (chart) {
        const columnHeightOne = chart.getDatasetMeta(2).data[1]?.base - chart.getDatasetMeta(2).data[1]?.y
        const columnHeightTwo = chart.getDatasetMeta(4).data[2]?.base - chart.getDatasetMeta(4).data[2]?.y
        chart.ctx.save()
        setColumnHeight(Math.min(columnHeightOne, columnHeightTwo))
        if (!isMobile && columnHeightOne > 55) {
          // Logo da Copérnico da barra de desconto
          chart.ctx.drawImage(
            labelImage,
            chart.getDatasetMeta(2).data[1]?.x - 20,
            chart.getDatasetMeta(2).data[1]?.y + 25,
            50,
            25
          )
        }

        if (!isMobile && columnHeightTwo > 55) {
          // Logo da Copérnico da barra de desconto Copérnico + CEMIG
          chart.ctx.drawImage(
            labelImage,
            chart.getDatasetMeta(4).data[2]?.x - 20,
            chart.getDatasetMeta(4).data[2]?.y + 25,
            50, 25
          )
        }
        if (chart.ctx)
          chart.ctx.beginPath()
      }
    }
  }

  const LABEL = useMemo(() => ({
    datalabels: {
      color: '#ffffff',
      anchor: 'end' as const,
      clamp: true,
      align: 'start' as const,
      offset: columnHeight < 55 || isMobile ? 0 : 10,
      formatter: (value: string, context: any) => {
        const newValue = formatCurrency(value)
        if (context?.dataset?.renderLabel.display) {
          if (context?.dataset?.renderLabel.value && value && !isMobile) {
            return `${newValue} \n\n    ${context?.dataset?.renderLabel.value}`
          }
          if (value) {
            return `${newValue}`
          }
        }
        return ''
      },
      font: {
        size: 6,
        lineHeight: 'normal',
        family: 'Exo 2'
      }
    }
  }), [columnHeight, isMobile])

  const LEGEND = useMemo(() => ({
    legend: {
      display: disableLegend,
      position: `${positionLegend}` as const,
      reverse: true,
      onClick: () => { },
      labels: {
        color: '#758592',
        padding: 32,
        zIndex: 1,
      }
    }
  }), [disableLegend, positionLegend, isMobile])

  const PLUGINS = useMemo(() => ({
    ...LABEL,
    ...LEGEND,
    tooltip: {
      enabled: showTooltip,
      callbacks: {
        label: (context: TooltipItem<any>): string | string[] | undefined => {
          let label = context.dataset.label || ''
          if (label) {
            label += ': '
          }
          if (!isMobile && context.parsed.y !== null) {
            label += symbol === 'currency' ? formatCurrency(Number(context.parsed.y)) : `${context.parsed.y} kWh`
          }
          if (isMobile && context.parsed.x !== null) {
            label += symbol === 'currency' ? formatCurrency(Number(context.parsed.x)) : `${context.parsed.x} kWh`
          }
          return label
        }
      }

    }
  }), [showTooltip, isMobile, symbol, LABEL, LEGEND])

  const createGradient = (ctx: CanvasRenderingContext2D, area: ChartArea, colors?: string[]) => {
    const colorDefault = colors || ['#ff7700', '#FFC057', '#F12711']
    const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top)
    let range = 0

    colorDefault.forEach(color => {
      gradient.addColorStop(range, color)
      range = range + 0.5
    })

    return gradient
  }

  const setColorLegend = {
    id: 'colorLegend',
    beforeDraw: (chart: any) => {
      const legends = chart.legend.legendItems

      legends.forEach((e: { text: string, fillStyle: any }) => {
        if (e.text === 'SOL Copérnico' || e.text === 'Economia Indique & Ganhe') {
          e.fillStyle = createGradient(chart.ctx, chart.chartArea, ['#ff7700', '#FFC057', '#F12711'])
        }
      })
    }
  }

  const downloadFile = (value: any, name: string) => {
    if (value === 'PDF' && idPosition >= 0) {
      downloadPdfChart(name, idPosition)
    }

    if (value === 'xlsx') {
      handleDownloadSpreadsheetData(name)
    }
    setTypeFile(null)
  }

  const options = useMemo(() => {
    if (type === 'horizontal' && isMobile) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false
        },
        indexAxis: 'y' as const,
        scales: {
          x: {
            stacked: true,
            beginAtZero: true,
            max: maxValuePositionY,
            ticks: {
              callback: (value: any) => {
                if (symbol === 'currency') {
                  return formatCurrency(value)
                }
                return `${value} kWh`
              }
            }
          },
          y: {
            stacked: true,
            beginAtZero: true,
          },
        },
        plugins: PLUGINS,
      }
    }
    return {
      responsive: true,
      title: {
        display: false
      },
      interaction: {
        mode: 'index' as const,
        intersect: false,
      },
      scales: {
        x: {
          display: true,
          grid: {
            drawOnChartArea: false,
          },
        },
        y: {
          stacked: true,
          border: {
            display: false,
          },
          max: maxValuePositionY,
          ticks: {
            callback: (value: any) => {
              if (symbol === 'currency') {
                return formatCurrency(value)
              }
              return `${value} kWh`
            }
          }
        },
      },
      layout: {
        padding: {
          top: 32,
          right: 10,
          bottom: 0,
          left: 20
        }
      },
      plugins: PLUGINS,
    }
  }, [isMobile, symbol, disableLegend, maxValuePositionY])

  const data = useMemo(() => ({
    labels: isMobile ? labelsMobile : labels,
    datasets: dataSets ?? []
  }), [labels, dataSets, labelsMobile, isMobile])

  const updateChartData = useCallback((ctx: CanvasRenderingContext2D, area: ChartArea) => {
    const chartData = {
      ...data,
      datasets: data.datasets.map(dataset => {
        if (dataset.isGradient) {
          return {
            ...dataset,
            backgroundColor: createGradient(ctx, area, dataset?.gradientColors),
          }
        }
        return {
          ...dataset,
          backgroundColor: dataset.color || '#758592',
        }
      }),
    }
    setChartData(chartData)
  }, [data])

  useEffect(() => {
    ChartJS.defaults.font.family = 'Exo 2'

    if (isMobile) {
      ChartJS.defaults.font.size = 5
    } else {
      ChartJS.defaults.font.size = 20
    }

  }, [isMobile])


  useEffect(() => {
    const chart = chartRef.current
    if (chart?.ctx) {
      updateChartData(chart.ctx, chart.chartArea)
    }
  }, [data, chartRef])

  return (
    <Wrapper>
      {isLoading ? (
        <SpinContainerTable>
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 70,
                  color: 'rgb(245, 175, 25)',
                  alignSelf: 'center'
                }}
                width={'100%'}
                spin
              />
            }
          />
        </SpinContainerTable>
      ) : (
        <Chart
          id='chart'
          ref={chartRef}
          options={options}
          data={chartData}
          type='bar'
          plugins={[imageCop, ChartDataLabels, setColorLegend]}
          className={type === 'horizontal' && isMobile ? 'chart-container' : ''}
        />
      )}
      {!disableExportChart && (
        <ExportChart className='downloadFile'>
          <Select
            options={[{ label: 'PDF', value: 'PDF' }, { label: 'Excel(.xlsx)', value: 'xlsx' }]}
            onChange={(value: unknown) => {
              downloadFile(value, title)
            }}
            value={typeFile}
            title='Exportar como:'
          />
        </ExportChart>
      )}
      {!disableButton && (
        <Button onClick={() => navigate('/invoices')}>Histórico de Faturas</Button>
      )}
    </Wrapper>
  )
}

export default BarChart