import styled from 'styled-components'
import {
  Button as AntButton,
} from 'antd'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .chart-container {
    position: relative;
    margin: auto;
    height: 100vh!important;
    /* width: 80vw!important; */
  }
`

export const ExportChart = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
`

export const SpinContainerTable = styled.div`
  width: 100%;
  align-self: center;
  padding: 0 45%;
`

export const Button = styled(AntButton)`
  width: 100%;
  height: 37px;
  color: ${({ theme }): any => theme.colors.GRAY};
  text-align: center;
  font: normal normal normal 14px/11px 'Exo 2';
  letter-spacing: 0px;
  background: ${({ theme }): any => theme.colors.LIGHT_GRAY} 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  margin-right: 10px;

  :hover {
    background: ${({ theme }): any => theme.colors.GRAY} 0% 0% no-repeat padding-box;
    color: ${({ theme }): any => theme.colors.WHITE};
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    width: 100%;
    margin-bottom: 10px;
  }
`