import styled from 'styled-components'

export const Container = styled.div`
  width: calc(100vw - ${({ theme }): any => theme.breakPoints.MENU_WIDTH});
  height: 100%;
  background-color: ${({ theme }): any => theme.colors.LIGHT_GRAY};
  overflow-y: auto;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.BS_LG}) {
    width: 100vw;
  }
`