import { Rule } from 'antd/lib/form'
import { TLogin } from '.'


export const initialValues: TLogin = {
  cpfCnpj: '',
  password: ''
}

export const validation = (key: string) => {
  switch (key) {

    case 'cpfCnpj':
      return [{
        required: true,
        message: 'CPF ou CNPJ é necessário.'
      },
      () => ({
        validator(_, value) {
          if (value.replace(/[^0-9]/g, '').length < 11)
            return Promise.reject('Insira um CPF ou CNPJ válido.')
          if (value.replace(/[^0-9]/g, '').length === 11)
            return Promise.resolve()
          if (value.replace(/[^0-9]/g, '').length < 14)
            return Promise.reject(new Error('Insira um CNPJ válido.'))
          if (value.replace(/[^0-9]/g, '').length === 14)
            return Promise.resolve()
        },
      }),
      {
        max: 30,
        message: 'Insira um CPF ou CNPJ válido.'
      }] as Rule[]

    case 'password':

      return [{
        required: true,
        message: 'Senha é necessária.'
      },
      {
        max: 50,
        message: 'Senha não pode conter mais de 50 caracteres.'
      }] as Rule[]

    default:
      [] as Rule[]
  }
}