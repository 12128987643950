import React, { useEffect, useState } from 'react'
import useInvoices from 'repositories/useInvoices'
import {
  Select,
  Title,
  Container
} from './styles'

type TYearFilterProps = {
  onChange: (value: unknown)=>void,
  title?:string
  styleTitle?:object
  value?:number
}

const YearFilter = (props:TYearFilterProps) => {
  const { onChange, title = 'Selecionar Período:', styleTitle = {}, value } = props

  const [options, setOptions] = useState([{ label: 'Últimos 12 meses', value: null }])

  const invoices = useInvoices()

  const getYearFilter = async () => {
    const response = await invoices.getFilterYear()
    if(!response) return
    const formattedYears = response.content.years.map(( year:number ) => {
      return{
        label: year.toString(),
        value: year
      }
    })
    const newOptions = [...options, ...formattedYears]
    setOptions(newOptions)
  }

  useEffect(() => {
    getYearFilter()
  }, [])

  return(
    <Container>
      <Title style={styleTitle}>{title}</Title>
      <Select
        options={options}
        bordered={false}
        defaultValue={null}
        size='large'
        onChange={onChange}
        value={value}
      />
    </Container>
  )
}

export default YearFilter
