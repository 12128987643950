import { useState } from 'react'
import useRest from 'hooks/useRest'
import { storageKey } from 'services/axios'
import { addConsumerUnitParam, getSelectedConsumerUnit } from 'utils/helpers'

export type TGetReferAndWinListResponse = {
  referlist: TReferAndWinListItem[]
}

export type TReferAndWinListItem = {
  name: string
  indicationDate: string
  indicationStatus: string
}

export type TReferAndWin = {
  cardData: {
    referenceMonth: string,
    year: number,
    month: number,
    economyByReferral: number,
    totalEconomyByReferral: number
  }
}

export type TPostReferParams = {
  name?: string
  companyName?: string
  personType: string
  responsibleName?: string
  phone?: string
  companyPhone?: string
  uc?: string
}

const useReferAndWin = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getReferAndWin = async (triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/dashboard/referAndWinEconomyCard'
    const params = addConsumerUnitParam({}, storageKey)

    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    return response.content as TReferAndWin
  }

  const getReferAndWinList = async (triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/referAndWin'
    const params = addConsumerUnitParam({}, storageKey)

    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    return response.content as TGetReferAndWinListResponse
  }

  const postReference = async (data: TPostReferParams, triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)

    const url = `/referAndWin?uc=${getSelectedConsumerUnit(storageKey)}`

    const response = await rest.post(url, data)
    if (triggerLoading) setIsLoading(false)
    return response as any
  }

  return {
    isLoading,
    getReferAndWin,
    getReferAndWinList,
    postReference
  }
}

export default useReferAndWin