import React, { useMemo, useState, useEffect } from 'react'
import { Menu as AntdMenu } from 'antd'
import { useNavigate, useLocation, NavLink } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import { storageKey } from 'services/axios'
import useLogin from 'repositories/useLogin'
import useConsumerUnits, { TConsumerUnit, TAddress } from 'repositories/useConsumerUnits'
import Footer from 'components/Footer'


import {
  User,
  Billing,
  Energy,
  Exit,
  Help,
  House,
  ReferNWin,
  Exchange
} from 'components/Icons'

import ConfirmationModal from 'components/ConfirmationModal'
import SelectConsumerUnitsModal from 'components/SelectConsumerUnitsModal'

import {
  Links,
  ContainerProfile,
  ProfileData,
  ProfilePicture,
  ChangeUC,
  ScrollableContainer
} from './styles'
import SelectClientsModal from 'components/SelectClientsModal'

type TItems = {
  key: string
  path: string
}

enum Uri {
  HOME = '/',
  HELP = '/help',
  INVOICES = '/invoices',
  CONSUMPTION = '/consumption',
  MYPROFILE = '/my-profile',
  REFERNWIN = '/refer-and-win',
}


const items: TItems[] = [
  {
    key: '1',
    path: Uri.HOME,
  },
  {
    key: '2',
    path: Uri.MYPROFILE,
  },
  {
    key: '3',
    path: Uri.HELP,
  },
  {
    key: '4',
    path: Uri.REFERNWIN,
  },
  {
    key: '5',
    path: Uri.CONSUMPTION,
  },
  {
    key: '6',
    path: Uri.INVOICES,
  }
]

const Menu = () => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isSelectConsumerUnitModalOpen, setIsSelectConsumerUnitModalOpen] = useState(false)
  const [isSelectClientsModalOpen, setIsSelectClientsModalOpen] = useState(false)
  const [consumerUnitList, setConsumerUnitList] = useState<TConsumerUnit[]>([])
  const [view, setView] = useState<String>('')
  const [address, setAddress] = useState<TAddress>()
  const [imageUrl, setImageUrl] = useState<string>()
  const auth = useAuth()
  const loginRepository = useLogin()

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const mainPath = `/${pathname.split('/')[1]}`

  const consumerUnitsRepository = useConsumerUnits()

  const isAdmin = useMemo(() => {
    const formattedUser = JSON.parse(String(localStorage.getItem(storageKey)))
    return formattedUser.isAdmin
  }, [])

  useEffect(() => {
    const getUCs = async () => {
      const response = await consumerUnitsRepository.getConsumerUnits()
      if(!response) return
      setConsumerUnitList(response.content.consumerUnitList)
    }
    if(!isAdmin) getUCs()
  }, [])

  const selectedKey = useMemo(() => {
    const findedItem = items.find(item => item.path === mainPath)
    if (!findedItem) return ''

    return findedItem.key
  }, [mainPath])

  const getIconColor = (uri:string) => {
    if(uri === pathname) return '#F5AF19'
    return 'white'
  }

  const onClickGoToPage = (path: string) => (): void => navigate(path)

  const logout = () => {
    auth.removeUserData()
    loginRepository.logout()
  }

  useEffect(() => {
    const formattedUser = JSON.parse(String(localStorage.getItem(storageKey)))
    setImageUrl(formattedUser.profilePictureUrl)
  }, [])

  useEffect(() => {
    const handleStorage = () => {
      const formattedUser = JSON.parse(String(localStorage.getItem(storageKey)))
      setImageUrl(formattedUser.profilePictureUrl)
    }

    window.addEventListener('storage', handleStorage)
  }, [])

  useEffect(() => {
    const handleStorage = () => {
      const formattedUser = JSON.parse(String(localStorage.getItem(storageKey)))
      setView(formattedUser.selectedUC)
      setAddress(formattedUser.selectedAddress)
    }

    window.addEventListener('selectUC', handleStorage)
  }, [])

  useEffect(() => {
    if(!consumerUnitList) return
    if(consumerUnitList.length === 0) return
    if(consumerUnitList.length === 1) {
      const userData = JSON.parse(String(localStorage.getItem(storageKey)))
      const newUserData = {
        ...userData,
        selectedUC: consumerUnitList[0].code,
        selectedAddress: consumerUnitList[0].address
      }
      localStorage.setItem(storageKey, JSON.stringify(newUserData))
      setView(consumerUnitList[0].code)
      setAddress(consumerUnitList[0].address)
    }

    else {
      const formattedUser = JSON.parse(String(localStorage.getItem(storageKey)))
      if(!formattedUser.selectedUC) {
        setIsSelectConsumerUnitModalOpen(true)
        return
      }
      setView(formattedUser.selectedUC)
      setAddress(formattedUser.selectedAddress)
    }
  }, [consumerUnitList])

  useEffect(() => {
    const formattedUser = JSON.parse(String(localStorage.getItem(storageKey)))
    if(isAdmin && !formattedUser.selectedUC) {
      setIsSelectClientsModalOpen(true)
      return
    }
    setView(formattedUser.selectedUC)
    setAddress(formattedUser.selectedAddress)
  }, [])

  const getViewName = (name:String) => {
    if(!name) return 'SELECIONE UMA UC'
    if(name === 'VISÃO GERAL') return name
    return `UC: ${name}`
  }

  return (
    <>
      <ContainerProfile>
        <ProfilePicture>
          {imageUrl ? <img src={imageUrl} alt='' /> :
            <User color='white' size='26px' />}
        </ProfilePicture>
        <ProfileData>
          <h4>{getViewName(view)}</h4>
          {address && (
            <>
              <span>{address.street}, {address.number}</span>
              <span>{address.neighborhood}, {address.city}, {address.state ? address.state.uf : ''}</span>
            </>
          ) }
        </ProfileData>
        {!isAdmin && consumerUnitList !== null && consumerUnitList.length > 1 &&
          <ChangeUC
            onClick={() => setIsSelectConsumerUnitModalOpen(true)}
          >
            <Exchange size='55px' />
          </ChangeUC>
        }
        {isAdmin &&
          <ChangeUC
            onClick={() => setIsSelectClientsModalOpen(true)}
          >
            <Exchange size='55px' />
          </ChangeUC>
        }
      </ContainerProfile>
      <ScrollableContainer>
        <AntdMenu
          style={{ border: 'none' }}
          defaultSelectedKeys={[selectedKey]}
          activeKey={selectedKey}
          multiple={false}
          mode='inline'
          items={
            [
              {
                key: '1',
                icon: <House color={getIconColor(Uri.HOME)} size='24px' />,
                onClick: onClickGoToPage(Uri.HOME),
                label: 'Início',
                title: 'Início'
              },
              {
                key: '2',
                icon: <User color={getIconColor(Uri.MYPROFILE)} size='24px' />,
                onClick: onClickGoToPage(Uri.MYPROFILE),
                label: 'Seus Dados',
                title: 'Seus Dados'
              },
              {
                key: '3',
                icon: <Help color={getIconColor(Uri.HELP)} size='24px' />,
                onClick: onClickGoToPage(Uri.HELP),
                label: 'Ajuda',
                title: 'Ajuda'
              },
              {
                type: 'divider'
              },
              {
                key: '4',
                icon: <ReferNWin color={getIconColor(Uri.REFERNWIN)} size='24px' />,
                onClick: onClickGoToPage(Uri.REFERNWIN),
                label: 'Indique & Ganhe',
                title: 'Indique & Ganhe'
              },
              {
                key: '5',
                icon: <Energy color={getIconColor(Uri.CONSUMPTION)} size='24px' />,
                onClick: onClickGoToPage(Uri.CONSUMPTION),
                label: 'Consumo e economia',
                title: 'Consumo e economia'
              },
              {
                key: '6',
                icon: <Billing color={getIconColor(Uri.INVOICES)} size='24px' />,
                onClick: onClickGoToPage(Uri.INVOICES),
                label: 'Faturas',
                title: 'Faturas'
              },
              {
                type: 'divider'
              },
              {
                key: '7',
                icon: <Exit color='white' size='24px' />,
                onClick: () => setIsConfirmationModalOpen(true),
                label: 'Sair',
                title: 'Sair'
              },
              {
                type: 'divider'
              },
            ]
          }
        />
        <Links>
          <NavLink to='/terms'>Política de Privacidade</NavLink>
          <NavLink to='/terms'>Termos de uso</NavLink>
          <NavLink to='/help'>Central de ajuda</NavLink>
        </Links>
        <Footer color='white' />
      </ScrollableContainer>
      <ConfirmationModal
        confirmationText='Tem certeza que deseja sair?'
        isOpen={isConfirmationModalOpen}
        okText='Encerrar sessão'
        onCancel={() => setIsConfirmationModalOpen(false)}
        onFinishOperation={logout}
      />
      <SelectConsumerUnitsModal
        isOpen={isSelectConsumerUnitModalOpen}
        consumerUnitList={consumerUnitList}
        onFinishOperation={() => setIsSelectConsumerUnitModalOpen(false)}
      />
      <SelectClientsModal
        isOpen={isSelectClientsModalOpen}
        onFinishOperation={() => setIsSelectClientsModalOpen(false)}
      />
    </>
  )
}

export default Menu