import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const Lock = (props: TProps): JSX.Element => {
  const {
    size = '14',
    color = '#868686'
  } = props

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 19.604 24.506'>
      <g id='password_icon' transform='translate(-4 -1)'>
        <path id='Caminho_217' data-name='Caminho 217' d='M21.154,27.887H6.451A2.451,2.451,0,0,1,4,25.436V16.451A2.451,2.451,0,0,1,6.451,14h14.7A2.451,2.451,0,0,1,23.6,16.451v8.985a2.451,2.451,0,0,1-2.451,2.451ZM6.451,15.634a.817.817,0,0,0-.817.817v8.985a.817.817,0,0,0,.817.817h14.7a.817.817,0,0,0,.817-.817V16.451a.817.817,0,0,0-.817-.817Z' transform='translate(0 -2.381)' fill={color} />
        <path id='Caminho_218' data-name='Caminho 218' d='M20.887,13.253H7.817A.817.817,0,0,1,7,12.436v-4.9A6.535,6.535,0,0,1,13.535,1h1.634A6.535,6.535,0,0,1,21.7,7.535v4.9A.817.817,0,0,1,20.887,13.253ZM8.634,11.619H20.07V7.535a4.9,4.9,0,0,0-4.9-4.9H13.535a4.9,4.9,0,0,0-4.9,4.9Z' transform='translate(-0.549 0)' fill={color} />
        <path id='Caminho_219' data-name='Caminho 219' d='M15.634,22.267a1.634,1.634,0,1,1,1.634-1.634A1.634,1.634,0,0,1,15.634,22.267ZM15.634,20.634Z' transform='translate(-1.831 -3.297)' fill={color} />
        <rect id='Retângulo_48' data-name='Retângulo 48' width='2' height='3' transform='translate(13 18)' fill={color} />
      </g>
    </svg>
  )
}

export default Lock