import axios from 'services/axios'
import { AxiosError, AxiosResponse } from 'axios'

import useErrorHandler from 'hooks/useErrorHandler'

const useRest = () => {
  const { handleApiErrorDesambiguation } = useErrorHandler()

  const get = async (
    url: string,
    params: Object = {},
    customHandleError?: (error: AxiosError) => any
  ) => {
    try {
      const _params = {
        params
      }
      const response = await axios.get(url, _params)
      return response.data
    } catch (error) {
      const _error = error as AxiosError

      if (customHandleError) {
        customHandleError(_error)
        return
      }

      const { status, detailCode } = _error.response?.data || {}

      if (status) {
        handleApiErrorDesambiguation(status)
        return
      }

      if (detailCode) handleApiErrorDesambiguation(detailCode)

    }
  }

  const post = async (
    url: string,
    body: Object = {},
    customHandleError?: (error: AxiosError) => any,
    removeAuthorization?: boolean
  ) => {
    try {
      const promise = removeAuthorization ? axios.post(url, body, { headers: { 'Authorization': '' } }) : axios.post(url, body)
      const response = await promise
      return response.data
    } catch (error) {
      const _error = error as AxiosError

      if (customHandleError && url !== 'auth/logout') {
        customHandleError(_error)
        return
      }

      const errorCode = Number(_error.response?.data.detailCode)
      handleApiErrorDesambiguation(errorCode)
    }
  }

  const put = async (
    url: string,
    body: Object = {},
    customHandleError?: (error: AxiosError) => any
  ) => {
    try {
      const response = await axios.put(url, body)
      return response.data
    } catch (error) {
      const _error = error as AxiosError

      if (customHandleError) {
        customHandleError(_error)
        return
      }

      const errorCode = Number(_error.response?.data.detailCode)
      handleApiErrorDesambiguation(errorCode)
    }
  }

  const patch = async (
    url: string,
    body: Object = {},
    customHandleError?: (error: AxiosError) => any
  ) => {
    try {
      const response = await axios.patch(url, body)
      return response.data
    } catch (error) {
      const _error = error as AxiosError

      if (customHandleError) {
        customHandleError(_error)
        return
      }

      const errorCode = Number(_error.response?.data.detailCode)
      handleApiErrorDesambiguation(errorCode)
    }
  }

  const postWithHeader = async (
    url: string,
    body: Object = {},
    header: Object = {},
    customHandleError?: (error: AxiosError) => any,
  ): Promise<AxiosResponse<any> | undefined> => {
    try {
      const response = await axios.post(url, body, header)

      return response
    } catch (error) {
      const _error = error as AxiosError
      if (customHandleError) {
        customHandleError(_error)
        return
      }

      const errorCode = Number(_error.response?.data.detailCode)
      handleApiErrorDesambiguation(errorCode)
    }
  }

  const putWithHeader = async (
    url: string,
    body: Object = {},
    header: Object = {},
    customHandleError?: (error: AxiosError) => any,
  ): Promise<AxiosResponse<any> | undefined> => {
    try {
      const response = await axios.put(url, body, header)

      return response
    } catch (error) {
      const _error = error as AxiosError
      if (customHandleError) {
        customHandleError(_error)
        return
      }

      const errorCode = Number(_error.response?.data.detailCode)
      handleApiErrorDesambiguation(errorCode)
    }
  }

  const patchWithHeader = async (
    url: string,
    body: Object = {},
    header: Object = {},
    customHandleError?: (error: AxiosError) => any,
  ): Promise<AxiosResponse<any> | undefined> => {
    try {
      const response = await axios.patch(url, body, header)

      return response
    } catch (error) {
      const _error = error as AxiosError
      if (customHandleError) {
        customHandleError(_error)
        return
      }

      const errorCode = Number(_error.response?.data.detailCode)
      handleApiErrorDesambiguation(errorCode)
    }
  }


  return {
    get,
    post,
    put,
    patch,
    postWithHeader,
    putWithHeader,
    patchWithHeader
  }
}

export default useRest