import styled from 'styled-components'

export const ContainerProfile = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 105px;
`

export const ProfilePicture = styled.div`
    width: 55px;
    height: 55px;
    background-image: linear-gradient(to right, ${({ theme }): any => theme.colors.RED}, ${({ theme }): any => theme.colors.ORANGE});
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;

    img {
        position: relative;
        width: 55px;
        height: 55px;
        border-radius: 100px;
    } 
`

export const ProfileData = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h4 {
        text-align: left;
        font: normal normal 900 16px/19px 'Exo 2';
        letter-spacing: 0px;
        color: ${({ theme }): any => theme.colors.GRAY};
        opacity: 1;
    }

    span {
        text-align: left;
        font: normal normal normal 12px/14px 'Exo 2';
        letter-spacing: 0px;
        color: ${({ theme }): any => theme.colors.GRAY};
        opacity: 1;
    } 
`

export const ScrollableContainer = styled.div`
    @media screen and (max-height: 700px) {
        overflow-y: auto;
    }
`

export const ChangeUC = styled.div`
    cursor: pointer;
`

export const Links = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 2;
    padding: 15px;

    a, a:visited, a:hover, a:active {
        text-align: left;
        font: normal normal normal 14px/17px 'Exo 2';
        letter-spacing: 0px;
        color: ${({ theme }): any => theme.colors.WHITE};
        opacity: 1;
        text-decoration: none;
        padding-top: 5px
    }

    a:hover{
        text-decoration: underline;
    }
`