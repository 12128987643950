/* eslint-disable no-useless-escape */
import React, {
  useState,
  useEffect,
  useMemo
} from 'react'
import InputMask from 'react-input-mask'
import { Upload } from 'antd'
import type { RcFile, UploadFile, UploadChangeParam } from 'antd/es/upload/interface'
import { useToasts, Options } from 'react-toast-notifications'
import { initialValues, validation } from './validations'

import { storageKey } from 'services/axios'
import { formatCpfCnpj } from 'utils/helpers'
import useMyProfile from 'repositories/useYourData'

import InnerContainer from 'components/InnerPageContainer'
import ChangePasswordModal from 'components/ChangePasswordModal'

import { User, Camera } from 'components/Icons'

import {
  HeaderContainer,
  Name,
  PlanContainer,
  Picture,
  PictureNameContainer,
  MainContainer,
  Label,
  Form,
  FormItem,
  Input,
  FormRow,
  Button,
  EllipsisBlock,
  CameraBox
} from './styles'
import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'

export type TMyProfileForm = {
  name: string
  email: string
  password: string
  cpfCnpj: string
  telephone: string
  contractedPlan: string
}


const toastSuccessConfig = {
  appearance: 'success',
  autoDismiss: true,
} as Options

const toastErrorConfig = {
  appearance: 'error',
  autoDismiss: true,
} as Options

const MyProfile = () => {
  const [form] = Form.useForm<TMyProfileForm>()
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false)
  const [name, setName] = useState<string>('')
  const [cpfCnpj, setcpfCnpj] = useState<string>('')
  const [email, setEmail] = useState('')
  const [contractedPlan, setContractedPlan] = useState('')
  const [fileList, setFileList] = useState<UploadFile[]>()
  const [imageUrl, setImageUrl] = useState<string>()

  const myProfileRepository = useMyProfile()
  const { addToast } = useToasts()

  const isAdmin = useMemo(() => {
    const formattedUser = JSON.parse(String(localStorage.getItem(storageKey)))
    return formattedUser.isAdmin
  }, [])

  const getMask = (value: string | undefined) => {
    if(!value) return '999.999.999-99'
    const cleanedValue = value.replace(/\D/g, '')

    return cleanedValue.length <= 11 ? '999.999.999-99' : '99.999.999/9999-99'
  }

  const getProfileData = async () => {
    const response = await myProfileRepository.getMyProfile()
    if(!response) return
    const formattedTelephone = response.content.telephone ?? '00000000000'
    form.setFieldValue('telephone', formattedTelephone)
    form.setFieldValue('email', response.content.email)
    form.setFieldValue('name', response.content.name)
    form.setFieldValue('cpfCnpj', response.content.cpfCnpj)
    form.validateFields()
    setName(response.content.name)
    setcpfCnpj(response.content.cpfCnpj)
    setEmail(response.content.email)
    setContractedPlan(response.content.plan)
    if(!response.content.profilePictureUrl) return
    setImageUrl(response.content.profilePictureUrl)
  }

  useEffect(() => {
    getProfileData()
  }, [])

  const patchPhone = async () => {
    const error = form.getFieldError('telephone')
    if(error.length > 0) return

    const newTelephone = form.getFieldValue('telephone').replace(/\D+/g, '')
    const response = await myProfileRepository.patchMyProfile({ newTelephone })
    if(!response) return
    addToast('Telefone alterado com sucesso!', toastSuccessConfig)
    getProfileData()
  }

  const patchEmail = async () => {
    const newEmail = form.getFieldValue('email')
    const error = form.getFieldError('email')
    if(error.length > 0) return

    const response = await myProfileRepository.patchMyProfile({ newEmail })
    if(!response) return
    addToast('E-mail alterado com sucesso!', toastSuccessConfig)
    getProfileData()
  }

  const beforeUpload = (file:UploadFile) => {
    const isIMG = (file.type === 'image/png' || file.type === 'image/jpeg')
    if(!isIMG) addToast('A imagem deve possuir formato .jpeg ou .png', toastErrorConfig)

    const isBiggerThan5MB = (Number(file?.size) / 1024 / 1024 < 5)
    if(!isBiggerThan5MB) addToast('A imagem não pode ser maior que 5MB', toastErrorConfig)

    return (isIMG && isBiggerThan5MB) || Upload.LIST_IGNORE
  }

  const onChangeUpload = (info:UploadChangeParam<UploadFile<any>>) => {
    const newFileList = info.fileList.slice(-1)
    setFileList(newFileList)
  }

  const uploadIMG = async () => {
    if(!fileList) return

    const response = await myProfileRepository.newProfilePicture(fileList[0]. originFileObj as RcFile)
    if(!response) return
    const newImg = response.data.content.profilePictureUrl
    const userData = JSON.parse(String(localStorage.getItem(storageKey)))
    const newUserData = {
      ...userData,
      profilePictureUrl: newImg
    }
    localStorage.setItem(storageKey, JSON.stringify(newUserData))
    window.dispatchEvent(new Event('storage'))
    setImageUrl(newImg)
    addToast('Imagem de perfil alterada com sucesso!', toastSuccessConfig)
  }


  return (
    <InnerContainer>
      <PageWrapper>
        <PageTitle>Seus dados</PageTitle>
        <HeaderContainer>
          <PictureNameContainer>
            <Picture>
              {imageUrl ? <img src={imageUrl} alt='' /> :
                <User color='white' size='60px' />}
            </Picture>
            <CameraBox>
              <Upload
                beforeUpload={beforeUpload}
                onChange={onChangeUpload}
                showUploadList={false}
                customRequest={uploadIMG}
                fileList={fileList}
                disabled={isAdmin}
              >
                <Camera size='15px' />
              </Upload>
            </CameraBox>
            <Name>
              <EllipsisBlock><span className='profileName'>{name}</span></EllipsisBlock>
              <EllipsisBlock><span>{email}</span></EllipsisBlock>
              <EllipsisBlock><span>{formatCpfCnpj(cpfCnpj)}</span></EllipsisBlock>
            </Name>
          </PictureNameContainer>
          <PlanContainer>
            <span>Plano contratado:</span>
            <h3>{contractedPlan}</h3>
          </PlanContainer>
        </HeaderContainer>
        <MainContainer>
          <Form
            layout='vertical'
            onFinish={() => alert('foi')}
            form={form}
            autoComplete='off'
            size='middle'
            requiredMark='optional'
            initialValues={initialValues}
          >
            <FormRow>
              <FormItem
                label={<Label>Nome completo</Label>}
                name='name'
                id='input-name'
                required
              >
                <Input
                  size='middle'
                  disabled
                />
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem
                label={<Label>Email</Label>}
                name='email'
                id='input-email'
                rules={validation('email')}
                required
              >
                <Input
                  size='middle'
                  disabled={isAdmin}
                />
              </FormItem>
              <Button
                onClick={patchEmail}
                loading={myProfileRepository.isLoading}
                disabled={isAdmin}
              >
                Alterar
              </Button>
            </FormRow>
            <FormRow>
              <FormItem
                label={<Label>Senha</Label>}
                name='password'
                id='input-password'
                required
              >
                <Input
                  size='middle'
                  type='password'
                  disabled
                />
              </FormItem>
              <Button
                onClick={() => setIsChangePasswordModalOpen(true)}
                disabled={isAdmin}
              >
                Alterar
              </Button>
            </FormRow>
            <FormRow>
              <FormItem
                label={<Label>CPF / CNPJ</Label>}
                name='cpfCnpj'
                id='input-cpf'
                required
              >
                <InputMask disabled mask={getMask(cpfCnpj)} maskChar='_' alwaysShowMask={false}>
                  {() =>
                    <Input
                      size='large'
                      placeholder='000.000.000-00'
                      maxLength={30}
                      disabled
                    />
                  }
                </InputMask>
              </FormItem>
            </FormRow>
            <FormRow>
              <FormItem
                label={<Label>Telefone</Label>}
                name='telephone'
                id='input-phone'
                rules={validation('telephone')}
                required
              >
                <InputMask
                  mask={'(99) 9 9999-9999'}
                  maskChar='_'
                  alwaysShowMask={false}
                  disabled={isAdmin}
                >
                  {() =>
                    <Input
                      size='middle'
                      disabled={isAdmin}
                    />
                  }
                </InputMask>
              </FormItem>
              <Button
                onClick={patchPhone}
                loading={myProfileRepository.isLoading}
                disabled={isAdmin}
              >
                Alterar
              </Button>
            </FormRow>
          </Form>
        </MainContainer>
      </PageWrapper>
      <ChangePasswordModal
        passwordType='changePassword'
        isOpen={isChangePasswordModalOpen}
        onCancel={() => setIsChangePasswordModalOpen(false)}
        onFinishOperation={() => setIsChangePasswordModalOpen(false)}
      />
    </InnerContainer>
  )
}

export default MyProfile