import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  StyledHeader,
  LogoImg
} from './styles'
import SolCopWhiteLogo from '../../assets/whiteLogoSolCop.svg'

export type THeaderProps = {
  fixed?: boolean
}

const Header = (props:THeaderProps) => {
  const { fixed = true } = props

  const position = fixed ? 'fixed' : 'relative'

  const navigate = useNavigate()

  return (
    <StyledHeader style={{ position }}>
      <LogoImg
        src={SolCopWhiteLogo}
        alt='Logo Sol Cop Branco'
        onClick={(): void => navigate('/')}
      />
    </StyledHeader>
  )
}

export default Header