import styled from 'styled-components'
import {
  Input as AntInput,
  Form as AntForm,
  Button as AntButton
} from 'antd'

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: ${({ theme }): any => theme.colors.WHITE};
  margin-bottom: 30px;
  max-width: ${({ theme }): any => theme.breakPoints.CONTENT_MAX_WIDTH};
  height: 155px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    width: 100%;
    height: 185px;
    margin-bottom: 5px;
  }
`

export const PictureNameContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    width: 100%;
  }
`

export const Picture = styled.div`
  width: 120px;
  height: 120px;
  background-image: linear-gradient(to right, ${({ theme }): any => theme.colors.RED}, ${({ theme }): any => theme.colors.ORANGE});
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin: 15px 0 15px 15px;

  img {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 100px;
  } 
`

export const CameraBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 28px;
  background-color: ${({ theme }): any => theme.colors.LIGHT_GRAY};
  border-radius: 5px;
  opacity: 1;
  top: 40px;
  right: 30px;
  padding-top: 3px;
  
  span {
    cursor: pointer;
    text-align: center;
    @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
      width: 28px;
    }
  }
`

export const Name = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  span{
    text-align: left;
    font: normal normal normal 17px/20px 'Exo 2';
    letter-spacing: 0px;
    color: ${({ theme }): any => theme.colors.GRAY};
    opacity: 1;
  } 

  .profileName{
    text-align: left;
    font: normal normal normal 22px/26px 'Exo 2';
    letter-spacing: 0px;
    color: ${({ theme }): any => theme.colors.GRAY};
    opacity: 1;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    width: 55%;
    padding-right: 10px;
  }
`

export const EllipsisBlock = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  overflow: hidden;
`


export const PlanContainer = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 25px;

  h3{
    text-align: right;
    font: normal normal 900 17px/20px 'Exo 2';
    letter-spacing: 0px;
    color: ${({ theme }): any => theme.colors.GRAY};
    opacity: 1;
  }

  span{
    text-align: right;
    font: normal normal normal 17px/20px 'Exo 2';
    letter-spacing: 0px;
    color: ${({ theme }): any => theme.colors.GRAY};
    opacity: 1;
  } 

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    display: none;
  }
`
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: ${({ theme }): any => theme.colors.WHITE};
  margin-top: 30px;
  padding: 20px 5px;
  max-width: ${({ theme }): any => theme.breakPoints.CONTENT_MAX_WIDTH};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    width: 100%;
    margin-top: 20px;
  }
`

export const Label = styled.h3`
  font: normal normal normal 22px/26px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.GRAY};
  margin: 0;
`

export const Form = styled(AntForm)`
  width: 100%;
  padding: 15px;
`

export const FormItem = styled(AntForm.Item)`
  width: 80%;
  
  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    width: 100%;
  }
`

export const Input = styled(AntInput)`
  width: 50%;
  border: none;
  font: normal normal normal 19px/23px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.GRAY};
  margin: 0;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    width: 100%;
  }
`

export const Button = styled(AntButton)`
  width: 130px;
  height: 37px;
  color: ${({ theme }): any => theme.colors.WHITE};
  text-align: center;
  font: normal normal normal 15px/19px 'Exo 2';
  letter-spacing: 0px;
  background: ${({ theme }): any => theme.colors.ORANGE} 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    margin-bottom: 10px;
  }
`

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  margin-bottom: 14px;
  border-bottom: 1px solid ${({ theme }): any => theme.colors.LIGHT_GRAY};

  .ant-input-disabled{
    background-color: ${({ theme }): any => theme.colors.WHITE};
  }

  .ant-form-item-explain-error {
    margin-bottom: 15px;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding-bottom: 0;

    div {
      margin-bottom: 2px;
    }

    input {
      padding-left: 0;
    }
  }
`