import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const Billing = (props: TProps): JSX.Element => {
  const {
    size = '30',
    color = '#868686'
  } = props


  return (
    <svg id='pay_ic' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width={size} height={size} viewBox='0 0 25 25'>
      <defs>
        <clipPath id='clipPath'>
          <rect id='Retângulo_134' data-name='Retângulo 134' width='25' height='25' fill={color} />
        </clipPath>
      </defs>
      <g id='Grupo_176' data-name='Grupo 176' clipPath='url(#clipPath)'>
        <path id='Caminho_1267' data-name='Caminho 1267' d='M23.252,8.858H19.931V1.107A1.107,1.107,0,0,0,18.159.221L15.867,1.938c-3.455-2.58-2.469-2.58-5.9,0-3.444-2.58-2.469-2.58-5.913,0L1.772.221A1.107,1.107,0,0,0,0,1.107V21.038A3.322,3.322,0,0,0,3.322,24.36H21.038a3.322,3.322,0,0,0,3.322-3.322V9.965a1.108,1.108,0,0,0-1.107-1.107M3.322,22.145a1.108,1.108,0,0,1-1.107-1.107V3.322c2.215,1.628,1.561,1.594,4.794-.83,3.433,2.58,2.458,2.58,5.9,0,3.322,2.48,2.68,2.425,4.806.83,0,18.547-.089,18.048.188,18.823Zm18.823-1.107a1.107,1.107,0,1,1-2.215,0V11.073h2.215Z' fill={color} />
        <path id='Caminho_1268' data-name='Caminho 1268' d='M8.768,10.319h2.768a1.107,1.107,0,0,0,0-2.215H10.429a1.108,1.108,0,0,0-2.215.055,2.768,2.768,0,0,0,.554,5.481H9.876a.554.554,0,0,1,0,1.107H7.108a1.107,1.107,0,0,0,0,2.215H8.215a1.107,1.107,0,0,0,2.215,0,2.782,2.782,0,0,0-.554-5.536H8.768a.554.554,0,0,1,0-1.107' transform='translate(0.643 0.753)' fill={color} />
      </g>
    </svg>
  )
}

export default Billing