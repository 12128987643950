import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const User = (props: TProps): JSX.Element => {
  const {
    size = '14',
    color = '#868686'
  } = props

  return (
    <svg id='profile_icon' xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 19.714 23'>
      <path id='Caminho_215' data-name='Caminho 215' d='M14.75,13.5A5.75,5.75,0,1,0,9,7.75a5.75,5.75,0,0,0,5.75,5.75Zm0-9.857A4.107,4.107,0,1,1,10.643,7.75,4.107,4.107,0,0,1,14.75,3.643Z' transform='translate(-4.893 -2)' fill={color} />
      <path id='Caminho_216' data-name='Caminho 216' d='M14.679,18H13.036A9.036,9.036,0,0,0,4,27.036a.821.821,0,0,0,.821.821H22.893a.821.821,0,0,0,.821-.821A9.036,9.036,0,0,0,14.679,18ZM5.692,26.214a7.393,7.393,0,0,1,7.344-6.571h1.643a7.393,7.393,0,0,1,7.344,6.571Z' transform='translate(-4 -4.857)' fill={color} />
    </svg>

  )
}

export default User