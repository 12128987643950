import { useState } from 'react'
import useRest from 'hooks/useRest'
import { storageKey } from 'services/axios'
import { addConsumerUnitParam } from 'utils/helpers'

export type TGetInvoiceParams = {
  yearFilter: number
  uc?: string
} | undefined

export type TInvoice = {
  consumerUnit: string
  detailUrl: string
  dueDate: string
  referenceMonth: string
  status: string
  total: number
}

export type TGetInvoiceResponse = {
  invoices: TInvoice[]
}

export type TGetInvoiceInfoResponse = {
  energyConsumedCard: {
    energyConsumed: number
    referenceMonth: string
  }
  nextInvoiceCard: {
    dueDate: string
    nextInvoiceValue: number
    referenceMonth: string
    invoiceUrl: string
    status: string
  }
  savingsCard: {
    monthlySavings: number
    referenceMonth: string
  }
  totalSavingsCard: {
    savingsHistory: number
  }
}


const useInvoices = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getInvoiceInfo = async (triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/invoices/cards'
    const params = addConsumerUnitParam({}, storageKey)

    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    if (response.detailCode === 10004) return null
    return response.content as TGetInvoiceInfoResponse
  }

  const getInvoices = async (data:TGetInvoiceParams, triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/invoices'
    const params = addConsumerUnitParam(data, storageKey)

    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    return response.content as TGetInvoiceResponse
  }

  const getFilterYear = async (triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/invoices/listFilter'
    const params = addConsumerUnitParam({}, storageKey)

    const response = await rest.get(url, params)
    if (triggerLoading) setIsLoading(false)
    return response as any
  }

  return {
    isLoading,
    getInvoices,
    getFilterYear,
    getInvoiceInfo
  }
}

export default useInvoices