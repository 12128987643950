import React from 'react'
import Header from './Header'
import Sidebar from './Sidebar'

import {
  Container,
  Main,
  Content
} from './styles'

type TProps = {
  children: React.ReactNode
}

const Layout = ({ children }: TProps): JSX.Element => {

  return (
    <Container>
      <Header />
      <Main>
        <Sidebar />
        <Content>{children}</Content>
      </Main>
    </Container>
  )
}

export default Layout