import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const Energy = (props: TProps): JSX.Element => {
  const {
    size = '14',
    color = '#868686'
  } = props

  return (
    <svg id='energy_ic' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width={size} height={size} viewBox='0 0 16 32'>
      <defs>
        <clipPath id='clip-path'>
          <rect id='Retângulo_136' data-name='Retângulo 136' width='16' height='32' fill='none' stroke={color} strokeWidth='1' />
        </clipPath>
      </defs>
      <g id='Grupo_180' data-name='Grupo 180' clipPath='url(#clip-path)'>
        <path id='Caminho_1270' data-name='Caminho 1270' d='M15,1H3L1,19H5L3,31,15,11H11Z' fill='none' stroke={color} strokeLinejoin='round' strokeWidth='2' />
      </g>
    </svg>
  )
}

export default Energy