import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const House = (props: TProps): JSX.Element => {
  const {
    size = '14',
    color = '#868686'
  } = props

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 29.923 26'>
      <g id='home_ic' transform='translate(-1.07 -3)'>
        <path id='Caminho_1261' data-name='Caminho 1261' d='M30,15a1,1,0,0,1-.58-.19L16,5.23,2.58,14.81a1,1,0,0,1-1.16-1.62l14-10a1,1,0,0,1,1.16,0l14,10A1,1,0,0,1,30,15Z' fill={color} />
        <path id='Caminho_1262' data-name='Caminho 1262' d='M5,9A1,1,0,0,1,4,8V4A1,1,0,0,1,5,3H9A1,1,0,0,1,9,5H6V8A1,1,0,0,1,5,9Z' fill={color} />
        <path id='Caminho_1263' data-name='Caminho 1263' d='M25,29H20a1,1,0,0,1-1-1V21H13v7a1,1,0,0,1-1,1H7a3,3,0,0,1-3-3V16a1,1,0,0,1,2,0V26a1,1,0,0,0,1,1h4V20a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v7h4a1,1,0,0,0,1-1V16a1,1,0,0,1,2,0V26a3,3,0,0,1-3,3Z' fill={color} />
      </g>
    </svg>

  )
}

export default House