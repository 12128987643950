import styled from 'styled-components'
import {
  Button,
  Tag as AntTag
} from 'antd'

export const TableContainer = styled.div`
  background: ${({ theme }): any => theme.colors.WHITE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  max-width: ${({ theme }): any => theme.breakPoints.CONTENT_MAX_WIDTH};
  width: 100%;
  padding: 40px;
  margin-top: 40px;
  
  .ant-table-cell {
    text-align: left;
    font: normal normal normal 14px/18px 'Exo 2';
    letter-spacing: 0px;
    color: ${({ theme }): any => theme.colors.GRAY};
  }

  .ant-table {
    overflow-x: hidden;

    th {
      font-weight: 800;
    }

    @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
      overflow-x: scroll;
    }
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    margin-right: 0;
    padding: 15px;
  }
`

export const TableTitle = styled.h2`
  text-align: center;
  font: normal normal 600 30px/32px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.YELLOW};
  margin-bottom: 45px;
  max-width: ${({ theme }): any => theme.breakPoints.CONTENT_MAX_WIDTH};
`

export const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 35px 0 10px 0;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    flex-direction: column-reverse;
    margin-bottom: 15px;
  }
`

export const RedirectButton = styled(Button)`
  background: ${({ theme }): any => theme.colors.GRAY} 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 267px;
  height: 60px;
  margin-top: 10px;

  :hover{
    background: ${({ theme }): any => theme.colors.YELLOW} 0% 0% no-repeat padding-box;
  }
  
  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    width: 80%;
  }
`

export const LabelButton = styled.h3`
  font: normal normal normal 25px/30px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.WHITE};
  margin: 0;
`

export const Tag = styled(AntTag)`
  width: 163px;
  height: 33px;
  text-align: center;
  font: normal normal normal 13px/20p 'Exo 2';
  color: ${({ theme }): any => theme.colors.WHITE};
  padding-top: 5px;
`

export const SpinContainerTable = styled.div`
  width: 100%;
  align-self: center;
  padding: 0 45%;
`

export const ErrorMessage = styled.h1`
  text-align: center;
  font: normal normal 300 30px/36px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.YELLOW};

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    text-align: center;
    align-self: center;
    margin-bottom: 16.5px;
  }
`
