import styled from 'styled-components'
import { Select as AntdSelect } from 'antd'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 20px;
`

export const Select = styled(AntdSelect)`
  .ant-select-selection-item, .ant-select-item-option-content, .ant-select-dropdown{
    text-align: center;
    font: normal normal normal 16px/19px 'Exo 2';
    letter-spacing: 0px;
    color: ${({ theme }): any => theme.colors.GRAY};
    opacity: 1;
    min-width: 145px;
  }
`

export const Title = styled.span`
  text-align: left;
  font: normal normal bold 14px/17px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.GRAY};
  opacity: 1;
`