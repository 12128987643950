import styled from 'styled-components'

export const Container = styled.div<{$marginBottom?:boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: ${({ theme }): any => theme.colors.WHITE};
  margin-top: 30px;
  padding: 20px 5px;
  width: 100%;
  max-width: ${({ theme }): any => theme.breakPoints.CONTENT_MAX_WIDTH};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  margin-bottom: ${({ $marginBottom }): any => $marginBottom ? '5rem' : '0'};

  .div2PDF{
    width: 100%;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    width: 100%;
    margin-top: 20px;
  }
`

export const Message = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font: normal normal normal 40px/48px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.GRAY};
  opacity: 1;
  height:546px;
`
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    flex-direction: column;
    justify-content: center;
  }
`

export const TitleChart = styled.div`
  width: 100%;
  margin-right: 25%;
  text-align: right;

  h3{
    font: normal normal 900 22px/26px 'Exo 2';
    letter-spacing: 0px;
    color: ${({ theme }): any => theme.colors.GRAY};
    opacity: 1;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    margin-right: 0;
    
    h3{
      text-align: center;
    }
  }
`

export const Period = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
