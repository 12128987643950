import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import Menu from '../Menu'
import {
  StyledHeader,
  LogoImg,
  MenuContainer,
  MenuButton
} from './styles'
import { theme } from 'styles'
import { BurgerMenu, Help } from 'components/Icons'
import SolCopWhiteLogo from 'assets/whiteLogoSolCop.svg'

export type THeaderProps = {
  fixed?: boolean
}

const Header = (props:THeaderProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [scrollbarWidth, setScrollbarWidth] = useState<number>(0)
  const { fixed = true } = props

  const position = fixed ? 'fixed' : 'relative'

  const navigate = useNavigate()

  const { pathname } = useLocation()

  const onClickGoToPage = (): void => navigate('/help')

  useEffect(() => {
    const bodyOffsetWidth = document.body?.offsetWidth
    if(!bodyOffsetWidth) return
    const innerPageContainerClientWidth = document.getElementById('inner-page-container')?.clientWidth
    if(!innerPageContainerClientWidth) return
    setScrollbarWidth(bodyOffsetWidth - 350 - innerPageContainerClientWidth)
  }, [document.body, document.getElementById('inner-page-container')])

  useEffect(() => {
    setIsMenuOpen(false)
  }, [pathname])

  return (
    <StyledHeader style={{ position }}>

      <MenuButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <BurgerMenu color='white' size='35' />
      </MenuButton>

      <div>
        <LogoImg
          src={SolCopWhiteLogo}
          alt='Logo Sol Cop Branco'
          onClick={(): void => navigate('/')}
        />
        <div onClick={() => onClickGoToPage()} style={{ paddingRight: `${scrollbarWidth}px` }}>
          <Help color={theme.colors.WHITE} size='26' />
        </div>
      </div>

      {isMenuOpen ?
        <MenuContainer>
          <Menu />
        </MenuContainer>
        : <></>
      }
    </StyledHeader>
  )
}

export default Header