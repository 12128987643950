import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-kerning: auto;
  }

  html {
    font-family: 'Exo-2', sans-serif;
    -webkit-text-size-adjust: 100%;
  }

  body {
    margin: 0;
    overflow-y: hidden;
  }
`
