import { useState } from 'react'
import useRest from 'hooks/useRest'

export type TRequestTokenParams = {
  cpfCnpj: string
  password: string
  captchaResponse: string
}

const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const requestToken = async (data: TRequestTokenParams, triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/auth/signin'

    const response = await rest.post(url, data, undefined, true)
    if (triggerLoading) setIsLoading(false)
    return response as any
  }

  const logout = async (triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/auth/logout'

    const response = await rest.post(url)
    if (triggerLoading) setIsLoading(false)
    return response as any
  }

  return {
    isLoading,
    logout,
    requestToken,
  }
}

export default useLogin