import { useToasts, Options } from 'react-toast-notifications'
import { useAuth } from 'hooks/useAuth'
import { getApiErroMessage } from 'utils/helpers'
import { useNavigate } from 'react-router-dom'

enum ApiErrorCodes {
  INVALID_TOKEN = 401
}

export enum ApiErrorMessages {
  INVALID_TOKEN = 'Auth token is not recognized',
  INVALID_CREDENTIALS = 'Invalid email or password',
  ZIP_CODE_NOT_FOUND = 'ZipCode not found',
  EXPIRED_TOKEN = 'Expired token',
  INVALID_USER_TOKEN = 'Invalid token',
  INACTIVE_USER = 'User not activated'
}

const toastErrorConfig = {
  appearance: 'error',
  autoDismiss: true,
} as Options

const useErrorHandler = () => {
  const { addToast, removeAllToasts } = useToasts()
  const { removeUserData, isSignedIn } = useAuth()

  const navigate = useNavigate()

  const handleApiError = (code: number) => {
    const message = getApiErroMessage(code)
    addToast(message, toastErrorConfig)
  }

  const handleGenericError = () => {
    const message =
      'Algo de errado aconteceu. Por favor, tente novamente mais tarde!'
    addToast(message, toastErrorConfig)
  }

  const handleInvalidTokenError = () => {
    const message = 'Sua sessão foi encerrada. Por favor, faça login novamente!'
    removeAllToasts()
    addToast(message, toastErrorConfig)
  }

  const handleApiErrorDesambiguation = (code: number) => {
    if (code === ApiErrorCodes.INVALID_TOKEN && isSignedIn) {
      removeUserData()
      handleInvalidTokenError()
      navigate(0)
      return
    }

    handleApiError(code)
  }

  const handleBusinessLogicError = (message: string) => {
    const getMessage = (message: ApiErrorMessages) => {
      switch (message) {
        case ApiErrorMessages.INVALID_CREDENTIALS:
          return 'E-mail e/ou senha inválidos'
        case ApiErrorMessages.ZIP_CODE_NOT_FOUND:
          return 'CEP não encontrado'
        case ApiErrorMessages.EXPIRED_TOKEN:
          return 'Esse link expirou!'
        case ApiErrorMessages.INVALID_USER_TOKEN:
          return 'Esse token está inválido!'
        case ApiErrorMessages.INACTIVE_USER:
          return 'O usuário está desativado. Entre em contato com a Copérnico'
        default:
          return 'Algo de errado aconteceu. Por favor, tente novamente mais tarde!'
      }
    }

    const _message = getMessage(message as ApiErrorMessages)

    addToast(_message, toastErrorConfig)
  }

  return {
    handleApiError,
    handleGenericError,
    handleApiErrorDesambiguation,
    handleBusinessLogicError
  }
}

export default useErrorHandler
