import React, { useEffect, useState } from 'react'
import {
  Table,
  Spin
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import type { ColumnsType } from 'antd/es/table'
import useInvoices,
{
  TGetInvoiceResponse,
  TInvoice
}
  from 'repositories/useInvoices'
import InnerPageContainer from 'components/InnerPageContainer'
import YearFilter from 'components/YearFilter'
import InvoiceInfoCards from 'components/InvoiceInfoCards'
import {
  TableContainer,
  TableTitle,
  Tag,
  TableButton,
  Header,
  SpinContainerTable,
  ErrorMessage,
  ErrorContainer,
} from './styles'
import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'


const getTagColor = ( tag:string ) => {
  switch(tag){
    case 'PAGO': return '#5FBCA2'
    case 'EM ABERTO': return '#FFC057'
    case 'VENCIDO': return '#FF5757'
    default: return 'inherit'
  }
}

const columns: ColumnsType<TInvoice> = [
  {
    title: 'UC',
    dataIndex: 'consumerUnit',
    key: 'consumerUnit',
    align: 'center'
  },
  {
    title: 'Mês',
    dataIndex: 'referenceMonth',
    key: 'referenceMonth',
    align: 'center'
  },
  {
    title: 'Vencimento',
    dataIndex: 'dueDate',
    key: 'dueDate',
    align: 'center'
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    align: 'center',
    render: total => (
      <span>
        {total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
      </span>
    )
  },
  {
    title: 'Situação',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: status => (
      <Tag color={getTagColor(status.toUpperCase())}>{status.toUpperCase()}</Tag>
    )
  },
  {
    title: 'Fatura detalhada',
    dataIndex: 'detailUrl',
    key: 'detailUrl',
    align: 'center',
    render: detailUrl => (
      <TableButton>
        <a href={detailUrl} target='_blank' rel='noreferrer noopener'>VER FATURA</a>
      </TableButton>
    )
  }
]


const Invoices = () => {
  const [invoicesList, setInvoicesList] = useState<TGetInvoiceResponse>()
  const [selectedYear, setSelectedYear] = useState<number | null>()


  const invoices = useInvoices()

  const antIcon =
    <LoadingOutlined
      style={{
        fontSize: 70,
        color: 'rgb(245, 175, 25)',
        alignSelf: 'center'
      }}
      width={'100%'}
      spin
    />

  const getInvoices = async () => {
    const params = !selectedYear ? undefined : { yearFilter: selectedYear }
    const response = await invoices.getInvoices(params)
    if(!response) return
    setInvoicesList(response)
  }

  useEffect(() => {
    getInvoices()
  }, [selectedYear])

  const onChangeFilter = ( value:unknown ) => {
    if(!value) {
      setSelectedYear(undefined)
      return
    }
    setSelectedYear(Number(value))
  }

  return (
    <InnerPageContainer>
      <PageWrapper>
        <PageTitle>Faturas</PageTitle>
        <InvoiceInfoCards />
        {(!invoices.isLoading && invoicesList?.invoices.length === 0) ?
          <ErrorContainer>
            <ErrorMessage>Não há faturas para serem exibidas no momento! Volte mais tarde.</ErrorMessage>
          </ErrorContainer> :
          <TableContainer>
            <Header>
              <TableTitle>Histórico de Faturas</TableTitle>
              <YearFilter onChange={onChangeFilter} />
            </Header>
            {invoices.isLoading ? <SpinContainerTable><Spin indicator={antIcon} /></SpinContainerTable> :
              <Table
                columns={columns}
                dataSource={invoicesList?.invoices}
                pagination={false}
              />
            }
          </TableContainer>
        }
      </PageWrapper>
    </InnerPageContainer>
  )
}

export default Invoices