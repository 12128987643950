import styled from 'styled-components'
import { Modal as AntdModal } from 'antd'

export const StyledModal = styled(AntdModal)`
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;

  h2 {
    text-align: center;
    font: normal normal normal 24px/29px 'Exo 2';
    letter-spacing: 0px;
    color: #758592;
    opacity: 1;
    color: ${({ theme }): any => theme.colors.GRAY};
    margin: 15px 0 30px 0;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    .ant-modal-content {
      padding: 0;
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 100px;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    padding: 0 150px
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    padding: 15px 11px
  }
`

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
`

export const Card = styled.div`
  background: #7585921C 0% 0% no-repeat padding-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 200px;
  height: 200px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  top: 0;
  transition: top ease 0.5s;

  h3{
    text-align: center;
    font: normal normal 900 20px/24px 'Exo 2';
    letter-spacing: 0px;
    color: #758592;
  }

  span{
    text-align: center;
    font: normal normal normal 13px/18px 'Exo 2';
    letter-spacing: 0px;
    color: #758592;
  }

  :hover {
    top: -10px;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    width: 150px;
  }
`
