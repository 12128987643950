import React from 'react'
import GlobalStyles from './style'
import Routes from 'routes'
import Providers from 'providers'
import VLibras from '@djpfs/react-vlibras'

import './App.css'

const App = () => (
  <Providers>
    <VLibras />
    <Routes />
    <GlobalStyles />
  </Providers>
)

export default App
