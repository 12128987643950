enum Colors {
  BLACK = '#000000',
  WHITE = '#FFFFFF',
  GRAY = '#758592',
  LIGHT_GRAY= '#EDEDEF',
  RED = '#F12711',
  YELLOW = '#FFC057',
  ORANGE = '#F5AF19',
  BLUE = '#0000FF',
}

enum BreakPoints {
  SMALL_SCREEN = '650px',
  MEDIUM_SCREEN = '1187px',
  BIG_SCREEN = '1450px',
  BS_MD = '767px',
  BS_LG = '992px',
  MENU_WIDTH = '350px',
  CONTENT_MAX_WIDTH = '1206px',
  SCREEN_CAP = '1596px' // MENU + CONTENT + left-padding(40px)
}

type TTheme = {
  colors: typeof Colors
  breakPoints: typeof BreakPoints
}

export const theme: TTheme = {
  colors: Colors,
  breakPoints: BreakPoints
}
