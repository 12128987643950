import React, {
  useState,
  useEffect,
  useRef
} from 'react'

import { useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { useAuth } from 'hooks/useAuth'
import useLogin from 'repositories/useLogin'
import { storageKey } from 'services/axios'
import {
  Input,
  Form,
  Checkbox,
  Spin
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import InputMask, { InputState } from 'react-input-mask'
import { initialValues, validation } from './validations'
import Header from 'components/Header'
import Footer from 'components/Footer'
import CodeModal from 'components/CodeModal'
import ChangePasswordModal from 'components/ChangePasswordModal'
import {
  User as UserIcon,
  Lock as LockIcon,
  OpenEye,
  ClosedEye
} from 'components/Icons'
import SolImg from 'assets/sol_element.png'
import SolCopGrayLogo from 'assets/grayLogoSolCop.svg'
import SolCopWhiteLogo from 'assets/whiteLogoSolCop.svg'
import FamilyImg from 'assets/family_background.png'
import {
  Container,
  Canvas,
  SolBackgroundImg,
  FamilyBackgroundImg,
  Title,
  TitleContainer,
  SubTitle,
  LoginContainer,
  LoadingContainer,
  LogoImg,
  LoginSubTitle,
  LoginFooter,
  Label,
  SmallLabel,
  LoginButton,
  FormItem,
  EyeIconDiv,
  LabelButton,
  LinkArea,
  CheckBoxArea,
  LogoImgMobile
} from './styles'

export type TLogin = {
  cpfCnpj: string
  password: string
}

const Login = () => {
  const login = useLogin()
  const auth = useAuth()
  const navigate = useNavigate()
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const [form] = Form.useForm<TLogin>()

  const [mask, setMask] = useState('999.999.999-99')
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [checked, setChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isCodeModalOpen, setIsCodeModalOpen] = useState(false)
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false)
  const [codeEmail, setCodeEmail] = useState('placeholder-email')

  const userLogin = async (values: TLogin): Promise<void> => {
    setIsLoading(true)
    if (!recaptchaRef.current) {
      setIsLoading(false)
      return
    }

    const tokenCAPTCHA = await recaptchaRef.current.executeAsync()
    if (!tokenCAPTCHA) {
      setIsLoading(false)
      return
    }
    recaptchaRef.current.reset()
    const formattedCpfCnpj = values.cpfCnpj.replace(/[^0-9]/g, '')

    const requestTokenParams = {
      cpfCnpj: formattedCpfCnpj,
      password: values.password,
      captchaResponse: tokenCAPTCHA
    }

    const response = await login.requestToken(requestTokenParams)
    if (!response) {
      setIsLoading(false)
      return
    }

    if (checked) {
      const storedFormData = {
        ...form.getFieldsValue(),
        checked: true
      }
      localStorage.setItem('formData', JSON.stringify(storedFormData))
    }
    else localStorage.removeItem('formData')
    const userData = response.content

    auth.saveUserData(userData)
    setIsLoading(false)
    if (userData.user.isFirstAccess === false) navigate('/')
    else {
      setCodeEmail(userData.user.email)
      setIsCodeModalOpen(true)
    }
  }

  useEffect(() => {
    if (!auth || !auth.userData) return
    const requestExistentToken = Boolean(localStorage.getItem(storageKey))
    const isFirsAccess = Boolean(auth.userData.user.isFirstAccess)
    if (requestExistentToken && !isFirsAccess) navigate('/')
  }, [])

  const onChangeCheckbox = () => {
    setChecked(!checked)
  }

  useEffect(() => {
    const storagedFormData = localStorage.getItem('formData')
    if (!storagedFormData) return
    const formattedFormData = JSON.parse(storagedFormData)
    form.setFieldValue('cpfCnpj', formattedFormData.cpfCnpj)
    form.setFieldValue('password', formattedFormData.password)
    setChecked(formattedFormData.checked)
    const cleanedValue = formattedFormData.cpfCnpj?.replace(/\D/g, '')
    cleanedValue?.length === 14 ? setMask('99.999.999/9999-99') : setMask('999.999.999-99')
  }, [])

  const beforeMaskedValueChange = (newState: InputState, oldState: InputState, userInput: string): InputState => {
    const oldValue = (oldState.value || '').replace(/\D/g, '')
    const newValue = (newState.value || '').replace(/\D/g, '')
    const isErasure = oldValue.length > newValue.length

    if ((!userInput && !isErasure)) return newState

    const input = (userInput || '').replace(/\D/g, '')

    let updatedValue = ''
    if (input.length > 1 && newValue.length <= 11) {
      updatedValue = input
    } else if (oldValue.length === 11) {
      updatedValue = (newValue + input).substring(0, 14)
    } else {
      updatedValue = newValue
    }

    if ((updatedValue.length) > 11) {
      setMask('99.999.999/9999-99')
      const selectionValue = updatedValue.length + (updatedValue.length > 12 ? 4 : 3)
      return { ...newState, selection: { start: selectionValue, end: selectionValue }, value: updatedValue }
    }
    setMask('999.999.999-99')
    return newState
  }

  return (
    <Container>
      <Header fixed={false} />
      <Canvas>
        <SolBackgroundImg>
          <img src={SolImg} alt='Sol Laranja' />
        </SolBackgroundImg>
        <FamilyBackgroundImg>
          <img src={FamilyImg} alt='Familia' />
        </FamilyBackgroundImg>
        <LogoImgMobile
          src={SolCopWhiteLogo}
          alt='Logo Sol Cop Branco'
        />
        <TitleContainer>
          <Title>Bem vindo ao <b>Portal do Cliente</b></Title>
          <SubTitle>Tenha acesso aos seus dados e gastos com energia elétrica</SubTitle>
        </TitleContainer>
        <LoginContainer isLoading={isLoading}>
          <LogoImg
            src={SolCopGrayLogo}
            isLoading={isLoading}
            alt='Logo Sol Cop Cinza'
          />
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 70,
                  color: 'rgb(117, 133, 146)',
                  alignSelf: 'center',
                  marginTop: '20px',
                  display: isLoading ? 'inline-block' : 'none'
                }}
                width={'100%'}
                spin
              />
            }
          />
          <LoadingContainer isLoading={isLoading}>
            <LoginSubTitle>Entre para iniciar sua sessão</LoginSubTitle>
            <Form
              name='loginForm'
              initialValues={initialValues}
              layout='vertical'
              onFinish={userLogin}
              form={form}
              autoComplete='off'
              size='middle'
              requiredMark='optional'
            >
              <FormItem
                label={<Label>CPF/CNPJ</Label>}
                rules={validation('cpfCnpj')}
                name='cpfCnpj'
                id='input-cpfCnpj'
              >
                <InputMask
                  mask={mask}
                  maskChar='_'
                  alwaysShowMask={false}
                  beforeMaskedValueChange={beforeMaskedValueChange}
                >
                  {(inputProps: any) =>
                    <Input
                      {...inputProps}
                      size='large'
                      addonBefore={<UserIcon size='22px' color='white' />}
                      placeholder='000.000.000-00'
                      maxLength={30}
                    />
                  }
                </InputMask>
              </FormItem>
              <FormItem
                label={<Label>Senha</Label>}
                rules={validation('password')}
                name='password'
                id='input-cpf'
              >
                <Input.Password
                  size='large'
                  addonBefore={<LockIcon size='22px' color='white' />}
                  visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
                  iconRender={
                    visible => visible ?
                      <EyeIconDiv onClick={() => setPasswordVisible(prevState => !prevState)}><OpenEye size='27.5px' /></EyeIconDiv> :
                      <EyeIconDiv onClick={() => setPasswordVisible(prevState => !prevState)}><ClosedEye size='27.5px' /> </EyeIconDiv>}
                />
              </FormItem>
              <CheckBoxArea>
                <Checkbox checked={checked} onChange={onChangeCheckbox}>
                  <SmallLabel>Lembrar-me</SmallLabel>
                </Checkbox>
                <a href='/forgot-password'><SmallLabel>Esqueci minha senha</SmallLabel></a>
              </CheckBoxArea>
              <LoginButton
                htmlType='submit'
                loading={isLoading}
              >
                <LabelButton>Iniciar Sessão</LabelButton>
              </LoginButton>
              <LinkArea>
                <a href='https://solcopernico.com.br/'><SmallLabel>Ainda não sou cliente</SmallLabel></a>
              </LinkArea>
            </Form>
            <LoginFooter>
              Este aplicativo é protegido por reCAPTCHA e a <a href='/terms'>política de privacidade </a>
              e os <a href='/terms'>termos de serviço</a> do Google se aplicam.
            </LoginFooter>
          </LoadingContainer>
        </LoginContainer>
      </Canvas>
      <Footer />
      <ReCAPTCHA
        style={{ display: 'none' }}
        ref={recaptchaRef}
        size='invisible'
        sitekey='6LcFB1AqAAAAAFC0JaBadS6W0QYiqGNFPgapHBbS'
      />
      <CodeModal
        email={codeEmail}
        isOpen={isCodeModalOpen}
        onCancel={() => setIsCodeModalOpen(false)}
        onFinishOperation={() => {
          setIsCodeModalOpen(false)
          setIsChangePasswordModalOpen(true)
        }}
      />
      <ChangePasswordModal
        passwordType='defaultPassword'
        isOpen={isChangePasswordModalOpen}
        onCancel={() => setIsChangePasswordModalOpen(false)}
        onFinishOperation={() => setIsChangePasswordModalOpen(false)}
      />
    </Container>
  )
}

export default Login