import React from 'react'
import InnerPageContainer from 'components/InnerPageContainer'
import { Container, Header } from './styles'
import Accordion from 'components/Accordion'
import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'

const Help = () => {

  return(
    <InnerPageContainer>
      <PageWrapper>
        <PageTitle>Ajuda</PageTitle>
        <Container>
          <Header>
            Perguntas frequentes
          </Header>
          <Accordion />
        </Container>
      </PageWrapper>
    </InnerPageContainer>
  )
}

export default Help