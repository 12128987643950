import styled from 'styled-components'
import {
  Button as AntButton,
  Tag as AntTag
} from 'antd'

export const ErrorContainer = styled.div`
  background: ${({ theme }): any => theme.colors.WHITE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  max-width: ${({ theme }): any => theme.breakPoints.CONTENT_MAX_WIDTH};
  width: 100%;
  padding: 40px;
  margin: 30px 35px 0 0;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    margin-right: 0;
    padding: 15px;
  }
`

export const ErrorMessage = styled.h1`
  text-align: center;
  font: normal normal 300 30px/36px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.YELLOW};

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    text-align: center;
    align-self: center;
    margin-bottom: 16.5px;
  }
`


export const TableContainer = styled.div`
  background: ${({ theme }): any => theme.colors.WHITE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  max-width: ${({ theme }): any => theme.breakPoints.CONTENT_MAX_WIDTH};
  width: 100%;
  padding: 40px;
  margin: 30px 35px 0 0;
  
  .ant-table-cell {
    text-align: left;
    font: normal normal normal 14px/18px 'Exo 2';
    letter-spacing: 0px;
    color: ${({ theme }): any => theme.colors.GRAY};
  }

  .ant-table {
    overflow-x: hidden;

    th {
      font-weight: 800;
    }

    @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
      overflow-x: scroll;
    }
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    margin-right: 0;
    padding: 15px;
  }
`

export const TableTitle = styled.h2`
  text-align: center;
  font: normal normal normal 24px/29px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.GRAY};
  margin-bottom: 40px;
  padding: 0 31%;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    font: normal normal normal 20px/25px 'Exo 2';
    padding: 0;
  }
`

export const Tag = styled(AntTag)`
  width: 90px;
  height: 30px;
  text-align: center;
  font: normal normal normal 12px/16px 'Exo 2';
  color: ${({ theme }): any => theme.colors.WHITE};
  padding-top: 5px;
`

export const TableButton = styled(AntButton)`
  width: 110px;
  height: 30px;
  color: ${({ theme }): any => theme.colors.WHITE};
  text-align: center;
  font: normal normal normal 12px/16px 'Exo 2';
  letter-spacing: 0px;
  background: ${({ theme }): any => theme.colors.ORANGE} 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    flex-direction: column-reverse;
  }
`

export const SpinContainerTable = styled.div`
  width: 100%;
  align-self: center;
  padding: 0 45%;
`