import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const Help = (props: TProps): JSX.Element => {
  const {
    size = '14',
    color = '#868686'
  } = props

  return (
    <svg id='help_icon' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width={size} height={size} viewBox='0 0 26 26'>
      <defs>
        <clipPath id='clipPath'>
          <rect id='Retângulo_138' data-name='Retângulo 138' width='26' height='26' fill='none' stroke={color} strokeWidth='1' />
        </clipPath>
      </defs>
      <g id='Grupo_184' data-name='Grupo 184' clipPath='url(#clipPath)'>
        <circle id='Elipse_21' data-name='Elipse 21' cx='11.5' cy='11.5' r='11.5' transform='translate(1 1)' fill='none' stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
        <path id='Caminho_1273' data-name='Caminho 1273' d='M8.09,8.319a3.477,3.477,0,0,1,6.757,1.159c0,2.318-3.477,3.477-3.477,3.477' transform='translate(1.287 0.954)' fill='none' stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
        <line id='Linha_27' data-name='Linha 27' transform='translate(13 19)' fill='none' stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
      </g>
    </svg>
  )
}

export default Help