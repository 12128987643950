import React from 'react'
import {
  SelectCustom,
  Container
} from './styles'

type TOptions = {
  value: string | number,
  label: string
}

type TSelectProps = {
  onChange: (value: unknown)=>void,
  title:string,
  options:TOptions[],
  value:unknown
}

const Select = ({ options, title, onChange, value }:TSelectProps) => {

  return(
    <Container>
      <SelectCustom
        placeholder={title}
        options={options}
        bordered={false}
        defaultValue={null}
        size='large'
        onChange={onChange}
        value={value}
      />
    </Container>
  )
}

export default Select
