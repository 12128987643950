import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from 'hooks/useAuth'
import { ToastProvider } from 'react-toast-notifications'
import { ThemeProvider } from 'styled-components'
import { ConfigProvider } from 'antd'
import { theme } from 'styles'

type TProps = {
  children: React.ReactNode
}

const Providers = ({ children }: TProps) => (
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <AuthProvider>
          <ConfigProvider
            theme={{
              components: {
                Radio: {
                  buttonColor: theme.colors.ORANGE,
                  buttonSolidCheckedActiveBg: theme.colors.ORANGE,
                  buttonSolidCheckedBg: theme.colors.ORANGE,
                  buttonSolidCheckedHoverBg: theme.colors.ORANGE,
                },
                Button: {
                  colorPrimaryBorderHover: theme.colors.WHITE,
                  colorPrimaryHover: theme.colors.WHITE,
                  colorPrimary: theme.colors.WHITE,
                  colorPrimaryActive: theme.colors.ORANGE,
                  colorPrimaryTextHover: theme.colors.ORANGE,
                },
                Checkbox: {
                  colorPrimary: theme.colors.GRAY,
                  colorPrimaryHover: theme.colors.ORANGE
                },
                Menu: {
                  itemHoverColor: theme.colors.WHITE,
                  itemHoverBg: theme.colors.BLACK
                },
                Input: {
                  activeBorderColor: theme.colors.ORANGE,
                  hoverBorderColor: theme.colors.ORANGE,
                  activeShadow: '0 0 0 2px rgb(245, 175, 25, 0.1)'
                },
                Table: {
                  headerColor: theme.colors.GRAY
                }
              },
              token: {
                fontFamily: 'Exo 2',
                colorPrimary: theme.colors.ORANGE,
              }
            }}
          >
            {children}
          </ConfigProvider>
        </AuthProvider>
      </ToastProvider>
    </ThemeProvider>
  </BrowserRouter>
)

export default Providers
