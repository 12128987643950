import Moment from 'moment'
import html2canvas from 'html2canvas'
import pdfConverter from 'jspdf'

export const formatDateTimeToView = (date?: string) => {
  if(!date) return ''
  return Moment(date).format('DD/MM/YYYY HH:mm')
}

export const formatDateToServer = (date: string) => (
  Moment(date).format('YYYY-MM-DD')
)

export const removeTimeFromDateTime = (date: string) => (
  date.substring(0, 10)
)

export const formatDecimalNumber = (number: string | number) => {
  const formatter = new Intl.NumberFormat(
    'pt-BR',
    {
      style: 'decimal',
      maximumFractionDigits: 2
    }
  )

  return formatter.format(Number(number))
}

export const formatDecimalNumberToPercentage = (number: string |number) => (
  `${formatDecimalNumber(number)} %`
)

export const formatCurrency = (number: string | number) => {
  const formatter = new Intl.NumberFormat(
    'pt-BR',
    {
      style: 'currency',
      currency: 'BRL'
    }
  )

  return formatter.format(Number(number))
}

export const formatDateToView = (date: string) => {
  if (!date) return ''
  return Moment(date).format('DD/MM/YYYY')
}

export const getCurrentDate = () => Moment().format('YYYY-MM-DD')

export const getDiffDaysBetweenDates = (start: string, end: string) => {
  const startDate = Moment(start, 'YYYY-MM-DD')
  const endDate = Moment(end, 'YYYY-MM-DD')
  return Moment.duration(endDate.diff(startDate)).asDays()
}

export const fileToBase64 = async (file: Blob) => {
  const base64 = await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

  const [, data] = String(base64).split(',')

  return data
}

export const downloadBase64File = async (
  fileName: string,
  base64string: string,
  fileFormat = 'application/pdf'
) => {
  await new Promise<void>((resolve, reject) => {
    try {
      const linkSource = `data:${fileFormat};base64,${base64string}`

      const downloadLink = document.createElement('a')
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
      resolve()
    } catch (error) {
      reject(error)
    }
  })
}

export const formatCurrencyToServer = (number: string) => {
  if (!number) return '0'

  if (number.indexOf(','))
    return number.replace(/\./g, '').replace(',', '.')

  return number
}

export const getApiErroMessage = (code: number) => {
  switch (code) {
    case -1000: return 'Algo inesperado aconteceu'
    case -1001: return 'Você precisa estar autenticado para realizar esta operação!'
    case -1002: return 'Você não tem permissão para realizar esta operação!'
    case -1003: return 'A requisição contém dados inválidos!'
    case -1007: return 'A requisição contém dados inválidos!'
    case -2000: return 'Usuário e/ou senha inválidos!'
    case -2001: return 'Usuário não encontrado!'
    case -2002: return 'Senha fornecida foi considerada fraca!'
    case -2003: return 'Sua senha antiga está incorreta, tente novamente!'
    case -2004: return 'Senha e Confirmação de Senha não possuem o mesmo valor!'
    case -2005: return 'Nova senha não pode ser a mesma que a senha anterior!'
    case -2006: return 'A senha padrão já foi trocada anteriormente, utilize a Alterar Senha para trocá-la.'
    case -2007: return 'A senha padrão precisa ser alterada antes de acessar o sistema!'
    case -2008: return 'E-mail precisa ser verificado antes de acessar o sistema!'
    case -3000: return 'Código inválido!'
    case -3001: return 'Código já foi verificado anteriormente!'
    case -3002: return 'Código expirado! Favor gerar outro.'
    case -3003: return 'Código revogado!'
    case -3004: return 'O Usuário ja foi verificado!'
    case -4000: return 'Erro ao enviar o e-mail!'
    case -4001: return 'Lista de envio de e-mails vazia!'
    case -4002: return 'Remetente do e-mail está ausente!'
    case -4003: return 'Corpo do e-mail está ausente!'
    case -4004: return 'O campo assunto do e-mail está ausente!'
    case -4005: return 'Detalhes do e-mail estão ausentes!'
    case -4008: return 'O Usuário não possui email, por favor entre em contato com o suporte'
    case -5000: return 'CAPTCHA inválido!'
    case -5001: return 'Erro ao validar CAPTCHA!'
    case -5002: return 'O Segredo do CAPTCHA não foi encontrado!'
    case -5003: return 'O Segredo do CAPTCHA está inválido ou não foi gerado corretamente!'
    case -5004: return 'O parâmetro de resposta do CAPTCHA está faltando!'
    case -5005: return 'O parâmetro de resposta do CAPTCHA é inválido ou não foi gerado corretamente!'
    case -5007: return 'A resposta do CAPTCHA não é mais válida, expirou ou já foi usada anteriormente!'
    case -6000: return 'O arquivo está vazio!'
    case -6001: return 'Formato de arquivo não suportado!'
    case -6002: return 'Erro ao salvar o arquivo!'
    case -7001: return 'Token de reset de senha inválido! Checar e-mail novamente ou refazer o envio de um novo token!'
    case -8000: return 'Unidade consumidora não encontrada!'
    case -8001: return 'A Unidade consumidora não pertence ao Usuário informado!'
    case -10000: return 'Unidade consumidora não encontrada!'
    case -10001: return 'A Unidade consumidora não pertence ao Usuário informado!'
    case -12000: return 'Unidade consumidora não encontrada!'
    case -12001: return 'A Unidade consumidora não pertence ao Usuário informado!'
    case -13000: return 'Unidade consumidora não encontrada!'
    case -13001: return 'A Unidade consumidora não pertence ao Usuário informado!'
    case -14000: return 'Unidade consumidora não encontrada!'
    case -14001: return 'A Unidade consumidora não pertence ao Usuário informado!'
    case -15000: return 'Unidade consumidora não encontrada!'
    case -15001: return 'A Unidade consumidora não pertence ao Usuário informado!'
    case -17000: return 'Cliente ja foi indicado!'
    case -17001: return 'Unidade consumidora não encontrada!'
    default: return 'Algo de errado aconteceu. Por favor, tente novamente mais tarde!'
  }
}

export const formatDateEnUs = (date: Moment.MomentInput) => (
  Moment(date).format('YYYY-MM-DD hh:mm')
)

export const getYesNoFromBoolean = (value: boolean) => (
  value ? 'Sim' : 'Não'
)

export const removeMaskGuides = (value: string) => (
  value
    .replace(/_/g, '')
    .replace(/-/g, '')
    .replace(/\(/g, '')
    .replace(/\)/g, '')
    .replace(/ /g, '')
    .replace(/[^\d]+/g, '')
)

export const formatCpfCnpj = (number?: string) => {
  if (!number) return ''

  const [regext, replacement] = number.length === 11
    ? [/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4']
    : [/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5']

  return number.replace(regext, replacement)
}

export const addDaysInDate = (days: string) => (
  Moment(new Date()).add(days, 'days').format('YYYY-MM-DD')
)

export const downloadUrl = (url: string, name: string): void => {
  const link = document.createElement('a')

  link.download = name
  link.href = url
  link.target = '_BLANK'

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const sleep = (ms: number = 0) => new Promise( resolve => setTimeout(resolve, ms))

export const sortArray = (list: TSelectOption[]) => {
  if(!list.length) return []

  const ordenedArray = list.sort((old, after) => {
    if(old.label > after.label) return 1
    if(old.label < after.label) return -1

    return 0
  })

  return ordenedArray
}

export const downloadPdfChart = (name:string, position:number) => {

  const input = window.document.getElementsByClassName('div2PDF')[position] as any
  const downloadFile = window.document.getElementsByClassName('downloadFile')[position] as any

  downloadFile.style.display = 'none'

  html2canvas(input).then(canvas => {
    const img = canvas.toDataURL('image/png')
    const pdf = new pdfConverter('l', 'pt')
    pdf.addImage(
      img,
      'png',
      15, 40, 750, 500
    )
    pdf.save(`${getCurrentDate()}-${name}.pdf`)

    downloadFile.style.display = 'grid'

  })
}

export const getSelectedConsumerUnit = (storageKey: string) => {
  const formattedUser = JSON.parse(String(localStorage.getItem(storageKey)))
  return(formattedUser.selectedUC)
}

export const addConsumerUnitParam = (data: any, storageKey: string) => {
  if(getSelectedConsumerUnit(storageKey) === 'VISÃO GERAL') return data

  const newParams = {
    ...data,
    uc: getSelectedConsumerUnit(storageKey)
  }

  return newParams
}

