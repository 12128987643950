import React from 'react'

import {
  Wrapper
} from './styles'

type TProps = {
  children: React.ReactNode
}

const PageWrapper = ({ children }: TProps): JSX.Element => (
  <Wrapper>
    {children}
  </Wrapper>
)


export default PageWrapper