import React, { useState } from 'react'
import { Input, Form } from 'antd'
import {
  StyledModal,
  ContainerForm,
  ConfirmButton,
  LabelButton
} from './styles'
import useCodeVerification from 'repositories/useCodeVerification'

export type TCodeModalProps = {
  email: string
  isOpen: boolean
  onFinishOperation: () => void
  onCancel: () => void
}

type TForm = {
  input0: string
  input1: string
  input2: string
  input3: string
  input4: string
  input5: string
}

const initialValues: TForm = {
  input0: '',
  input1: '',
  input2: '',
  input3: '',
  input4: '',
  input5: ''
}

const CodeModal = (props: TCodeModalProps) => {
  const {
    email,
    isOpen = false,
    onFinishOperation,
    onCancel
  } = props

  const codeVerificationApi = useCodeVerification()

  const [form] = Form.useForm<TForm>()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)


  const handleInputChange = (index: number) => {
    if (form.getFieldValue(`input${index}`) === '') return
    if (!parseInt(form.getFieldValue(`input${index}`))) {
      const upperCasedDigit = form.getFieldValue(`input${index}`).toUpperCase()
      form.setFieldValue(`input${index}`, upperCasedDigit)
    }
    if (index === 5) return
    const nextInput = document.getElementById(`input-${index + 1}`)
    if (nextInput) {
      nextInput.focus()
    }
  }

  const handleFinish = async () => {
    const code = (
      form.getFieldValue('input0') +
      form.getFieldValue('input1') +
      form.getFieldValue('input2') +
      form.getFieldValue('input3') +
      form.getFieldValue('input4') +
      form.getFieldValue('input5')
    )

    const response = await codeVerificationApi.postCode({ code })
    if (!response) return
    onFinishOperation()
  }

  const resendCode = async () => {
    const response = await codeVerificationApi.resendCode()
    if (!response) return
    alert('Email reenviado!')
  }

  const onChangeInputValue = () => {
    if (
      form.getFieldValue('input0') === '' ||
      form.getFieldValue('input1') === '' ||
      form.getFieldValue('input2') === '' ||
      form.getFieldValue('input3') === '' ||
      form.getFieldValue('input4') === '' ||
      form.getFieldValue('input5') === ''
    ) setIsButtonDisabled(true)

    else setIsButtonDisabled(false)
  }

  const onBackspacePressed = (event: React.KeyboardEvent, index: number) => {
    if (index === 0) return
    if (!event) return
    if (!(event.key === 'Backspace')) return
    if (!(form.getFieldValue(`input${index}`) === '')) return

    const lastInput = document.getElementById(`input-${index - 1}`)
    if (lastInput) {
      lastInput.focus()
    }
  }

  return (
    <StyledModal
      open={isOpen}
      onOk={handleFinish}
      confirmLoading={codeVerificationApi.isLoading}
      onCancel={onCancel}
      width='1215px'
      footer={null}
      closeIcon={null}
      centered
    >
      <h2>Digite o código que enviamos ao seu e-mail</h2>
      <h5>Um código de 6 dígitos foi enviado para o email {email}. Verifique sua caixa de entrada.</h5>
      <ContainerForm>
        <h3>Digite o código:</h3>
        <Form
          name='codeModalForm'
          layout='inline'
          initialValues={initialValues}
          onFinish={handleFinish}
          form={form}
          autoComplete='off'
          size='middle'
          requiredMark='optional'
          onFieldsChange={onChangeInputValue}
        >
          <Form.Item
            name='input0'
            id='formItem-input-0'
          >
            <Input
              onChange={() => handleInputChange(0)}
              size='large'
              placeholder=''
              id='input-0'
              maxLength={1}
              onKeyDown={(event: React.KeyboardEvent) => onBackspacePressed(event, 0)}
            />
          </Form.Item>
          <Form.Item
            name='input1'
            id='formItem-input-1'
          >
            <Input
              onChange={() => handleInputChange(1)}
              size='large'
              placeholder=''
              id='input-1'
              maxLength={1}
              onKeyDown={(event: React.KeyboardEvent) => onBackspacePressed(event, 1)}
            />
          </Form.Item>
          <Form.Item
            name='input2'
            id='formItem-input-2'
          >
            <Input
              onChange={() => handleInputChange(2)}
              size='large'
              placeholder=''
              id='input-2'
              maxLength={1}
              onKeyDown={(event: React.KeyboardEvent) => onBackspacePressed(event, 2)}
            />
          </Form.Item>
          <Form.Item
            name='input3'
            id='formItem-input-3'
          >
            <Input
              onChange={() => handleInputChange(3)}
              size='large'
              placeholder=''
              id='input-3'
              maxLength={1}
              onKeyDown={(event: React.KeyboardEvent) => onBackspacePressed(event, 3)}
            />
          </Form.Item>
          <Form.Item
            name='input4'
            id='formItem-input-4'
          >
            <Input
              onChange={() => handleInputChange(4)}
              size='large'
              placeholder=''
              id='input-4'
              maxLength={1}
              onKeyDown={(event: React.KeyboardEvent) => onBackspacePressed(event, 4)}
            />
          </Form.Item>
          <Form.Item
            name='input5'
            id='formItem-input-5'
          >
            <Input
              onChange={() => handleInputChange(5)}
              size='large'
              placeholder=''
              id='input-5'
              maxLength={1}
              onKeyDown={(event: React.KeyboardEvent) => onBackspacePressed(event, 5)}
            />
          </Form.Item>
        </Form>
        <h4 onClick={resendCode}>Reenviar o código</h4>
        <ConfirmButton
          type='primary'
          htmlType='submit'
          onClick={form.submit}
          loading={codeVerificationApi.isLoading}
          disabled={isButtonDisabled}
        >
          <LabelButton>Confirmar</LabelButton>
        </ConfirmButton>
      </ContainerForm>
    </StyledModal>
  )
}

export default CodeModal