import styled from 'styled-components'

export const SolBackgroundImg = styled.img`
  position: absolute;
  top: -15%;
  left: 50%;
  width: 45vw;
  height: auto;
  z-index: 1;
  opacity: 1;
`

export const Canvas = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5% 18.5%;
  background-image: linear-gradient(to right, #F5AF19, #F12711);
`

export const Title = styled.h1`
  margin: 50px 0 65px 0;
  font: normal normal 300 50px/60px 'Exo 2';
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  z-index: 10;
`

export const Container = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 100px;
  background-color: white;
  z-index: 10;

  p, h2 {
    color: #758592;
    margin-top: 17.5px;
  }

  h2 {
    font: normal normal normal 20px/25px 'Exo 2';
    letter-spacing: 0px;
  }

  p {
    font: normal normal normal 15px/25px 'Exo 2';
    letter-spacing: 0px;
  }
`