import React, {
  useEffect,
  useState,
  useMemo
} from 'react'

import {
  Input,
  Form,
  Radio,
  RadioChangeEvent
} from 'antd'
import { storageKey } from 'services/axios'
import InputMask from 'react-input-mask'
import { useNavigate } from 'react-router-dom'
import { useToasts, Options } from 'react-toast-notifications'
import useReferAndWin from 'repositories/useReferAndWin'
import InnerContainer from 'components/InnerPageContainer'
import { getSelectedConsumerUnit } from 'utils/helpers'
import {
  User as UserIcon,
  Phone as PhoneIcon,
  Building as BuildingIcon
} from 'components/Icons'
import BackgroundImgMobile from 'assets/indique&ganhe_banner_mobile.png'
import { initialValues, validation } from './validations'
import {
  FormContainer,
  FormTitle,
  SubmitButton,
  LabelButton,
  Label,
  FormItem,
  Canvas,
  FormDiv,
  RedirectButton,
  MobileBackgroundImg
} from './styles'
import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'

export type TReferNWinForm = {
  name?: string,
  phone?: string,
  responsibleName?: string,
  companyName?: string,
  companyPhone?: string
  isCompany: boolean
}

const toastSuccessConfig = {
  appearance: 'success',
  autoDismiss: true,
} as Options

const ReferNWin = () => {
  const [isCompany, setIsCompany] = useState(false)
  const [isUCSelected, setIsUCSelected] = useState<boolean>(false)

  const [form] = Form.useForm<TReferNWinForm>()
  const navigate = useNavigate()
  const referNWinRepository = useReferAndWin()
  const { addToast } = useToasts()

  const isAdmin = useMemo(() => {
    const formattedUser = JSON.parse(String(localStorage.getItem(storageKey)))
    return formattedUser.isAdmin
  }, [])

  const onChangeRadio = (e: RadioChangeEvent) => {
    setIsCompany(e.target.value)
  }

  const postForm = async (values: TReferNWinForm) => {
    const params = {
      ...values,
      personType: isCompany ? 'J' : 'F'
    }

    const response = await referNWinRepository.postReference(params)
    if(!response) return
    addToast('Indicação enviada com sucesso!', toastSuccessConfig)
  }

  useEffect(() => {
    if(getSelectedConsumerUnit(storageKey) === 'VISÃO GERAL') setIsUCSelected(false)
    else setIsUCSelected(true)
  }, [])


  return (
    <InnerContainer>
      <PageWrapper>
        <PageTitle>
          Indique & Ganhe
        </PageTitle>
        <Canvas>
          <MobileBackgroundImg>
            <img src={BackgroundImgMobile} alt='indique&ganhe banner' />
          </MobileBackgroundImg>
          <FormDiv>
            <RedirectButton
              loading={false}
              onClick={() => navigate('/refer-and-win/list')}
            >
              <LabelButton>Ver minhas indicações</LabelButton>
            </RedirectButton>
            <FormContainer>
              <FormTitle>Quem você está indicando?</FormTitle>
              <Radio.Group onChange={onChangeRadio} value={isCompany}>
                <Radio value={false}>Amigo(a)</Radio>
                <Radio value={true}>Empresa</Radio>
              </Radio.Group>
              <Form
                name='loginForm'
                initialValues={initialValues}
                layout='vertical'
                onFinish={postForm}
                form={form}
                autoComplete='off'
                size='middle'
                requiredMark={false}
              >
                {!isCompany &&
                  <FormItem
                    label={<Label>Nome do seu amigo(a)</Label>}
                    rules={validation('name')}
                    name='name'
                    id='input-name'
                    required
                  >
                    <Input
                      size='large'
                      addonBefore={<UserIcon size='22px' color='white' />}
                      placeholder='Digite o nome do seu amigo(a)'
                      maxLength={30}
                      disabled={!isUCSelected || isAdmin}
                    />
                  </FormItem>
                }
                {!isCompany &&
                  <FormItem
                    label={<Label>Telefone do seu amigo(a)</Label>}
                    rules={validation('phone')}
                    name='phone'
                    id='input-phone'
                    required
                  >
                    <InputMask
                      mask={'(99) 9 9999-9999'}
                      maskChar='_'
                      alwaysShowMask={false}
                      disabled={!isUCSelected || isAdmin}
                    >
                      {(inputProps: any) =>
                        <Input
                          {...inputProps}
                          size='large'
                          addonBefore={<PhoneIcon size='22px' color='white' />}
                          placeholder='(00) 0 0000-0000'
                          maxLength={30}
                          disabled={!isUCSelected || isAdmin}
                        />
                      }
                    </InputMask>
                  </FormItem>
                }
                {isCompany &&
                  <FormItem
                    label={<Label>Nome do responsável</Label>}
                    rules={validation('responsibleName')}
                    name='responsibleName'
                    id='input-responsibleName'
                    required
                  >
                    <Input
                      size='large'
                      addonBefore={<UserIcon size='22px' color='white' />}
                      placeholder='Digite o nome do responsável'
                      maxLength={30}
                      disabled={!isUCSelected || isAdmin}
                    />
                  </FormItem>
                }
                {isCompany &&
                  <FormItem
                    label={<Label>Nome fantasia da empresa</Label>}
                    rules={validation('companyName')}
                    name='companyName'
                    id='input-companyName'
                    required
                  >
                    <Input
                      size='large'
                      addonBefore={<BuildingIcon size='22px' color='white' />}
                      placeholder='Digite o nome fantasia na empresa'
                      maxLength={30}
                      disabled={!isUCSelected || isAdmin}
                    />
                  </FormItem>
                }
                {isCompany &&
                  <FormItem
                    label={<Label>Telefone da empresa</Label>}
                    rules={validation('companyPhone')}
                    name='companyPhone'
                    id='input-companyPhone'
                    required
                  >
                    <InputMask
                      mask={'(99) 9 9999-9999'}
                      maskChar='_'
                      alwaysShowMask={false}
                      disabled={!isUCSelected || isAdmin}
                    >
                      {(inputProps: any) =>
                        <Input
                          {...inputProps}
                          size='large'
                          addonBefore={<PhoneIcon size='22px' color='white' />}
                          placeholder='(00) 0 0000-0000'
                          maxLength={30}
                          disabled={!isUCSelected || isAdmin}
                        />
                      }
                    </InputMask>
                  </FormItem>
                }
                <SubmitButton
                  htmlType='submit'
                  loading={referNWinRepository.isLoading}
                  disabled={!isUCSelected || isAdmin}
                >
                  <LabelButton>Convidar</LabelButton>
                </SubmitButton>
              </Form>
            </FormContainer>
          </FormDiv>
        </Canvas>
      </PageWrapper>
    </InnerContainer>
  )
}

export default ReferNWin