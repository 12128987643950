import React from 'react'
import { storageKey } from 'services/axios'
import { useNavigate } from 'react-router-dom'
import { TConsumerUnit } from 'repositories/useConsumerUnits'
import {
  ConsumerUnit as ConsumerUnitIcon,
  MultipleConsumerUnit as MultipleConsumerUnitIcon
} from 'components/Icons'
import {
  StyledModal,
  Wrapper,
  CardsContainer,
  Card
} from './styles'

export type TSelectConsumerUnitsModalProps = {
  isOpen: boolean,
  consumerUnitList: TConsumerUnit[]
  onFinishOperation: ()=> void
}

const SelectConsumerUnitsModal = (props: TSelectConsumerUnitsModalProps) => {
  const {
    isOpen = false,
    consumerUnitList,
    onFinishOperation
  } = props

  const navigate = useNavigate()

  const selectUC = (uc: TConsumerUnit) => {
    const userData = JSON.parse(String(localStorage.getItem(storageKey)))
    const newUserData = {
      ...userData,
      selectedUC: uc.code,
      selectedAddress: uc.address ? uc.address : null
    }
    localStorage.setItem(storageKey, JSON.stringify(newUserData))
    window.dispatchEvent(new Event('selectUC'))
    window.dispatchEvent(new Event('reloadHome'))
    onFinishOperation()
    navigate('/')
  }

  return (
    <StyledModal
      open={isOpen}
      width = '1180px'
      footer = {null}
      closeIcon = {null}
      centered
    >
      <Wrapper>
        <h2>Selecione a unidade consumidora correspondente</h2>
        <CardsContainer>
          <Card
            onClick={() => selectUC({ code: 'VISÃO GERAL' })}
          >
            <MultipleConsumerUnitIcon size='50' />
            <h3>VISÃO GERAL</h3>
            <span>Selecione essa opção para obter uma visão geral de todas as suas unidades.</span>
          </Card>
          {consumerUnitList?.map( uc => (
            <Card
              key={uc.code}
              onClick={() => selectUC(uc)}
            >
              <ConsumerUnitIcon size='50' />
              <h3>{uc.code}</h3>{
                uc.address && (
                  <>
                    <span>{
                      `${uc.address.street}, 
                    ${uc.address.number}, 
                    ${uc.address.neighborhood}, 
                    ${uc.address.city}, 
                    ${uc.address.state.uf}`
                    }
                    </span>
                  </>
                )
              }
            </Card>
          ))}
        </CardsContainer>
      </Wrapper>
    </StyledModal>
  )
}

export default SelectConsumerUnitsModal