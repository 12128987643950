import { useState } from 'react'
import useRest from 'hooks/useRest'

type TPostCode = {
  code: string
}

const useCodeVerification = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const postCode = async (data:TPostCode, triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/emailVerification/validate'

    const response = await rest.post(url, data)
    if (triggerLoading) setIsLoading(false)
    return response as any
  }

  const resendCode = async (triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)
    const url = '/emailVerification/resend'

    const response = await rest.post(url)
    if (triggerLoading) setIsLoading(false)
    return response as any
  }

  return {
    isLoading,
    postCode,
    resendCode
  }
}

export default useCodeVerification