import React from 'react'
import {
  Container,
  Socials,
  Copyrights,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon
} from './styles'

export type TFooterProps = {
  color?: string
}

const Footer = (props:TFooterProps) => {

  const { color = '#5A5A5A' } = props

  return (
    <Container>
      <Socials>
        <a href='https://www.facebook.com/sol.copernico/'>
          <FacebookIcon color={color} />
        </a>
        <a href='https://www.instagram.com/solcopernico/'>
          <InstagramIcon color={color} />
        </a>
        <a href='https://www.linkedin.com/company/solcopernico/'>
          <LinkedinIcon color={color} />
        </a>
      </Socials>
      <Copyrights $textColor={color}>© 2023 SOL Copérnico. Todos os direitos Reservados.</Copyrights>
    </Container>
  )
}

export default Footer