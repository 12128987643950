import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { storageKey } from 'services/axios'
import InnerPageContainer from 'components/InnerPageContainer'
import BarChart from 'components/BarChart'
import InvoiceInfoCards from 'components/InvoiceInfoCards'
import { LoadingOutlined } from '@ant-design/icons'

import useReferAndWin, { TReferAndWin } from 'repositories/useReferAndWin'
import useInvoiceComparisonChart, { TInvoiceComparisonChart, TColumns } from 'repositories/useInvoiceComparisonChart'

import { Divider, Spin } from 'antd'

import Tooltip from 'components/Tooltip'

import {
  Container,
  Card,
  Context,
  Header,
  SpinContainer,
  ValueSpan,
  CardTitle,
  Button,
  ButtonIndicate,
  Referent,
  Message,
  ContentChart,
  TooltipContainer,
  TitleChart,
  Title,
  Period
} from './styles'

import IndiqueEGanhe from 'assets/logo_indique_ganhe@2x.png'
import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'

const Home = () => {
  const [referAndWinData, setReferAndWinData] = useState<TReferAndWin>()
  const [reloadHome, setReloadHome] = useState<boolean>(false)
  const [invoiceComparisonChartData, setInvoiceComparisonChartData] = useState<TInvoiceComparisonChart>()

  const referAndWin = useReferAndWin()
  const invoiceComparisonChart = useInvoiceComparisonChart()
  const navigate = useNavigate()

  const textTooltip = 'Neste gráfico é possível fazer a comparação entre a fatura 100% CEMIG e seu cenário atual CEMIG + SOL Copérnico. Nós garantimos que, ao somar o valor da fatura CEMIG + boleto SOL Copérnico, o valor gasto mensal com energia é menor do que o que se pagaria apenas para a distribuidora (CEMIG).'

  const getReferAndWinData = async () => {
    const response = await referAndWin.getReferAndWin()
    if (!response) return
    setReferAndWinData(response)
  }

  const getInvoiceComparisonChartData = async () => {
    setInvoiceComparisonChartData({} as TInvoiceComparisonChart)
    const response = await invoiceComparisonChart.getInvoiceComparisonChart()
    if (!response) return
    setInvoiceComparisonChartData(response)
  }

  const isEmpty = useMemo(() => Object.keys(referAndWinData?.cardData ?? {}).length === 0, [referAndWinData])

  const getMaxValue = useMemo(() => {
    let max = 0
    if (invoiceComparisonChartData?.chartData) {
      invoiceComparisonChartData?.chartData.columns.map(({ sectionData }: TColumns) => {
        max = max < Math.max(...sectionData) ? Math.max(...sectionData) : max
      })
    }

    return Math.ceil(max / 50) * 100
  }, [invoiceComparisonChartData, reloadHome])

  const isSmallFontNecessary = (value: number | undefined) => {
    if (!value) return false
    if (value >= 100000) return true
    return false
  }

  const assemblesArrayValues = (columnName: string, sectionData: number[], maxValue: number) => {
    switch (columnName) {
      case 'Cenário CEMIG':
        return [{
          label: 'CEMIG',
          data: [...sectionData, null, null],
          color: '#758592',
          isGradient: false,
          stack: 'Stack 0',
          renderLabel: {
            value: 'CEMIG',
            display: true
          },
        }]
      case 'Desconto SOL Copérnico':
        const value = maxValue - sectionData[0]
        return [
          {
            label: 'desconto',
            data: [null, value, null],
            color: '#ffffff00',
            isGradient: false,
            stack: 'Stack 0',
            renderLabel: {
              display: false
            },
          },
          {
            label: 'desconto',
            data: [null, ...sectionData, null],
            color: '#5FBCA2',
            isGradient: false,
            stack: 'Stack 0',
            renderLabel: {
              display: true
            },
          }
        ]
      default:
        return [
          {
            label: 'SOL + Copérnico',
            data: [null, null, sectionData[0]],
            color: '#758592',
            isGradient: false,
            stack: 'Stack 0',
            renderLabel: {
              value: 'CEMIG',
              display: true
            },
          },
          {
            label: 'SOL + Copérnico',
            data: [null, null, sectionData[1]],
            isGradient: true,
            color: '#ffffff45',
            gradientColors: ['#F30', '#ff7700'],
            stack: 'Stack 0',
            renderLabel: {
              display: true
            },
          }
        ]
    }
  }

  const invoiceComparisonChartDatasets = useMemo(() => {
    if (invoiceComparisonChartData?.chartData) {
      const chartData: any[] | undefined = []
      let maxValue = 0

      invoiceComparisonChartData?.chartData.columns.map(({ sectionData, columnName }: TColumns) => {
        if (columnName === 'Cenário CEMIG') {
          maxValue = Math.max(...sectionData)
        }
        chartData.push(...assemblesArrayValues(columnName, sectionData, maxValue))
      })

      return chartData
    }
  }, [invoiceComparisonChartData])

  useEffect(() => {
    const formattedUser = JSON.parse(String(localStorage.getItem(storageKey)))
    if (formattedUser.isAdmin && !formattedUser.selectedUC) return
    getReferAndWinData()
    getInvoiceComparisonChartData()
  }, [])

  window.addEventListener('reloadHome', () => setReloadHome(true))

  useEffect(() => {
    if (!reloadHome) return
    getReferAndWinData()
    getInvoiceComparisonChartData()
    setReloadHome(false)
  }, [reloadHome])

  return (
    <InnerPageContainer>
      <PageWrapper>
        <PageTitle>Início</PageTitle>
        <InvoiceInfoCards isDashboard />
        <Container>
          {!invoiceComparisonChartDatasets?.length && !invoiceComparisonChart.isLoading ? (
            <Message>
              <h3>Você ainda não tem informações de consumo e economia geradas.</h3>
            </Message>
          ) : (
            <ContentChart>
              <TooltipContainer>
                <Tooltip content={textTooltip} />
              </TooltipContainer>
              <TitleChart>
                <Title>
                  <span>Comparativo de faturas</span>
                  <span>Cenário atual SOL Copérnico X Cenário hipotético CEMIG</span>
                </Title>
                <Period>
                  <span> Referente a </span>
                  {invoiceComparisonChartData?.chartData &&
                    (<span>{invoiceComparisonChartData?.chartData.referenceMonth ?? '?'}</span>)
                  }
                </Period>
              </TitleChart>
              <BarChart
                labels={['Cenário CEMIG', 'Desconto SOL Copérnico', 'Cenário SOL Copérnico + CEMIG']}
                labelsMobile={['Cenário CEMIG', 'Desconto SOL Copérnico', 'Cenário SOL Copérnico + CEMIG']}//{['CEMIG', 'Desconto', 'SOL' ]}
                dataSets={invoiceComparisonChartDatasets ?? []}
                symbol='currency'
                disableLegend={false}
                type='vertical'
                disableExportChart
                isLoading={invoiceComparisonChart.isLoading}
                maxValuePositionY={getMaxValue}
                title='Histórico de economia'
                disableButton={false}
                showTooltip={false}
              />
            </ContentChart>
          )}
          <Card>
            <Header>
              <img src={IndiqueEGanhe} />
            </Header>
            <Context>
              <CardTitle>Economia por indicação</CardTitle>
              {referAndWin.isLoading ? <SpinContainer>
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 70,
                        color: 'rgb(245, 175, 25)',
                        alignSelf: 'center'
                      }}
                      width={'100%'}
                      spin
                    />
                  }
                />
              </SpinContainer> :
                <>
                  <ValueSpan
                    className={`${isSmallFontNecessary(referAndWinData?.cardData?.economyByReferral)
                      ? 'smallerFont' : ''
                    }`}
                  >
                    {isEmpty ? '?' : referAndWinData?.cardData?.economyByReferral
                      .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                    }
                  </ValueSpan>
                  <Referent>
                    <span>
                      Referente a
                    </span>
                    <span>
                      {isEmpty ? '?' : referAndWinData?.cardData?.referenceMonth}
                    </span>
                  </Referent>
                  <Divider className='last-divider' />
                </>
              }
            </Context>
            <Context>
              <CardTitle>Economia total por indicação</CardTitle>
              {referAndWin.isLoading ? <SpinContainer>
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 70,
                        color: 'rgb(245, 175, 25)',
                        alignSelf: 'center'
                      }}
                      width={'100%'}
                      spin
                    />
                  }
                />
              </SpinContainer> :
                <>
                  <ValueSpan
                    className={`${isSmallFontNecessary(referAndWinData?.cardData?.totalEconomyByReferral)
                      ? 'smallerFont' : ''
                    }`}
                  >
                    {isEmpty ? '?' : referAndWinData?.cardData?.totalEconomyByReferral
                      .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                    }
                  </ValueSpan>
                  <Divider className='last-divider' />
                  <Button onClick={() => navigate('/refer-and-win/list')}>Ver histórico de indicações</Button>
                  <ButtonIndicate onClick={() => navigate('/refer-and-win')}>Indicar alguém</ButtonIndicate>
                </>
              }
            </Context>
          </Card>
        </Container>

      </PageWrapper>
    </InnerPageContainer>
  )
}

export default Home