import React, {
  useState,
  useEffect,
  useMemo
} from 'react'
import { storageKey } from 'services/axios'
import { useNavigate } from 'react-router-dom'
import useClients, { TClient, TGetClientsParams } from 'repositories/useClients'
import { formatCpfCnpj } from 'utils/helpers'
import { Input, Pagination, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { User } from 'components/Icons'
import {
  StyledModal,
  Wrapper,
  CardsContainer,
  Card,
  CardTextArea,
  ProfilePicture,
  SpinContainer,
  EllipsisBlock
} from './styles'

export type TSelectClientsModalProps = {
  isOpen: boolean,
  onFinishOperation: () => void
}

const defaultParams: TGetClientsParams = {
  searchTerm: '',
  page: 0,
  pageSize: 5,
}

const antIcon =
  <LoadingOutlined
    style={{
      fontSize: 70,
      color: 'rgb(245, 175, 25)',
      alignSelf: 'center'
    }}
    width={'100%'}
    spin
  />

const SelectClientsModal = (props: TSelectClientsModalProps) => {
  const {
    isOpen = false,
    onFinishOperation
  } = props

  const { Search } = Input

  const [clientsList, setClientsList] = useState<TClient[]>([])
  const [totalClients, setTotalClients] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(5)
  const [term, setTerm] = useState<string>('')
  const clientsRepository = useClients()

  const isAdmin = useMemo(() => {
    const formattedUser = JSON.parse(String(localStorage.getItem(storageKey)))
    return formattedUser.isAdmin
  }, [])

  const getClients = async (params: TGetClientsParams) => {
    const response = await clientsRepository.getClients(params)
    if (!response) return
    setClientsList(response.content.pageResponse.content)
    setTotalClients(response.content.pageResponse.totalElements)
  }

  useEffect(() => {
    if (!isAdmin) return
    getClients(defaultParams)
  }, [])

  const navigate = useNavigate()

  const selectClient = (client: TClient) => {
    const userData = JSON.parse(String(localStorage.getItem(storageKey)))
    const newAddress = client.address ? {
      ...client.address,
      state: client.state,
      city: client.city.name
    } : null
    const newUserData = {
      ...userData,
      selectedUC: client.consumerUnitCode,
      profilePictureUrl: client.profilePictureUrl,
      selectedAddress: newAddress
    }
    localStorage.setItem(storageKey, JSON.stringify(newUserData))
    window.dispatchEvent(new Event('selectUC'))
    window.dispatchEvent(new Event('reloadHome'))
    window.dispatchEvent(new Event('storage'))
    onFinishOperation()
    navigate('/')
  }

  const onChangeSearch = (e: any) => {
    const removeDots = e.target.value.split('.').join('')

    const removeTracer = removeDots.split('-').join('')

    const removeBackslash = removeTracer.split('/').join('')

    setTerm(removeBackslash)
  }

  const filteredSearch = () => {
    const params: TGetClientsParams = {
      ...defaultParams,
      searchTerm: term
    }
    setCurrentPage(1)
    getClients(params)
  }

  const onChangePagination = (page: number, pageSize: number) => {
    setCurrentPage(page)
    setPageSize(pageSize)
    const params: TGetClientsParams = {
      page: page - 1,
      pageSize,
      searchTerm: term
    }
    getClients(params)
  }

  return (
    <StyledModal
      open={isOpen}
      width='800px'
      footer={null}
      closeIcon={null}
      centered
    >
      <Wrapper>
        <CardsContainer>
          <Search
            placeholder='Pesquise o nome do cliente referente'
            onChange={onChangeSearch}
            onSearch={filteredSearch}
            size='large'
          />
          {clientsRepository.isLoading ? <SpinContainer><Spin indicator={antIcon} /></SpinContainer> :
            clientsList.map(client => (
              <Card
                key={client.consumerUnitCode}
                onClick={() => selectClient(client)}
              >
                <ProfilePicture>
                  {client.profilePictureUrl ? <img src={client.profilePictureUrl} alt='' /> :
                    <User color='white' size='30px' />}
                </ProfilePicture>
                <CardTextArea>
                  <EllipsisBlock><span>{client.customerName}</span></EllipsisBlock>
                  <h3>{client.email}</h3>
                  <h3>{formatCpfCnpj(client.customerDocument)}</h3>
                  <h3>UC: {client.consumerUnitCode}</h3>
                </CardTextArea>
              </Card>
            ))}
          <Pagination
            total={totalClients}
            current={currentPage}
            onChange={onChangePagination}
            pageSize={pageSize}
            pageSizeOptions={[5, 10, 20]}
          />
        </CardsContainer>
      </Wrapper>
    </StyledModal>
  )
}

export default SelectClientsModal