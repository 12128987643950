import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const Exchange = (props: TProps): JSX.Element => {
  const {
    size = '30',
    color = '#868686'
  } = props


  return (
    <svg id='exchange_icon' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width={size} height={size} viewBox='0 0 49 49'>
      <defs>
        <clipPath id='clip-path'>
          <rect id='Retângulo_265' data-name='Retângulo 265' width='49' height='49' fill={color} />
        </clipPath>
      </defs>
      <g id='Grupo_291' data-name='Grupo 291' clipPath='url(#clip-path)'>
        <path id='Caminho_1297' data-name='Caminho 1297' d='M8.938,7.163A20.572,20.572,0,0,1,39.452,34.326L33.883,22.57h5.142A16.456,16.456,0,0,0,10.792,11.078L8.94,7.165ZM36.2,37.977A20.572,20.572,0,0,1,5.688,10.814L11.256,22.57H6.114A16.456,16.456,0,0,0,34.348,34.062L36.2,37.975Zm-10.723-9.59L19.661,22.57l-5.819,5.817-2.909-2.909,8.728-8.726,5.817,5.817L31.3,16.753l2.909,2.909-8.728,8.726' transform='translate(2.114 2.114)' fill={color} />
      </g>
    </svg>

  )
}

export default Exchange