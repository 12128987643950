import styled from 'styled-components'

export const Container = styled.div`
  max-width: ${({ theme }): any => theme.breakPoints.CONTENT_MAX_WIDTH};
`

export const Header = styled.div`
  max-width: ${({ theme }): any => theme.breakPoints.CONTENT_MAX_WIDTH};
  text-align: left;
  font: normal normal 300 30px/37px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.GRAY};
  opacity: 1;
  margin-bottom: 20px;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    text-align: center;
    align-self: center;
  }
`

