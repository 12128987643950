import React from 'react'
import Header from 'components/Header'
import SolImg from 'assets/sol_element.png'
import {
  Canvas,
  Container,
  Title,
  SolBackgroundImg
} from './styles'

const Terms = () => {

  return (
    <>
      <Header />
      <SolBackgroundImg src={SolImg} alt='Sol Laranja' />
      <Canvas>
        <Title>Políticas de Privacidade</Title>
        <Container>
          <h2>SOL Copérnico</h2>

          <p>O presente Termo de Uso e Política de Privacidade (doravante “Termo”)
          têm como objetivo regulamentar o acesso e a utilização dos portais e aplicativos
          disponibilizados pelo Grupo SOL Copérnico, liderado pela SOL Copérnico Energias Renováveis Ltda.
          (doravante “Grupo SOL Copérnico” ou “Prestador”), inscrito no Cadastro Nacional de Pessoas
          Jurídicas(“CNPJ/MF”) sob o nº39.439.388/0001-67, com endereço à Avenida Paulista, nº 2100,
          CEP 01.310-930, São Paulo/SP, aos seus clientes pessoa jurídica (“Cliente”),
          por seus representantes (“Usuários”)</p>

          <h2>1 - Aceitação e Acesso</h2>

          <p>A Plataforma encontra-se disponível para acesso por meio de download na App Store e na Google
          Play, podendo ser acessada, também, por meio de plataforma web, disponível no endereço
          www.solcopernico.com.br.</p>

          <p>Ao aceitar o presente Termo, o Usuário e o Cliente declaram que são os únicos responsáveis por
          todas as informações disponibilizadas e inseridas quando de seu acesso à Plataforma,
          responsabilizando-se, com exclusividade, e inclusive perante terceiros, por quaisquer danos ou
          prejuízos decorrentes de informações incorretas, incompletas ou inverídicas por eles inseridas
          na Plataforma.</p>

          <p>Por meio da Plataforma, o Cliente, por meio do Usuário, poderá ter acesso a dados e informações
          referentes à conta bancária e investimentos, realizar pagamentos e transações financeiras,
          contatar seu gerente, bem como poderá contratar diversos serviços prestados pelo Grupo SOL
          Copérnico e cancelar aqueles que não mais lhe aprouverem.</p>

          <h2>2 - Declarações do Cliente e do Usuário e Vedações</h2>

          <p>Ao aceitar o presente Termo, o Usuário reconhece: (i) ser civilmente capaz e penalmente imputável
          à luz da legislação brasileira; (ii) ser representante do Cliente já contratante dos serviços
          prestados pelo Grupo SOL Copérnico; e (iii) que utilizará a Plataforma apenas para fins lícitos,
          reconhecendo que é o responsável, com exclusividade, pela utilização que dela fizer, eximindo o
          Grupo SOL Copérnico de qualquer responsabilidade por danos ou prejuízos decorrentes de seu uso da
          Plataforma e dos serviços por meio desta oferecidos.</p>

          <p>Ainda, o Cliente e o Usuário reconhecem que não deverão: (i) disponibilizar por meio da Plataforma
          qualquer conteúdo ilícito ou contrário à moral e aos bons costumes, ou capaz de violar os direitos
          de terceiros, assim como material que incorpore vírus ou outros elementos nocivos na Plataforma ou
          que prejudique os sistemas eletrônicos do Grupo SOL Copérnico ou de outros Usuários; (ii) transmitir
          qualquer conteúdo que incite preconceito, racismo ou qualquer forma de discriminação, bem como
          qualquer conteúdo erótico, obsceno, difamatório, calunioso ou que faça apologia ou constitua prática
          de crime; (iii) praticar quaisquer atos ilícitos, como fraudes, e/ou violações à legislação vigente,
          inclusive às disposições da Lei nº 9.613/98 e da Lei nº12.846/13; e (iv) alterar, decodificar,
          descompilar, reverter e/ou modificar o software da Plataforma, ou utilizar qualquer meio indevido ou
          ilícito para acessar informações confidenciais do Grupo SOL Copérnico.</p>

          <h2>3 - Propriedade Intelectual</h2>

          <p>É de propriedade das empresas integrantes do Grupo SOL Copérnico: (a) qualquer software, aplicativo
          ou funcionalidade referente à Plataforma; (b) sinais distintivos dos quais o Grupo SOL Copérnico é
          titular ou licenciado, incluindo, mas não se limitando, o nome empresarial “SOL COPÉRNICO ENERGIAS
          RENOVAVEIS” e as logomarcas “SOL COPÉRNICO” e “BRASÃO SOL COPÉRNICO” inseridas na Plataforma; e (c)
          todo e qualquer conteúdo disponibilizado na Plataforma, o qual não poderá ser utilizado pelos
          Usuários para fins diversos dos disponibilizados na Plataforma, sob qualquer forma.</p>

          <p>O Usuário e o Cliente declaram-se cientes de que é vedada a cópia, reprodução, ou qualquer outro tipo
          de utilização da propriedade intelectual do Grupo SOL Copérnico, ficando os infratores sujeitos às
          sanções civis e criminais correspondentes, nos termos das Leis 9.279/96, 9.609/98 e 9.610/98.</p>

          <h2>4 - Segurança</h2>

          <p>O Usuário e o Cliente entendem que, por meio da Plataforma, é possível a realização de transações
          financeiras, utilizando-se de suas contas e investimentos junto ao Grupo SOL Copérnico. Com o intuito
          de evitar o acesso e a realização de transações indevidas por meio da Plataforma, o Cliente e o Usuário
          reconhecem que deverão adotar as medidas de segurança adequadas, a saber:</p>

          <p>não compartilhar com quaisquer terceiros, mesmo que de sua confiança, suas senhas e credenciais de
          acesso à Plataforma, bem como os tokens disponibilizados pelo Grupo SOL Copérnico para a confirmação de transações, sendo estas
          informações de uso pessoal e intransferível;</p>

          <p>não acessar a Plataforma por meio de computadores ou dispositivos eletrônicos de uso público ou não
          confiáveis ou ainda por meio de redes públicas ou quenão garantam a segurança contra acesso indevido às
          informações por meio delas trafegadas; manter programas antivírus e firewall atualizados nos dispositivos
          utilizados para acessar a Plataforma;</p>

          <p>abster-se de responder quaisquer e-mails que contenham anexos de arquivos executáveis ou que solicite
          quaisquer informações pessoais dos Usuários ou credenciais de acesso à Plataforma ou à sua conta corrente,
          supostamente enviados pelo Grupo SOL Copérnico, tendo em vista que tais práticas não são adotadas pela
          instituição;</p>

          <p>ao criar sua senha de acesso à Plataforma, não utilizar informações que possam ser facilmente
          descobertas por terceiros, como, por exemplo, datas de nascimento e números de telefone;</p>

          <p>em caso de quaisquer dúvidas sobre segurança, entrar em contato com o Grupo SOL Copérnico, por meio
          das informações de contato disponibilizadas no presente Termo e na Plataforma. </p>

          <p>O Usuário e o Cliente reconhecem que o Grupo SOL Copérnico não se responsabiliza por falhas de
          segurança decorrentes exclusivamente da imprudência do Usuário e/ou da falha do Usuário em respeitar
          as precauções mínimas de segurança indicadas no presente Termo.</p>

          <h2>5 - Responsabilidade do Grupo SOL Copérnico</h2>

          <p>O Grupo SOL Copérnico não se responsabiliza perante os Clientes e Usuários por: (i) eventuais
          indisponibilidades, erros ou falhas da Plataforma, assim como por qualquer defraudação da utilidade
          que possa ser atribuída a ela pelo Usuário, pela falibilidade do sistema, nem por quaisquer
          dificuldades de acesso; (ii) erros ou inconsistências na transmissão de dados ou pela qualidade ou
          disponibilidade do sinal de Internet; (iii) existência de vírus ou elementos nocivos na Plataforma
          que possam causar alterações nos seus sistemas informáticos (software e hardware) ou nos documentos
          eletrônicos armazenados no sistema informático, de modo a eximir-se de qualquer responsabilidade
          pelos danos e prejuízos que possam decorrer de quaisquer elementos nocivos; (iv) eventuais falhas e
          erros decorrentes de serviços prestados por terceiros, tais como outras instituições financeiras e
          empresas envolvidas em quaisquer transações financeiras e demais operações; e (v) danos e prejuízos
          de toda ordem que venham a decorrer do conhecimento que terceiros não autorizados possam ter de
          quaisquer das informações fornecidas à Plataforma, em decorrência de falhas exclusivamente atribuíveis
          ao Cliente, ao Usuário ou a terceiros, que fujam a qualquer controle razoável do Grupo SOL Copérnico.</p>

          <p>O Cliente e o Usuário declaram-se cientes de que a senha cadastrada quando da efetiva utilização da
          Plataforma é de sua exclusiva responsabilidade e não deverá ser compartilhada com terceiros, de modo que
          o Usuário é o único e exclusivo responsável por acessos indevidos decorrentes de eventual uso inadequado
          ou negligente de suas senhas.</p>

          <h2>6 - Privacidade</h2>

          <p>Para que seja possível ao Grupo SOL Copérnico prestar serviços por meio da Plataforma de modo adequado,
          este coleta e armazena as informações inseridas na Plataforma ativamente pelo Usuário, tais como
          dados de contato, cadastrais e informações sobre transações. Ainda, poderão ser coletadas pelo Grupo
          SOL Copérnico algumas informações geradas automaticamente quando do acesso e utilização da Plataforma
          pelo Usuário, tais como características do dispositivo de acesso, do navegador, sistema operacional,
          Protocolo de Internet (IP, com data e hora), origem do IP, informações sobre cliques, código ID
          (IMEI) do aparelho móvel, reprodutor de flash instalado, páginas acessadas, as páginas seguintes
          acessadas após a saída da Plataforma, ou qualquer termo de busca digitado na Plataforma, além de
          informações de geolocalização do Usuário. Poderão, ainda, ser utilizadas algumas tecnologias padrões
          para coletar as informações do Usuário, especialmente cookies , de modo a aperfeiçoar sua experiência
          de navegação.</p>

          <p>Poderão, ainda, ser coletadas informações biométricas do Usuário, como, por exemplo, sua imagem,
          impressões digitais e voz.</p>

          <p>O Grupo SOL Copérnico considera todas as informações coletadas por meio da Plataforma como
          confidenciais, garantindo que serão tratadas e armazenadas conforme as determinações destes Termos e
          com a adoção das mais modernas medidas de segurança.As informações coletadas serão utilizadas para
          ermitir a devida e segura prestação de serviços pelo Grupo SOL Copérnico, para oferecer produtos ou
          serviços aos Clientes e Usuário e para melhorar a sua experiência de navegação na Plataforma.</p>

          <p>Desse modo, o aceite ao presente Termo pelo Cliente e Usuário significa seu reconhecimento de que
          suas informações coletadas poderão ser utilizadas para os fins indicados neste documento.</p>

          <p>Ademais, Cliente e Usuário reconhecem que os dados coletados por meio da Plataforma poderão ser
          compartilhados com terceiros nas seguintes situações: (i) quando necessário às atividades comerciais
          e serviços prestados pelo Grupo SOL Copérnico, como por exemplo, com seus parceiros ou contratados;
          (ii) para proteção dos interesses do Grupo SOL Copérnico, em qualquer tipo de conflito, incluindo
          ações judiciais; (iii) no caso de operações societárias envolvendo o Grupo SOL Copérnico, hipótese
          em que a transferência das informações será necessária para a continuidade dos serviços; ou (iv) em
          caso de obrigação legal ou mediante ordem judicial ou pelo requerimento de autoridades administrativas
          que detenham competência legal para sua requisição.</p>

          <p>Ainda, as informações do Cliente e do Usuário serão armazenadas pelo Grupo SOL Copérnico em servidores
          próprios ou por ele contratados, sendo empregados todos os esforços razoáveis de mercado para garantir
          a segurança de seus sistemas na guarda de referidos dados, entre eles as diretrizes sobre padrões de
          segurança estabelecidas no Decreto nº 8.771/2016.</p>

          <p>O Cliente e o Usuário poderão requerer a exclusão de seus dados coletados por meio da Plataforma,
          entrando em contato com o Grupo SOL Copérnico pelo email: contato@copernico.com.br, informando o
          pedido. Nesse caso, o Grupo SOL Copérnico empreenderá os melhores esforços para atender aos pedidos
          no menor espaço de tempo possível.</p>

          <p>O Cliente e Usuário encontram-se cientes de que a exclusão dos dados acarretará o fim de seu acesso
          à Plataforma. Ademais, por meio do aceite ao presente Termo, o Cliente e o Usuário reconhecem que,
          mesmo em caso de requisição de exclusão, a Plataforma observará o prazo mínimo de armazenamento de
          informações determinado pela legislação brasileira.</p>

          <h2>7 - Disponibilização do Acesso</h2>

          <p>O Grupo SOL Copérnico poderá, a seu único e exclusivo critério e a qualquer tempo, independentemente
          de qualquer aviso ou notificação ao Cliente, ao Usuário ou a terceiros, sob qualquer meio ou forma,
          suspender, cancelar ou limitar o acesso à Plataforma, ou ainda adotar outras providências que entender
          necessárias para o cumprimento destes Termos, sem prejuízo de outras medidas cabíveis, em caso de
          descumprimento pelo Usuário de qualquer disposição legal ou destes Termos.</p>

          <p>Nenhuma indenização será devida ao Cliente ou ao Usuário em razão do encerramento da disponibilização
          de acesso à Plataforma.</p>

          <h2> 8 - Contato</h2>

          <p>As notificações e comunicações por parte do Grupo SOL Copérnico ao Cliente e ao Usuário serão
          consideradas plenamente eficazes, para todos os efeitos, quando forem realizadas por meio de qualquer
          informação de contato indicada pelo Usuário para utilização da Plataforma.</p>

          <p>O contato do Cliente e do Usuário com o Grupo SOL Copérnico será realizado por meio da própria
          Plataforma e pelo número de telefone (11)2626-3932, no Serviço de Atendimento ao Consumidor (“SAC”)
          pelo número de telefone 0800 772 5755 ou através da Ouvidoria pelo número de telefone 0800 770 1236.</p>

          <h2>9 - Determinações Gerais</h2>

          <p>A presente relação jurídica é exclusivamente regida pelas leis brasileiras.</p>

          <p>Qualquer cláusula ou condição deste instrumento que, por qualquer razão, venha a ser reputada nula ou
          ineficaz por qualquer juízo ou tribunal, não afetará a validade das demais disposições destes Termos,
          as quais permanecerão plenamente válidas e vinculantes, gerando efeitos em sua máxima extensão.</p>

          <p>O Cliente e o Usuário declaram-se cientes de que estes Termos podem ser alterados a qualquer tempo,
          exceto em caso de vedação legal neste sentido, devendo, portanto, verificá-los periodicamente.</p>

          <p>O acesso à Plataforma subordina-se ainda ao disposto nas ``Normas Gerais Regulamentadoras de Produtos e
          para Prestação de Serviços para Pessoas Jurídicas Clientes da SOL Copérnico Energias Renováveis Ltda.``,
          registradas no 7º Cartório de Registro de Títulos e Documentos de São Paulo - Capital sob o nº 1.329.218
          e posteriores aditamentos averbados à margem do registro original.</p>

          <p>A falha do Grupo SOL Copérnico em exigir quaisquer direitos ou disposições do presente Termo não
          constituirá renúncia, podendo exercer regularmente o seu direito, dentro dos prazos legais.</p>

          <p>Fica eleito o Foro do local de domicílio do Usuário, no Brasil, para dirimir quaisquer dúvidas, questões
          ou litígios decorrentes do presente Termo, renunciando as partes a qualquer outro, por mais privilegiado
          que seja.</p>

          <p>Última Atualização: 03/09/2021</p>
        </Container>
      </Canvas>
    </>
  )
}

export default Terms
