import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Divider, Spin } from 'antd'
import { storageKey } from 'services/axios'
import { LoadingOutlined } from '@ant-design/icons'
import useInvoices, { TGetInvoiceInfoResponse } from 'repositories/useInvoices'
import Tooltip from 'components/Tooltip'
import {
  CardsContainer,
  BigCard,
  CardHeader,
  CardTitle,
  BigTag,
  ValueSpan,
  MiniLabelsDiv,
  MiniLabel,
  Card,
  TooltipContainer,
  Button,
  SpinContainer
} from './styles'

const EnergyTooltip = 'Neste campo é possível observar a quantidade de energia em kWh consumida no mês referência do seu boleto SOL Copérnico da instalação selecionada.'
const EconomyTooltip = 'Aqui é possível conferir o total economizado no mês referência com a sua assinatura SOL Copérnico, somando o desconto contratado e, caso se aplique, a economia gerada através do programa Indique & Ganhe.'
const TotalEconomyTooltip = 'Aqui é possível conferir a economia total acumulada desde o começo da sua assinatura SOL Copérnico até o momento.'

export type TInvoiceInfoCardsParams = {
  isDashboard?: boolean
}

const InvoiceInfoCards = (params:TInvoiceInfoCardsParams) => {
  const { isDashboard = false } = params

  const [invoiceInfo, setInvoiceInfo] = useState<TGetInvoiceInfoResponse>()
  const [reloadHome, setReloadHome] = useState<boolean>(false)
  const [isEmpty, setIsEmpty] = useState<boolean>(false)
  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(false)

  const navigate = useNavigate()
  const invoices = useInvoices()

  const antIcon =
    <LoadingOutlined
      style={{
        fontSize: 70,
        color: 'rgb(245, 175, 25)',
        alignSelf: 'center'
      }}
      width={'100%'}
      spin
    />

  const getTagColor = ( tag:string ) => {
    switch(tag){
      case 'PAGO': return '#5FBCA2'
      case 'EM ABERTO': return '#FFC057'
      case 'VENCIDO': return '#FF5757'
      case 'VAZIO': return '#FFFFFF'
      default: return 'inherit'
    }
  }

  const isSmallFontNecessary = (value:number | undefined) => {
    if(!value) return false
    if(value >= 100000) return true
    return false
  }

  const getInvoiceInfo = async () => {
    setIsFirstLoading(true)
    const response = await invoices.getInvoiceInfo()
    setIsFirstLoading(false)
    if(!response) {
      setIsEmpty(true)
      return
    }
    setInvoiceInfo(response)
  }

  useEffect(() => {
    const formattedUser = JSON.parse(String(localStorage.getItem(storageKey)))
    if(formattedUser.isAdmin && !formattedUser.selectedUC) return
    getInvoiceInfo()
  }, [])

  window.addEventListener('reloadHome', () => setReloadHome(true))

  useEffect(() => {
    if(!reloadHome) return
    getInvoiceInfo()
    setReloadHome(false)
  }, [reloadHome])


  return(
    <CardsContainer>
      <BigCard className={`${isEmpty ? 'opaqueDiv' : ''}`}>
        <CardHeader>
          <CardTitle>Valor da próxima fatura:</CardTitle>
          <BigTag
            color={getTagColor(invoiceInfo?.nextInvoiceCard.status ?
              invoiceInfo?.nextInvoiceCard.status.toUpperCase() : 'VAZIO'
            )}
          >
            <span>Status: </span>
            <span><b>{invoiceInfo?.nextInvoiceCard.status ?
              invoiceInfo?.nextInvoiceCard.status.toUpperCase() :
              '?'
            }</b></span>
          </BigTag>
        </CardHeader>
        {isFirstLoading ? <SpinContainer><Spin indicator={antIcon} /></SpinContainer> :
          <>
            <ValueSpan
              className={`${isSmallFontNecessary(invoiceInfo?.nextInvoiceCard.nextInvoiceValue)
                ? 'smallerFont' : ''
              }`}
            >
              {isEmpty ? '?' : invoiceInfo?.nextInvoiceCard.nextInvoiceValue
                .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
              }
            </ValueSpan>
            <MiniLabelsDiv>
              <MiniLabel>
                <p>Referente a</p>
                <p><strong>
                  {isEmpty ? '?' : invoiceInfo?.nextInvoiceCard.referenceMonth}
                </strong></p>
              </MiniLabel>
              <MiniLabel>
                <p>Vencimento</p>
                <p><strong>
                  {isEmpty ? '?' : invoiceInfo?.nextInvoiceCard.dueDate}
                </strong></p>
              </MiniLabel>
            </MiniLabelsDiv>
            <Divider />
            <Button type='default'>
              <a href={invoiceInfo?.nextInvoiceCard.invoiceUrl} target='_blank' rel='noreferrer noopener'>
                Ver Fatura
              </a>
            </Button>
            {isDashboard ?
              <Button onClick={() => navigate('/invoices')}>Histórico de Faturas</Button> : <></>
            }
          </>
        }
      </BigCard>
      <Card className={`${isEmpty ? 'opaqueDiv' : ''}`}>
        <TooltipContainer>
          <Tooltip content={EnergyTooltip} />
        </TooltipContainer>
        <CardTitle>Energia Consumida</CardTitle>
        {isFirstLoading ? <SpinContainer><Spin indicator={antIcon} /></SpinContainer> :
          <>
            <ValueSpan
              className={`${isSmallFontNecessary(invoiceInfo?.energyConsumedCard.energyConsumed)
                ? 'smallerFont' : ''
              }`}
            >
              {isEmpty ? '?' : invoiceInfo?.energyConsumedCard.energyConsumed} kWh
            </ValueSpan>
            <MiniLabel>
              <p>Referente a</p>
              <p><strong>{isEmpty ? '?' :
                invoiceInfo?.energyConsumedCard.referenceMonth}
              </strong></p>
            </MiniLabel>
            <Divider />
            <Button onClick={() => navigate('/consumption')}>Ver detalhes</Button>
          </>
        }
      </Card>
      <Card className={`${isEmpty ? 'opaqueDiv' : ''}`}>
        <TooltipContainer>
          <Tooltip content={EconomyTooltip} />
        </TooltipContainer>
        <CardTitle>Economia</CardTitle>
        {isFirstLoading ? <SpinContainer><Spin indicator={antIcon} /></SpinContainer> :
          <>
            <ValueSpan
              className={`${isSmallFontNecessary(invoiceInfo?.savingsCard.monthlySavings)
                ? 'smallerFont' : ''
              }`}
            >
              {isEmpty ? '?' : invoiceInfo?.savingsCard.monthlySavings
                .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
              }
            </ValueSpan>
            <MiniLabel>
              <p>Referente a</p>
              <p><strong>
                {isEmpty ? '?' : invoiceInfo?.savingsCard.referenceMonth}
              </strong></p>
            </MiniLabel>
            <Divider />
            <Button onClick={() => navigate('/consumption')}>Ver detalhes</Button>
          </>
        }
      </Card>
      <Card className={`${isEmpty ? 'opaqueDiv' : ''}`}>
        <TooltipContainer>
          <Tooltip content={TotalEconomyTooltip} />
        </TooltipContainer>
        <CardTitle>Economia total</CardTitle>
        {isFirstLoading ? <SpinContainer><Spin indicator={antIcon} /></SpinContainer> :
          <>
            <ValueSpan
              className={`${isSmallFontNecessary(invoiceInfo?.totalSavingsCard.savingsHistory)
                ? 'smallerFont' : ''
              }`}
            >
              {isEmpty ? '?' : invoiceInfo?.totalSavingsCard.savingsHistory
                .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
              }
            </ValueSpan>
            <Divider className='last-divider' />
            <Button onClick={() => navigate('/consumption')}>Ver detalhes</Button>
          </>
        }
      </Card>
    </CardsContainer>
  )
}

export default InvoiceInfoCards