import React from 'react'
import { Tooltip as AntdTooltip } from 'antd'
import { Help } from 'components/Icons'

export type TTooltipParams = {
  content: string
}

const Tooltip = (params:TTooltipParams) => {
  const { content } = params

  return(
    <AntdTooltip
      title={content}
      placement='top'
      color='white'
      overlayInnerStyle = {{ color: '#758592', border: '1px solid #758592' }}
      trigger={['hover', 'click']}
    >
      <span><Help size='23px' color='#D3D3D3' /></span>
    </AntdTooltip>
  )
}

export default Tooltip