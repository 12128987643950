import styled from 'styled-components'
import { Modal as AntdModal } from 'antd'

export const StyledModal = styled(AntdModal)`
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;

  h2 {
    text-align: center;
    font: normal normal normal 24px/29px 'Exo 2';
    letter-spacing: 0px;
    color: #758592;
    opacity: 1;
    color: ${({ theme }): any => theme.colors.GRAY};
    margin: 15px 0 30px 0;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    .ant-modal-content {
      padding: 0;
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 100px;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    padding: 0 150px
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    padding: 15px 11px
  }
`

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 15px;
`

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #7585921C 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 700px;
  height: 130px;
  padding: 25px;
  gap: 25px;
  cursor: pointer;

  :hover {
    background-color: #75859257;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    width: 100%;
    padding: 10px;
    gap: 5px;
  }
`

export const CardTextArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: 90%;
  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    width: 75%;
  }

  span{
    text-align: left;
    font: normal normal normal 22px/26px 'Exo 2';
    letter-spacing: 0px;
    color: #758592;
    opacity: 1;
    margin: 0;
    @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
      font: normal normal normal 18px/22px 'Exo 2';
      text-align: center;
    }
  }

  h3{
    text-align: left;
    font: normal normal normal 17px/20px 'Exo 2';
    letter-spacing: 0px;
    color: #758592;
    opacity: 0.49;
    margin: 0;
    @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
      font: normal normal normal 14px/17px 'Exo 2';
      text-align: center;
    }
  }
`

export const ProfilePicture = styled.div`
    width: 60px;
    height: 60px;
    background-image: linear-gradient(to right, ${({ theme }): any => theme.colors.RED}, ${({ theme }): any => theme.colors.ORANGE});
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;

    img {
        position: relative;
        width: 60px;
        height: 60px;
        border-radius: 100px;
    } 
`

export const SpinContainer = styled.div`
  width: 100%;
  align-self: center;
  padding: 30px 50%;
`

export const EllipsisBlock = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  overflow: hidden;
`
