import styled from 'styled-components'
import {
  Button as AntButton,
  Tag as AntTag
} from 'antd'

export const CardsContainer = styled.div`
  display: grid;
  gap: 25px;
  grid-template-columns: 3fr 2fr 2fr 2fr;
  max-width: ${({ theme }): any => theme.breakPoints.CONTENT_MAX_WIDTH};

  .opaqueDiv {
    opacity: 0.5;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    grid-template-columns: 1fr;
    width: 100%;
    max-width: 100%;
  }
`

export const ValueSpan = styled.span`
  text-align: left;
  font: normal normal 700 32px/38px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.GRAY};
  text-size-adjust: 80%;
`

export const BigCard = styled.div`
  min-height: 200px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 20px;
  padding-right: 5px;

  .ant-divider{
    margin: 10px 0;
  }

  .smallerFont{ 
    font: normal normal 700 26px/32px 'Exo 2';
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    width: 100%;
    max-width: 100%;
    padding: 10px;
  }
`

export const CardHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Card = styled.div`
  min-height: 200px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  padding: 5px 10px 20px 20px;

  .ant-divider{
    margin: 10px 0;
  }

  .last-divider {
    margin-top: 45px;
  }

  .smallerFont{ 
    font: normal normal 700 26px/32px 'Exo 2';
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    width: 100%;
    max-width: 100%;
    padding: 5px 10px 10px 10px;
  }
`

export const CardTitle = styled.h3`
  text-align: left;
  font: normal normal normal 16px/19px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.GRAY};
`

export const MiniLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px 12px 0 0;
  
  p {
    text-align: left;
    font: normal normal normal 10px/15px 'Exo 2';
    letter-spacing: 0px;
    color: ${({ theme }): any => theme.colors.GRAY};
    margin: 0;
  }

  strong {
    text-align: left;
    font: normal normal bold 12px/15px 'Exo 2';
    letter-spacing: 0px;
    color: ${({ theme }): any => theme.colors.GRAY};
    margin: 0;
  }
`
export const MiniLabelsDiv = styled.div`
  display: flex;
  flex-direction: row;
`

export const Button = styled(AntButton)`
  min-width: 130px;
  height: 37px;
  color: ${({ theme }): any => theme.colors.GRAY};
  text-align: center;
  font: normal normal normal 15px/19px 'Exo 2';
  letter-spacing: 0px;
  background: ${({ theme }): any => theme.colors.LIGHT_GRAY} 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  margin-right: 7.5px;

  :hover {
    background: ${({ theme }): any => theme.colors.GRAY} 0% 0% no-repeat padding-box;
    color: ${({ theme }): any => theme.colors.WHITE};
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    width: 100%;
    margin-bottom: 10px;
  }
`

export const BigTag = styled(AntTag)`
  width: 90px;
  height: 35px;
  text-align: center;
  font: normal normal normal 12px/16px 'Exo 2';
  color: ${({ theme }): any => theme.colors.WHITE};
  padding: 17.5px 7.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const SpinContainer = styled.div`
  width: 100%;
  align-self: center;
  padding: 30px 30%;
`

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`