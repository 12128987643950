import React, { useState, useEffect, useMemo } from 'react'
import useConsumption,
{
  TConsumptionData,
  TData
}
  from 'repositories/useConsumption'
import useEconomy,
{
  TEconomyData,
}
  from 'repositories/useEconomy'
import InnerContainer from 'components/InnerPageContainer'
import YearFilter from 'components/YearFilter'

import {
  Container,
  Message,
  TitleChart,
  Header,
  Period
} from './styles'
import BarChart, { TDatasets } from 'components/BarChart'
import { downloadBase64File } from 'utils/helpers'
import PageWrapper from 'components/PageWrapper'
import PageTitle from 'components/PageTitle'

const Consumption = () => {

  const [consumptionData, setConsumptionData] = useState<TConsumptionData>()
  const [economyData, setEconomyData] = useState<TEconomyData>()
  const [selectedYearConsumption, setSelectedYearConsumption] = useState<number | undefined>()
  const [selectedYearEconomy, setSelectedYearEconomy] = useState<number | undefined>()

  const consumption = useConsumption()
  const economy = useEconomy()

  console.warn('selectedYearConsumption', selectedYearConsumption)

  const getConsumption = async () => {
    setConsumptionData({} as TConsumptionData)
    const params = !selectedYearConsumption ? undefined : { yearFilter: selectedYearConsumption }
    const response = await consumption.getConsumptionData(params)
    if(!response) return
    setConsumptionData(response)
  }

  const getEconomy = async () => {
    setEconomyData({} as TEconomyData)
    const params = !selectedYearEconomy ? undefined : { yearFilter: selectedYearEconomy }
    const response = await economy.getEconomyData(params)
    if(!response) return
    setEconomyData(response)
  }

  const handleDownloadSpreadsheetDataConsumption = async(name:string, ) => {
    const params = !selectedYearConsumption ? undefined : { yearFilter: selectedYearConsumption }
    const response = await consumption.getSpreadsheetData(params)
    if(!response) return

    downloadBase64File(`${name}.xlsx`, response.spreadSheetToBase64, 'application/xlsx' )
  }

  const handleDownloadSpreadsheetDataEconomy = async(name:string, ) => {
    const params = !selectedYearEconomy ? undefined : { yearFilter: selectedYearEconomy }
    const response = await economy.getSpreadsheetData(params)
    if(!response) return

    downloadBase64File(`${name}.xlsx`, response.spreadSheetToBase64, 'application/xlsx' )
  }

  const getMaxValeuConsumption = useMemo(() => {
    let max = 0
    if(consumptionData?.chartData){
      consumptionData?.chartData.data.map(({ data }:TData) => {
        max = max < Math.max(...data) ? Math.max(...data) : max
      })
    }

    return Math.ceil(max / 50) * 100
  }, [consumptionData])

  const getMaxValeuEconomy = useMemo(() => {
    let max = 0
    if(economyData?.chartData){
      economyData?.chartData.data.map(({ data }:TData) => {
        max = max < Math.max(...data) ? Math.max(...data) : max
      })
    }

    return Math.ceil(max / 50) * 100
  }, [economyData])

  const consumptionDatasets = useMemo(() => {
    if(consumptionData?.chartData){
      return consumptionData?.chartData.data.map(({ data, label }:TData) => ({
        label: label !== 'CEMIG' ? 'SOL Copérnico' : label,
        data,
        color: label === 'CEMIG' ? '#758592' : '#ff7700',
        isGradient: label !== 'CEMIG',
        gradientColors: ['#F12711', '#ff7700', '#FFC057'],
        stack: 'Stack 0',
        renderLabel: {
          display: false
        },
      })) as TDatasets[]
    }
  }, [consumptionData])

  const economyDatasets = useMemo(() => {
    if(economyData?.chartData){
      return economyData?.chartData.data.map(({ data, label }:TData) => ({
        label,
        data,
        color: label === 'Economia gerada por energia solar' ? '#5FBCA2' : '#ff7700',
        isGradient: label !== 'Economia gerada por energia solar',
        gradientColors: ['#F12711', '#ff7700', '#FFC057'],
        stack: 'Stack 0',
        renderLabel: {
          display: false
        },
      })) as TDatasets[]
    }
  }, [economyData])

  useEffect(() => {
    if(!selectedYearConsumption ){
      setSelectedYearConsumption(undefined)

    }
    getConsumption()
  }, [selectedYearConsumption])

  useEffect(() => {
    getEconomy()
  }, [selectedYearEconomy])

  return (
    <InnerContainer>
      <PageWrapper>
        <PageTitle>Consumo e economia</PageTitle>
        <Container>
          {!consumptionDatasets?.length && !consumption.isLoading ? (
            <Message>Você ainda não tem informações de consumo e economia geradas.</Message>
          ) : (
            <div className='div2PDF'>
              <Header>
                <TitleChart>
                  <h3>Histórico de consumo</h3>
                </TitleChart>
                <Period>
                  <div>
                    <YearFilter
                      onChange={(value:unknown) => setSelectedYearConsumption(Number(value))}
                      title='SELECIONAR PERÍODO:'
                      value={selectedYearConsumption}
                      styleTitle={{
                        textAlign: 'left',
                        font: 'normal normal bold 12px/15px "Exo 2"',
                        marginRight: '2.4rem',
                        fontWeight: 'bold'
                      }}
                    />
                  </div>
                </Period>
              </Header>
              <BarChart
                labels={consumptionData?.chartData?.labels ?? []}
                dataSets={consumptionDatasets ?? []}
                symbol='quilowatt'
                title='Histórico de consumo'
                positionLegend='bottom'
                type='horizontal'
                isLoading={consumption.isLoading}
                idPosition={0}
                handleDownloadSpreadsheetData={(name:string) => handleDownloadSpreadsheetDataConsumption(name)}
                maxValuePositionY={getMaxValeuConsumption}
                disableButton
                showTooltip
              />
            </div>
          )}
        </Container>
        <Container $marginBottom>
          {!economyDatasets?.length && !economy.isLoading ? (
            <Message>Você ainda não tem informações de histórico de economia.</Message>
          ) : (
            <div className='div2PDF'>
              <Header>
                <TitleChart>
                  <h3>Histórico de economia</h3>
                </TitleChart>
                <Period>
                  <div>
                    <YearFilter
                      onChange={(value:unknown) => setSelectedYearEconomy(Number(value))}
                      title='SELECIONAR PERÍODO:'
                      value={selectedYearEconomy}
                      styleTitle={{
                        textAlign: 'left',
                        font: 'normal normal bold 12px/15px "Exo 2"',
                        marginRight: '2.4rem',
                        fontWeight: 'bold'
                      }}
                    />
                  </div>
                </Period>
              </Header>
              <BarChart
                labels={economyData?.chartData?.labels ?? []}
                dataSets={economyDatasets ?? []}
                symbol='currency'
                title='Histórico de economia'
                positionLegend='bottom'
                type='horizontal'
                isLoading={economy.isLoading}
                idPosition={1}
                handleDownloadSpreadsheetData={(name:string) => handleDownloadSpreadsheetDataEconomy(name)}
                maxValuePositionY={getMaxValeuEconomy}
                disableButton
                showTooltip
              />
            </div>
          )}
        </Container>
      </PageWrapper>
    </InnerContainer>
  )
}

export default Consumption