import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const Exclamation = (props: TProps): JSX.Element => {
  const {
    size = '30',
    color = '#868686'
  } = props


  return (
    <svg id='attention_ic' xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 112 112'>
      <g id='Icon-Exclamation'>
        <path id='Fill-49' d='M-163.9-157.9a56,56,0,0,1-56-56,56,56,0,0,1,56-56,56,56,0,0,1,56,56,56,56,0,0,1-56,56Zm0-105.908A50.1,50.1,0,0,0-213.808-213.9,50.1,50.1,0,0,0-163.9-163.992,50.1,50.1,0,0,0-113.992-213.9,50.1,50.1,0,0,0-163.9-263.808Z' transform='translate(219.9 269.9)' fill={color}/>
        <path id='Fill-50' d='M-197.4-236.1h6.561v6.561H-197.4Z' transform='translate(250.12 315.296)' fill={color} />
        <path id='Fill-51' d='M-192.245-212.307h-3.749l-1.406-26.477V-258.7h6.561v19.916l-1.406,26.477' transform='translate(250.12 284.943)' fill={color} />
      </g>
    </svg>

  )
}

export default Exclamation