import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const OpenEye = (props: TProps): JSX.Element => {
  const {
    size = '14',
    color = '#868686'
  } = props

  return (
    <svg id='eye_pass_icon' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='30' height='22' viewBox='0 0 30 22'>
      <defs>
        <clipPath id='clip-path'>
          <rect id='Retângulo_126' data-name='Retângulo 126' width={size} height={size} transform='translate(-2 -2)' fill={color} />
        </clipPath>
      </defs>
      <g id='Grupo_165' data-name='Grupo 165' transform='translate(2 2)'>
        <g id='Grupo_164' data-name='Grupo 164' clipPath='url(#clip-path)'>
          <path id='Caminho_1260' data-name='Caminho 1260' d='M26.247,8.133A14.7,14.7,0,0,0,13.2,0,14.706,14.706,0,0,0,.16,8.133a1.483,1.483,0,0,0,0,1.338A14.7,14.7,0,0,0,13.2,17.6,14.706,14.706,0,0,0,26.247,9.471a1.483,1.483,0,0,0,0-1.338M13.2,15.4a6.6,6.6,0,1,1,.006,0H13.2m0-11a4.371,4.371,0,0,0-1.16.174A2.194,2.194,0,0,1,8.977,7.642,4.391,4.391,0,1,0,13.2,4.4' transform='translate(-0.001)' fill={color} />
        </g>
      </g>
      <line id='Linha_20' data-name='Linha 20' x2='12' y2='12' transform='translate(8.5 5.5)' fill='none' stroke='#f5af19' strokeWidth='2' opacity='0' />
      <line id='Linha_21' data-name='Linha 21' x2='16' y2='16' transform='translate(8.5 3.5)' fill='none' stroke='#fff' strokeWidth='2' opacity='0' />
    </svg>
  )
}

export default OpenEye