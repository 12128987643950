import React, { useState } from 'react'
import useChangePassword from 'repositories/useChangePassword'
import { Input, Form, Spin } from 'antd'
import type { FormInstance } from 'antd/es/form'
import { LoadingOutlined } from '@ant-design/icons'
import { initialValues, validation } from './validations'
import {
  StyledModal,
  Wrapper,
  ConfirmButton,
  LabelButton,
  ReturnButtonArea,
  SmallLabel,
  ConfirmButtonArea
} from './styles'

export type TChangePasswordModalProps = {
  isOpen: boolean
  onFinishOperation: ()=> void
  onCancel: ()=> void
  passwordType: 'defaultPassword' | 'forgotPassword' | 'changePassword'
  newPasswordToken?: string
}

export type TForm = {
  newPassword: string
  newPasswordConfirmation: string
  oldPassword?: string
}

const ChangePasswordModal = (props: TChangePasswordModalProps) => {
  const {
    isOpen = false,
    onFinishOperation,
    onCancel,
    passwordType,
    newPasswordToken = ''
  } = props

  const [isLoading, setIsLoading] = useState(false)

  const changePassword = useChangePassword()

  const [form] = Form.useForm<TForm>()
  const formRef = React.useRef<FormInstance>(null)

  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />

  const handleFinish = async (values: TForm) => {
    setIsLoading(true)
    const formattedData = {
      ...values,
      token: newPasswordToken
    }
    const response = await changePassword.putNewPassword(formattedData, passwordType)
    setIsLoading(false)
    if (!response) return

    formRef.current?.resetFields()
    onFinishOperation()
  }

  return (
    <StyledModal
      open={isOpen}
      onCancel={onCancel}
      width = '1180px'
      footer = {null}
      closeIcon = {null}
      centered
    >
      <Wrapper>
        <h2>Altere sua senha</h2>
        <h3>Preencha os campos abaixo para prosseguir com alteração de senha da sua conta:</h3>
        <Form
          name='changePasswordForm'
          ref={formRef}
          initialValues={initialValues}
          layout='vertical'
          onFinish={handleFinish}
          form={form}
          autoComplete='off'
          size='middle'
          requiredMark='optional'
        >
          {(passwordType === 'changePassword') &&
          <Form.Item
            rules={validation('oldPassword')}
            name='oldPassword'
            id='input-oldPassword'
          >
            <Input
              size='large'
              placeholder='Senha atual'
              maxLength={30}
            />
          </Form.Item>}
          <Form.Item
            rules={validation('newPassword')}
            name='newPassword'
            id='input-newPassword'
          >
            <Input
              size='large'
              placeholder='Nova senha'
              maxLength={30}
            />
          </Form.Item>
          <Form.Item
            rules={validation('newPasswordConfirmation')}
            name='newPasswordConfirmation'
            id='input-newPasswordConfirmation'
          >
            <Input
              size='large'
              placeholder='Confirme sua nova senha'
              maxLength={30}
            />
          </Form.Item>
          <ConfirmButtonArea>
            <ConfirmButton
              type='primary'
              htmlType='submit'
            >
              {!isLoading ? <LabelButton>Confirmar</LabelButton> : <Spin indicator={antIcon} />}
            </ConfirmButton>
          </ConfirmButtonArea>
          <ReturnButtonArea>
            <SmallLabel onClick={onCancel}>Voltar</SmallLabel>
          </ReturnButtonArea>
        </Form>
      </Wrapper>
    </StyledModal>
  )
}

export default ChangePasswordModal