import styled from 'styled-components'
import FacebookSvg from 'assets/face_icon.svg'
import FacebookHoverSvg from 'assets/face_icon_hover.svg'
import InstagramSvg from 'assets/insta_icon.svg'
import InstagramHoverSvg from 'assets/insta_icon_hover.svg'
import LinkedinSvg from 'assets/linkedin_icon.svg'
import LinkedinHoverSvg from 'assets/linkedin_icon_hover.svg'


export const Container = styled.div`
  width: 100%;
  height: 70px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  padding: 15px 0;
`

export const Socials = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 10px 0;
  width: 165px;
`

export const Copyrights = styled.h5<{ $textColor?: string; }>`
  text-align: left;
  font: normal normal normal 11px/20px 'Exo 2';
  letter-spacing: 0px;
  color: ${props => props.$textColor || '#5A5A5A'};
  opacity: 1;
`

const svgHoverIcon = (svg: string, hoverSvg: string) => styled.svg<{ color?: string; }>`
  mask-image: url('${svg}');
  background-color: ${props => props.color || '#5A5A5A'};
  height: 34px;
  width: 34px;
  :hover {
    mask-image: none;
    background: transparent url('${hoverSvg}') 0% 0% no-repeat padding-box;
  }
`

export const FacebookIcon = svgHoverIcon(FacebookSvg, FacebookHoverSvg)
export const InstagramIcon = svgHoverIcon(InstagramSvg, InstagramHoverSvg)
export const LinkedinIcon = svgHoverIcon(LinkedinSvg, LinkedinHoverSvg)