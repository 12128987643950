import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const Building = (props: TProps): JSX.Element => {
  const {
    size = '14',
    color = '#868686'
  } = props

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 23.658 24.787'>
      <g id='company_ic' transform='translate(-2.508 -0.459)'>
        <path id='path1235' d='M93.14,41.414v2.1H89.47v2.4H87.517v7.459H85.782v-2.36H84.611v2.36H81.9V66.2h23.658V53.371h-2.792v-2.36h-1.171v2.36H99.785V45.912h-2.1v-2.4H94.311v-2.1Zm-2.5,3.272h5.872v1.226H90.641Zm-1.953,2.4h9.924V65.028H95.85V60.679h-4.4v4.349H88.69V53.371h0Zm-5.621,7.459h4.45V65.028h-4.45Zm16.718,0h4.6V65.028h-4.6Zm-7.159,7.308h2.051v3.176H92.626Z' transform='translate(-79.388 -40.955)' fill={color} />
        <path id='path1239' d='M10.063,6.7v3.569h5.67V6.7h-5.67Zm1.173,1.173H14.56V9.1H11.236Z' transform='translate(1.29 1.066)' fill={color} />
        <path id='path1243' d='M10.063,11.379v3.569h5.67V11.379Zm1.173,1.171H14.56v1.225H11.236Z' transform='translate(1.29 1.865)' fill={color} />
        <path id='path1532' d='M4.428,15.651v1.171H6.723V15.651Z' transform='translate(0.328 2.594)' fill={color} />
        <path id='path1536' d='M4.428,13.477V14.65H6.723V13.477Z' transform='translate(0.328 2.223)' fill={color} />
        <path id='path1540' d='M4.428,17.823v1.171H6.723V17.823Z' transform='translate(0.328 2.965)' fill={color} />
        <path id='path1544' d='M18.77,15.651v1.171h2.295V15.651Z' transform='translate(2.777 2.594)' fill={color} />
        <path id='path1548' d='M18.77,13.477V14.65h2.295V13.477Z' transform='translate(2.777 2.223)' fill={color} />
        <path id='path1552' d='M18.77,17.823v1.171h2.295V17.823Z' transform='translate(2.777 2.965)' fill={color} />
      </g>
    </svg>
  )
}

export default Building