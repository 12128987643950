import React from 'react'

import {
  Container
} from './styles'

type TProps = {
  children: React.ReactNode
}

const InnerContainer = ({ children }: TProps): JSX.Element => {

  return (
    <Container id='inner-page-container'>
      {children}
    </Container>
  )
}

export default InnerContainer