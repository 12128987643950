import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const Exit = (props: TProps): JSX.Element => {
  const {
    size = '14',
    color = '#868686'
  } = props

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 22 24.4'>
      <g id='exit_ic' transform='translate(-1 0.2)'>
        <g id='Grupo_183' data-name='Grupo 183'>
          <line id='Linha_26' data-name='Linha 26' x2='14.4' transform='translate(7.6 12)' fill='none' stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
        </g>
        <g id='Grupo_184' data-name='Grupo 184'>
          <path id='Caminho_1273' data-name='Caminho 1273' d='M11.9.8H4.5A2.456,2.456,0,0,0,2,3.2V20.7a2.476,2.476,0,0,0,2.5,2.5h7.4' fill='none' stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
        </g>
        <path id='Caminho_1274' data-name='Caminho 1274' d='M18.2,8.2,22,12l-3.8,3.8' fill='none' stroke={color} strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' />
      </g>
    </svg>
  )
}

export default Exit