import { Rule } from 'antd/lib/form'
import { TForm } from '.'


export const initialValues:TForm = {
  newPassword: '',
  newPasswordConfirmation: '',
  oldPassword: ''
}

const StrongPasswordRegex = '^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$'

export const validation = (key: string) => {
  switch (key) {

    case 'newPassword':

      return [{
        required: true,
        message: 'Senha é necessária.'
      },
      {
        pattern: StrongPasswordRegex,
        message: 'Sua senha deve conter no mínimo 8 caracteres, uma letra maiúscula, uma letra minúscula, um caracter especial e um número.'
      }] as Rule[]

    case 'newPasswordConfirmation':
      return [{
        required: true,
        message: 'Confirmar senha é necessário.'
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue('newPassword') === value) {
            return Promise.resolve()
          }
          return Promise.reject(new Error('Os valores não são iguais!'))
        },
      }),
      ] as Rule[]

    case 'oldPassword':

      return [{
        required: true,
        message: 'Senha é necessária.'
      },
      {
        max: 50,
        message: 'Senha não pode conter mais de 50 caracteres.'
      }] as Rule[]
    default:
      [] as Rule[]
  }
}