import styled from 'styled-components'

export const Container = styled.aside`
    position: absolute;
    width: ${({ theme }): any => theme.breakPoints.MENU_WIDTH};
    height: calc(100vh - 55px);
    background-image: linear-gradient(to right, ${({ theme }): any => theme.colors.RED}, ${({ theme }): any => theme.colors.ORANGE});
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media screen and (max-width: ${({ theme }): any => theme.breakPoints.BS_LG}) {
        display: none;
    }

    .ant-menu {
        border-inline-end: none;
    }

    .ant-menu-item {
        margin: 0;
        border-radius: 0;
        width: 100%;
        text-align: left;
        font: normal normal normal 20px/24px 'Exo 2';
        letter-spacing: 0px;
        color: ${({ theme }): any => theme.colors.WHITE};
        background-image: linear-gradient(to right, ${({ theme }): any => theme.colors.RED}, ${({ theme }): any => theme.colors.ORANGE});
        padding: 30px 0;

        :hover {
            background: black linear-gradient(to right, rgb(241, 39, 17, 0.9), rgb(245, 175, 25, 0.9));
            color: ${({ theme }): any => theme.colors.WHITE};
        }
    }

    .ant-menu-item-active{
        background: black linear-gradient(to right, rgb(241, 39, 17, 0.9), rgb(245, 175, 25, 0.9));
        color: ${({ theme }): any => theme.colors.WHITE};
        border-left: 7px solid ${({ theme }): any => theme.colors.ORANGE};
    }

    .ant-menu-title-content{
        padding-left: 15px;
    }

    .ant-menu-item-divider{
        margin: 0;
        background-image: linear-gradient(to right, ${({ theme }): any => theme.colors.RED}, ${({ theme }): any => theme.colors.ORANGE});
    }
`