import { useState } from 'react'
import useRest from 'hooks/useRest'

type TNewPasswordParams = {
  newPassword: string
  newPasswordConfirmation: string
  oldPassword?: string
  token?: string,
}

type TForgotPassword = {
  cpfCnpj: string
  captchaResponse: string
}

const useChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()

  const getPasswordUrl = (passwordType:string) => {
    switch(passwordType) {
      case 'defaultPassword': return '/password/changeDefault'
      case 'forgotPassword': return '/forgotPassword/newPassword'
      case 'changePassword': return '/password/change'
      default: return '/password/change'
    }
  }

  const putNewPassword = async (
    data:TNewPasswordParams,
    passwordType:'defaultPassword' | 'forgotPassword' | 'changePassword',
    triggerLoading = true
  ): Promise<any> => {
    if (triggerLoading) setIsLoading(true)

    const url = getPasswordUrl(passwordType)

    const response = await rest.put(url, data)
    if (triggerLoading) setIsLoading(false)
    return response as any
  }

  const forgotPassword = async (
    data:TForgotPassword,
    triggerLoading = true
  ): Promise<any> => {
    if (triggerLoading) setIsLoading(true)

    const url = '/forgotPassword'

    const response = await rest.post(url, data, undefined, true)
    if (triggerLoading) setIsLoading(false)
    return response as any
  }


  return {
    isLoading,
    putNewPassword,
    forgotPassword
  }
}

export default useChangePassword