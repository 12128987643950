import React from 'react'

import {
  Header
} from './styles'

type TProps = {
  children: React.ReactNode
}

const PageTitle = ({ children }: TProps): JSX.Element => (
  <Header>
    {children}
  </Header>
)


export default PageTitle