import React, { useEffect, useState } from "react";

import { Table, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import { LoadingOutlined } from "@ant-design/icons";
import useReferAndWin, {
  TReferAndWinListItem,
} from "repositories/useReferAndWin";
import InnerContainer from "components/InnerPageContainer";
import {
  TableContainer,
  TableTitle,
  RedirectButton,
  LabelButton,
  ButtonArea,
  Tag,
  SpinContainerTable,
  ErrorMessage,
} from "./styles";
import PageWrapper from "components/PageWrapper";
import PageTitle from "components/PageTitle";

const getTagColor = (tag: string) => {
  switch (tag) {
    case "DESCONTO GARANTIDO":
      return "#5FBCA2";
    case "AGUARDANDO":
      return "#FFC057";
    default:
      return "#758592";
  }
};

const columns: ColumnsType<TReferAndWinListItem> = [
  {
    title: "Nome",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "Data da indicação",
    dataIndex: "indicationDate",
    key: "indicationDate",
    align: "center",
  },
  {
    title: "Situação",
    dataIndex: "indicationStatus",
    key: "indicationStatus",
    align: "center",
    render: (indicationStatus) => (
      <Tag color={getTagColor(indicationStatus.toUpperCase())}>
        {indicationStatus.toUpperCase()}
      </Tag>
    ),
  },
];

const ReferNWinList = () => {
  const [data, setData] = useState<TReferAndWinListItem[]>([]);

  const navigate = useNavigate();
  const referAndWin = useReferAndWin();

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 70,
        color: "rgb(245, 175, 25)",
        alignSelf: "center",
      }}
      width={"100%"}
      spin
    />
  );

  useEffect(() => {
    const getPageData = async () => {
      const response = await referAndWin.getReferAndWinList();
      if (!response) return;
      setData(response.referList);
    };
    getPageData();
  }, []);

  return (
    <InnerContainer>
      <PageWrapper>
        <PageTitle>Indique & Ganhe</PageTitle>
        <TableContainer>
          <TableTitle>Minhas indicações</TableTitle>
          {!referAndWin.isLoading && data?.length === 0 ? (
            <ErrorMessage>
              Não há indicações para serem exibidas no momento! Volte mais
              tarde.
            </ErrorMessage>
          ) : referAndWin.isLoading ? (
            <SpinContainerTable>
              <Spin indicator={antIcon} />
            </SpinContainerTable>
          ) : (
            <Table columns={columns} dataSource={data} pagination={false} />
          )}
          <ButtonArea>
            <RedirectButton
              style={{ backgroundColor: "#F5AF19" }}
              onClick={() => navigate("/")}
            >
              <LabelButton>Voltar</LabelButton>
            </RedirectButton>
            <RedirectButton onClick={() => navigate("/refer-and-win")}>
              <LabelButton>Indicar amigo</LabelButton>
            </RedirectButton>
          </ButtonArea>
        </TableContainer>
      </PageWrapper>
    </InnerContainer>
  );
};

export default ReferNWinList;
