import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const MultipleConsumerUnit = (props: TProps): JSX.Element => {
  const {
    size = '14',
    color = '#868686'
  } = props

  return (
    <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width={size} height={size} viewBox='0 0 61.901 55'>
      <defs>
        <clipPath id='clip-path-5'>
          <rect id='Retângulo_264' data-name='Retângulo 264' width='61.901' height='55' fill={color} />
        </clipPath>
        <clipPath id='clip-path-6'>
          <rect id='Retângulo_262' data-name='Retângulo 262' width='5.654' height='11.106' fill='none' stroke={color} strokeWidth='1.3' />
        </clipPath>
        <clipPath id='clip-path-7'>
          <rect id='Retângulo_262-2' data-name='Retângulo 262' width='2.847' height='5.592' fill='none' stroke={color} strokeWidth='0.6' />
        </clipPath>
      </defs>
      <g id='un_general_icon' transform='translate(-65.05 -19)'>
        <g id='Grupo_285' data-name='Grupo 285' transform='translate(65.05 19)'>
          <g id='Grupo_284' data-name='Grupo 284' clipPath='url(#clip-path-5)'>
            <path id='Caminho_1291' data-name='Caminho 1291' d='M170.516,199.839c-.923.714-1.581.4-1.827-.924a32.272,32.272,0,0,0-2.808-8.5c-1.23-2.469-2.588-4.884-4.044-7.227a11.425,11.425,0,0,1-1.956-7.5,11.018,11.018,0,0,1,9.357-9.634,10.868,10.868,0,0,1,11.014,16.071c-1.276,2.309-2.657,4.563-3.879,6.9a46.541,46.541,0,0,0-3.347,8.386c-.07.235-.126.474-.229.868.533-.046.968-.064,1.4-.123,6-.832,11.254-3.152,15.331-7.768a16.669,16.669,0,0,0,3.782-7.221c.048-.2.066-.476.2-.575a1.936,1.936,0,0,1,.985-.462.663.663,0,0,1,.657.816,13.162,13.162,0,0,1-.471,2.177A21.058,21.058,0,0,1,184.5,196.779a26.671,26.671,0,0,1-9.218,3.105c-1.145.17-2.3.307-3.455.368a4.348,4.348,0,0,1-1.315-.413m.128-.979c.1-.335.158-.528.222-.719.642-1.91,1.222-3.844,1.943-5.725a58.633,58.633,0,0,1,5.424-10.516,8.966,8.966,0,0,0,1.114-7.758,8.491,8.491,0,0,0-7.322-6.219,9.075,9.075,0,0,0-10.264,10.035,9.764,9.764,0,0,0,1.763,4.35,47.093,47.093,0,0,1,6.027,12.412c.4,1.3.7,2.633,1.094,4.139' transform='translate(-139.879 -145.253)' fill={color} />
            <path id='Caminho_1292' data-name='Caminho 1292' d='M396.418,109.179a7.4,7.4,0,0,1-1.142,3.953,35.216,35.216,0,0,0-4.529,10.141.872.872,0,0,1-.989.743,6.523,6.523,0,0,0-.981.07.864.864,0,0,1-.969-.687c-.153-.492-.23-1.008-.359-1.508a27.346,27.346,0,0,0-3.819-8.346,7.583,7.583,0,0,1-1.44-4.231,7.159,7.159,0,0,1,6.664-7.11,7.051,7.051,0,0,1,7.564,6.976M389.3,121.186a2.641,2.641,0,0,0,.2-.372,43.734,43.734,0,0,1,4.217-8.8,5.876,5.876,0,0,0,.785-2.7,5.174,5.174,0,0,0-6.882-4.974,5.247,5.247,0,0,0-2.716,7.766c1.027,1.655,1.986,3.361,2.849,5.107.612,1.239,1.012,2.583,1.543,3.976' transform='translate(-334.517 -89.444)' fill={color} />
            <path id='Caminho_1293' data-name='Caminho 1293' d='M7.355,124.128a2.5,2.5,0,0,0-.31-.024c-1.287.054-1.305.039-1.6-1.273a27.834,27.834,0,0,0-3.755-8.786A12.988,12.988,0,0,1,.32,111.336a6.986,6.986,0,0,1,2.838-7.813,7.07,7.07,0,0,1,10.864,4.3,7.178,7.178,0,0,1-1.071,5.649,34.14,34.14,0,0,0-3.791,7.839c-.21.631-.387,1.273-.568,1.912-.224.79-.407.929-1.237.9m-.255-2.8a.837.837,0,0,0,.129-.177,40.718,40.718,0,0,1,4.359-9.073,5.035,5.035,0,0,0,.535-4.129,5.146,5.146,0,0,0-6.7-3.478,5.22,5.22,0,0,0-2.744,7.7c1.031,1.7,2.023,3.435,2.9,5.218.612,1.238,1,2.585,1.515,3.934' transform='translate(0 -89.563)' fill={color} />
            <path id='Caminho_1294' data-name='Caminho 1294' d='M142.817,13.592A13.484,13.484,0,0,0,140.8,8.536a11.131,11.131,0,0,1-.8-1.4,5.132,5.132,0,1,1,9.7-3.248,4.984,4.984,0,0,1-.57,3.907,21.173,21.173,0,0,0-2.688,6.006c-.062.239-.1.484-.149.728-.139.761-.423.915-1.183.98-2.045.176-4.1.328-6.121.663a23.307,23.307,0,0,0-7.29,2.643,1.314,1.314,0,0,1-1.069.09c-.787-.444-.706-1.2.138-1.683a23.759,23.759,0,0,1,6.582-2.608c1.775-.4,3.579-.673,5.458-1.018m1.787-.867.149,0a1.9,1.9,0,0,0,.132-.271A22.344,22.344,0,0,1,147.44,7a3.533,3.533,0,0,0,.51-2.016,3.174,3.174,0,0,0-3.224-3.109,3.362,3.362,0,0,0-3.272,3.053,3.722,3.722,0,0,0,.708,2.269,17.123,17.123,0,0,1,2.181,4.6c.092.31.173.623.26.934' transform='translate(-113.865 0)' fill={color} />
            <path id='Caminho_1295' data-name='Caminho 1295' d='M69.2,312.971c-1.36-.377-2.74-.694-4.076-1.143a23.436,23.436,0,0,1-11.736-8.066,17.673,17.673,0,0,1-3.129-7,1.958,1.958,0,0,1-.045-.618.88.88,0,0,1,.842-.855.846.846,0,0,1,.969.7c1.4,7,6.088,11.021,12.352,13.554a45.717,45.717,0,0,0,4.944,1.461c.632.175.96.515.893,1.069a.988.988,0,0,1-1.014.893' transform='translate(-43.943 -258.453)' fill={color} />
            <path id='Caminho_1296' data-name='Caminho 1296' d='M273.181,112.043a24.87,24.87,0,0,1,6.31,1.577c1.074.44,2.122.947,3.166,1.458.663.325.865.848.6,1.358s-.806.64-1.458.293a25.494,25.494,0,0,0-6.27-2.384c-.845-.2-1.708-.317-2.556-.5a.925.925,0,0,1-.827-1.057c.067-.5.422-.759,1.037-.745' transform='translate(-238.189 -98.067)' fill={color} />
          </g>
        </g>
        <g id='Grupo_293' data-name='Grupo 293' transform='translate(-443 -242)'>
          <g id='Grupo_286' data-name='Grupo 286' transform='translate(536.673 288.5)'>
            <g id='Grupo_282' data-name='Grupo 282' clipPath='url(#clip-path-6)'>
              <path id='Caminho_1290' data-name='Caminho 1290' d='M9.27,4.5H5.182L4.5,10.633H5.863l-.681,4.088L9.27,7.907H7.907Z' transform='translate(-4.058 -4.058)' fill='none' stroke={color} strokeLinejoin='round' strokeWidth='1.3' />
            </g>
          </g>
          <g id='Grupo_287' data-name='Grupo 287' transform='translate(561.673 278.5)'>
            <g id='Grupo_282-2' data-name='Grupo 282' clipPath='url(#clip-path-7)'>
              <path id='Caminho_1290-2' data-name='Caminho 1290' d='M6.9,4.5H4.843L4.5,7.588h.686L4.843,9.647,6.9,6.216H6.216Z' transform='translate(-4.278 -4.277)' fill='none' stroke={color} strokeLinejoin='round' strokeWidth='0.6' />
            </g>
          </g>
          <g id='Grupo_288' data-name='Grupo 288' transform='translate(513.673 278.5)'>
            <g id='Grupo_282-3' data-name='Grupo 282' clipPath='url(#clip-path-7)'>
              <path id='Caminho_1290-3' data-name='Caminho 1290' d='M6.9,4.5H4.843L4.5,7.588h.686L4.843,9.647,6.9,6.216H6.216Z' transform='translate(-4.278 -4.277)' fill='none' stroke={color} strokeLinejoin='round' strokeWidth='0.6' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default MultipleConsumerUnit