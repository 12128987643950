import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const Camera = (props: TProps): JSX.Element => {
  const {
    size = '14',
    color = '#868686'
  } = props

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 16.85 14.736'>
      <g id='exchange_photo_ic' transform='translate(-0.5 -2.5)'>
        <path id='Caminho_1281' data-name='Caminho 1281' d='M13.68,5.113,12.623,3h-7.4L4.17,5.113H2.057A1.056,1.056,0,0,0,1,6.17v9.51a1.056,1.056,0,0,0,1.057,1.057H15.793A1.056,1.056,0,0,0,16.85,15.68V6.17a1.056,1.056,0,0,0-1.057-1.057Z' fill='none' stroke={color} strokeLinejoin='round' strokeWidth='1' />
        <line id='Linha_39' data-name='Linha 39' x1='0.826' transform='translate(14.217 7.09)' fill='none' stroke={color} strokeLinejoin='round' strokeWidth='1' />
        <circle id='Elipse_16' data-name='Elipse 16' cx='3.717' cy='3.717' r='3.717' transform='translate(5.13 7.09)' fill='none' stroke={color} strokeLinejoin='round' strokeWidth='1' />
      </g>
    </svg>


  )
}

export default Camera