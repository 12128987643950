import styled from 'styled-components'
import { Modal as AntdModal, Button } from 'antd'

export const StyledModal = styled(AntdModal)`
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;

  h2 {
    text-align: center;
    font: normal normal normal 32px/50px 'Exo 2';
    letter-spacing: 0px;
    margin: 10px 0;
    color: ${({ theme }): any => theme.colors.GRAY};
  }

  h3 { 
    text-align: center;
    font: normal normal normal 20px/22px 'Exo 2';
    letter-spacing: 0px;
    margin: 10px 0;
    color: ${({ theme }): any => theme.colors.GRAY};
  }

  .ant-form-item {
    margin: 10px 0;
    width: 370px;

    @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
      width: 315px;
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 355px;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    padding: 0 150px
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
    padding: 0 15px
  }
`

export const ConfirmButton = styled(Button)`
  background: ${({ theme }): any => theme.colors.GRAY} 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 247px;
  height: 37px;
  align-self: center;

  :hover{
    background: ${({ theme }): any => theme.colors.YELLOW} 0% 0% no-repeat padding-box;
  }
`

export const ConfirmButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const LabelButton = styled.h5`
  font: normal normal normal 15px/19px 'Exo 2';
  letter-spacing: 0px;
  color: #fff;
  margin: 0;
  text-align: center;
`

export const ReturnButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;

  h4, h4:visited, h4:hover, h4:active {
    color: ${({ theme }): any => theme.colors.GRAY};
    text-decoration: none;
  }

  h4:hover{
    text-decoration: underline;
    cursor:pointer;
  }
`

export const SmallLabel = styled.h4`
  font: normal normal normal 15px/50px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.GRAY};
  margin: 0;
`