import { useState } from 'react'
import useRest from 'hooks/useRest'

export type TConsumerUnit = {
  code: string
  address?: TAddress
}

export type TAddress = {
  zipCode: string
  street: string
  number: string
  complement: string
  neighborhood: string
  addressType: string
  city: string
  state: {
    name: string
    uf: string
  }
}

const useConsumerUnits = () => {
  const [isLoading, setIsLoading] = useState(false)
  const rest = useRest()


  const getConsumerUnits = async (triggerLoading = true): Promise<any> => {
    if (triggerLoading) setIsLoading(true)

    const url = '/consumerUnits'

    const response = await rest.get(url)
    if (triggerLoading) setIsLoading(false)
    return response as any
  }


  return {
    isLoading,
    getConsumerUnits,
  }
}

export default useConsumerUnits