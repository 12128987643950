import React, { createContext, useContext, useState, useEffect } from 'react'
import restApi, { storageKey } from 'services/axios'

type TUserData = {
  jwt: string
  user: {
    id: number
    name: string
    federalRegistration: string
    email: string
    isFirstAccess: boolean
    userStatus: {
      id: number
      name: string
    }
  }
}

type TUseAuth = {
  isSignedIn: boolean
  userData?: TUserData
  hasToken(): boolean
  saveUserData(user: TUserData): void
  removeUserData(): void
}

const AuthContext = createContext<TUseAuth>({} as TUseAuth)

type TProps = {
  children: React.ReactNode
}

const AuthProvider = ({ children }: TProps) => {
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [userData, setUserData] = useState<TUserData>()

  useEffect(() => {
    const storageUserData = localStorage.getItem(storageKey)
    if (!storageUserData) return

    const parsedUserData = JSON.parse(storageUserData)
    setUserData(parsedUserData)
    if(parsedUserData.user.isFirstAccess === false) setIsSignedIn(true)
  }, [])

  const saveUserData = (userData: TUserData) => {
    localStorage.setItem(storageKey, JSON.stringify(userData))
    setUserData(userData)
    if(userData.user.isFirstAccess === false) setIsSignedIn(true)
    setToken(userData.jwt)
  }

  const removeUserData = () => {
    localStorage.removeItem(storageKey)
    setUserData(undefined)
    setIsSignedIn(false)
  }

  const setToken = (token: string) => {
    restApi.defaults.headers.Authorization = `Bearer ${token}`
  }

  const hasToken = () => Boolean(localStorage.getItem(storageKey))

  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        userData,
        hasToken,
        saveUserData,
        removeUserData
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = (): TUseAuth => {
  const context = useContext(AuthContext)

  return context
}

export { useAuth, AuthProvider }
