import React from 'react'

type TProps = {
  size?: string
  color?: string
}

const BurgerMenu = (props: TProps): JSX.Element => {
  const {
    size = '14',
    color = '#868686',
  } = props

  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_429_11066)'>
        <path d='M3 6.00092H21M3 12.0009H21M3 18.0009H21' stroke={color} strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_429_11066'>
          <rect width='24' height='24' fill='white' transform='translate(0 0.000915527)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default BurgerMenu