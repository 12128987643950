import styled from 'styled-components'
import {
  Button as AntButton,
} from 'antd'

export const Container = styled.div`
  display: grid;
  gap: 25px;
  grid-template-columns: 7.5fr 2fr;
  padding: 20px 0;
  max-width: ${({ theme }): any => theme.breakPoints.CONTENT_MAX_WIDTH};

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    grid-template-columns: 100%;
    width: 1fr;
    padding: 20px 0 0 0;
  }
`

export const Card = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    width: 100%;
  }
`

export const Context = styled.div`
  margin: 18px 21px;
`

export const Header = styled.div`
  background-image: linear-gradient(180deg, ${({ theme }): any => theme.colors.RED}, ${({ theme }): any => theme.colors.ORANGE});
  height: 96px;
  opacity: 1;
  border-radius: 15px 15px 0px 0px;
  display: flex;
  justify-content: center;

  img{
    width: 102px;
    height: 67px;
    opacity: 1;
    padding: 10px;
    padding: 9px 10px 0px 10px;
  }
`
export const CardTitle = styled.h3`
  text-align: left;
  font: normal normal normal 16px/19px 'Exo 2';
  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.GRAY};
  opacity: 1;
`

export const SpinContainer = styled.div`
  width: 100%;
  align-self: center;
  padding: 30px 30%;
`

export const ValueSpan = styled.span`
  text-align: left;
  font: normal normal bold 25px/20px 'Exo 2';
  color: ${({ theme }): any => theme.colors.GRAY};
  letter-spacing: 0px;
  opacity: 1;
`

export const Referent = styled.div`
  display:flex;
  justify-content: flex-start;
  flex-direction: column;
  color: ${({ theme }): any => theme.colors.GRAY};
  text-align: left;
  letter-spacing: 0px;

  > span:first-child{
    font: normal normal normal 10px/15px 'Exo 2';
  }

  > span:last-child{
    font: normal normal bold 12px/15px 'Exo 2';
  }
`

export const Button = styled(AntButton)`
  width: 100%;
  height: 37px;
  color: ${({ theme }): any => theme.colors.GRAY};
  text-align: center;
  font: normal normal normal 14px/11px 'Exo 2';
  letter-spacing: 0px;
  background: ${({ theme }): any => theme.colors.LIGHT_GRAY} 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  margin-right: 10px;
  cursor: pointer;

  :hover {
    background: ${({ theme }): any => theme.colors.GRAY} 0% 0% no-repeat padding-box !important;
    color: ${({ theme }): any => theme.colors.WHITE};
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    width: 100%;
    margin-bottom: 10px;
  }
`

export const ButtonIndicate = styled(AntButton)`
  width: 100%;
  height: 37px;
  color: ${({ theme }): any => theme.colors.WHITE};
  text-align: center;
  font: normal normal normal 14px/11px 'Exo 2';
  letter-spacing: 0px;
  background-image: linear-gradient(0deg, ${({ theme }): any => theme.colors.RED}, ${({ theme }): any => theme.colors.ORANGE});
  border-radius: 5px;
  opacity: 1;
  margin-right: 10px;
  margin-top: 10px;

  :hover {
    background: ${({ theme }): any => theme.colors.LIGHT_GRAY} 0% 0% no-repeat padding-box;
    color: ${({ theme }): any => theme.colors.GRAY}!important;
  }

  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    width: 100%;
    margin-bottom: 10px;
  }
`

export const Message = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height:546px;
  background: #FFFFFF;
  border-radius: 10px;

  h3 {
    font: normal normal normal 40px/48px 'Exo 2';
    letter-spacing: 0px;
    color: ${({ theme }): any => theme.colors.GRAY};
    opacity: 1;
    }
`
export const ContentChart = styled.div`
  background-color: ${({ theme }): any => theme.colors.WHITE};
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;

  button {
    margin: 2rem;
    width: 15rem;
  }

  canvas {
    max-width: 100% !important;
    @media screen and (min-width: ${({ theme }): any => theme.breakPoints.SMALL_SCREEN}) {
      min-width: 501px; 
    }
  }
`

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 5px;
`

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.GRAY};
  opacity: 1;
  text-align: center;

  span:first-child{
    font: normal normal 900 26px/31px 'Exo 2';
  }

  span:last-child{
    font: normal normal normal 16px/19px 'Exo 2';
  }
`

export const TitleChart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @media screen and (max-width: ${({ theme }): any => theme.breakPoints.MEDIUM_SCREEN}) {
    font: normal normal normal 20px/25px 'Exo 2';
    padding: 0;
  }

`

export const Period = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 3px;

  letter-spacing: 0px;
  color: ${({ theme }): any => theme.colors.GRAY};
  opacity: 1;
  text-align: center;
  font: normal normal normal 12px/18px 'Exo 2';

  span:last-child{
    font: normal normal bold 16px/19px 'Exo 2';
  }
`